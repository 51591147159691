import {
  BANK_NAME_LOADED,
  BANK_NAME_ERROR,
  BRANCH_NAME_LOADED,
  BRANCH_NAME_ERROR,
  AVAILABLE_PAYMENT_METHODS_LOADED,
  AVAILABLE_PAYMENT_METHODS_ERROR,
  CATEGORY_DETAIL_LOADED,
  CATEGORY_DETAIL_CLEAR,
  CATEGORY_DETAIL_ERROR,
  CATEGORY_LIST_LOADED,
  CATEGORY_LIST_ERROR,
  COUNTRY_CODE_LIST,
  COUNTRY_CODE_LIST_ERROR,
} from "./types"

const initialState = () => ({
  bank: [],
  branch: [],
  availablePaymentMethodList: [
    // format:
    // {
    //   id: 1,
    //   displayCurrencey: "yen",
    //   holding: "hirai-private",
    //   payment: "creditcard",
    // },
  ],
  paymentMethods: {
    // format:
    // 1: "yen",
  },
  category: {},
  categoryList: [],
  countryCodeList: [],
})

const convertToList = (data) => ({
  id: data.id,
  name: data.name,
  code: data.code,
})

const convertToCategory = (data) => ({
  id: data.id,
  name: data.name,
  urlParameter: data.urlParameter,
  isTixplus: data.isTixplus,
})

const masterDataReducers = (state = initialState(), action) => {
  switch (action.type) {
    case BANK_NAME_LOADED:
      return {
        ...state,
        bank: action.payload.map((data) => convertToList(data)),
      }
    case BRANCH_NAME_LOADED:
      return {
        ...state,
        branch: action.payload.map((data) => convertToList(data)),
      }
    case AVAILABLE_PAYMENT_METHODS_LOADED:
      return {
        ...state,
        availablePaymentMethodList: action.payload,
        paymentMethods: Object.fromEntries(
          action.payload.map((payment) => [payment.id, payment.payment])
        ),
      }
    case CATEGORY_DETAIL_LOADED:
      return {
        ...state,
        category: convertToCategory(action.payload),
      }
    case CATEGORY_DETAIL_CLEAR:
      return {
        ...state,
        category: {},
      }
    case CATEGORY_LIST_LOADED:
      return {
        ...state,
        categoryList: action.payload.map((value) => convertToCategory(value)),
      }
    case CATEGORY_DETAIL_ERROR:
      return {
        ...state,
      }
    case COUNTRY_CODE_LIST:
      return {
        ...state,
        countryCodeList: action.payload,
      }
    case CATEGORY_LIST_ERROR:
    case BANK_NAME_ERROR:
    case BRANCH_NAME_ERROR:
    case AVAILABLE_PAYMENT_METHODS_ERROR:
    case COUNTRY_CODE_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default masterDataReducers
