import React from "react"
import PropTypes from "prop-types"
import {
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormControl,
  Typography,
  Box,
} from "@material-ui/core"
import { useField, useFormikContext, ErrorMessage } from "formik"
import TextError from "../TextError/TextError"
import { creditcardImages } from "../../../utils/payment"

const CreditRadioButton = React.memo(
  ({ name, legend, options, disabled, ...props }) => {
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(name)
    const handleChange = (event) => {
      const { value } = event.target
      setFieldValue(name, value)
    }
    React.useEffect(() => {
      if (options?.length === 0) {
        setFieldValue(name, "newCredit")
      }
    }, [name, options, setFieldValue])
    const configRadioButton = {
      ...field,
      ...props,
      onChange: handleChange,
    }
    const configFormControl = {}
    if (meta && meta.touched && meta.error) {
      configFormControl.error = true
    }
    return (
      <FormControl {...configFormControl}>
        <FormLabel component="legend">{legend}</FormLabel>
        <RadioGroup {...configRadioButton}>
          {options.map(({ cardSeq, cardNo }, i) => (
            <Box
              display="flex"
              flexWrap="wrap"
              key={cardSeq}
              my={1}
              justifyContent="space-between"
            >
              <FormControlLabel
                value={cardSeq}
                control={<Radio classes={{ root: "custom-radio-root" }} />}
                label={`登録済みのカード${i + 1}`}
                disabled={disabled}
              />
              <Box display="flex">
                <Box display="flex" alignItems="center" mr={1}>
                  <Typography variant="body2">{cardNo}</Typography>
                </Box>
              </Box>
            </Box>
          ))}
          <Box display="flex" flexWrap="wrap" my={1}>
            <FormControlLabel
              value="newCredit"
              control={<Radio classes={{ root: "custom-radio-root" }} />}
              label="新規クレジットカード/デビットカード"
              disabled={disabled}
            />
            <Box ml={3}>
              {creditcardImages.map((card) => (
                <img src={card.icon} alt={card.name} key={card.name} />
              ))}
            </Box>
          </Box>
        </RadioGroup>
        <ErrorMessage component={TextError} name={name} />
      </FormControl>
    )
  }
)

CreditRadioButton.propTypes = {
  name: PropTypes.string,
  legend: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
}

export default CreditRadioButton
