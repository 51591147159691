import React from "react"
import PropTypes from "prop-types"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  accordion: {
    "&.MuiPaper-rounded": {
      position: "relative",
      padding: "42px 0",
      margin: "0",
      boxShadow: "none",
      border: "1px solid #EEE",
      borderWidth: "1px 0",
      "& + .MuiPaper-rounded": {
        borderWidth: "0 0 1px",
      },
      "&:last-child": {
        borderBottom: "0px",
      },
    },

    "& .MuiIconButton-edgeEnd": {
      margin: "0",
      "& .MuiIcon-root": {
        fontSize: "32px",
      },
    },

    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      padding: "0",

      "& .MuiAccordionSummary-content": {
        margin: "0",
        "& .accordion-title": {
          fontSize: "22px",
          fontWeight: "bold",
          lineHeight: "1.4",
          [theme.breakpoints.down("767")]: {
            fontSize: "20px",
          },
        },
      },
    },

    "& .MuiAccordionDetails-root": {
      padding: "0",
      marginTop: "20px",

      "& .MuiContainer-root": {
        padding: "0",
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.down("767")]: {
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        },
      },
    },

    "& .MuiGrid-container": {
      "& .MuiGrid-grid-xs-true": {
        fontSize: "19px",
        fontWeight: "bold",
        lineHeight: "1.4",
      },
    },
  },
}))

const AccordionWrapper = ({ title, children }) => {
  const classes = useStyles()

  return (
    <Accordion defaultExpanded className={classes.accordion}>
      <AccordionSummary
        expandIcon={<Icon>expand_more</Icon>}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography
          variant="h3"
          color="textPrimary"
          component="h3"
          className="accordion-title"
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container>{children}</Container>
      </AccordionDetails>
    </Accordion>
  )
}

AccordionWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default AccordionWrapper
