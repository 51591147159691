import draftToHtml from "draftjs-to-html"
import DOMPurify from "dompurify"
import { format } from "date-fns"
import { PAYMENT_METHOD_MASTER } from "../constants/paymentMethodMaster"

// 日本通貨表記
export const toJPformatter = new Intl.NumberFormat("ja-JP")

// 指数表記(Ex. 1e-7など)を整数表記/小数表記に変換する
export const num2FracStr = (number) => {
  if (number !== 0 && !number) return ""

  /*
   * 引数の値を文字列化
   */
  const numStr = String(number)

  /*
   * 正規表現でマッチング
   */
  const match = numStr.match(
    /^([+-]?)0*([1-9][0-9]*|)(?:\.([0-9]*[1-9]|)0*)?(?:[eE]([+-]?[0-9]+))?$/
  )

  /*
   * 引数の型が適切な形式ではない場合…
   */
  if (!match) {
    if (typeof number === "number") {
      /*
       * 引数の型が数値であれば、文字列化した値をそのまま返す
       */
      return numStr
    }
    /*
     * 引数の型が数値でなければ、エラーにする
     */
    throw new Error(`Invalid Number: "${numStr}"`)
  }

  /** @type {string} 数の符号 */
  const sign = match[1] === "-" ? "-" : ""
  /** @type {string} 仮数部の整数部 */
  const mantissaInt = match[2]
  /** @type {string} 仮数部の少数部 */
  const mantissaFrac = match[3] ? match[3] : ""
  /** @type {number} 指数部 */
  const exponent = Number(match[4])

  let returnValue = ""

  if (exponent) {
    /*
     * exponentがundefinedではなく（正規表現で指数部がマッチしていて）、
     * かつ、0ではない場合、指数表記として処理を開始する
     *
     * Note: 指数部が0の場合、ここで処理する意味は無いので少数表記として処理する。
     *       よって、指数部が0以外の場合にここで処理する。
     * Note: undefinedは数値化されるとNaNになり、false相当となる。
     *       一方、0の場合もfalse相当となる。
     *       ので、↑の条件文はコレで合っている。
     */

    /** @type {string} */
    const mantissaStr = mantissaInt + mantissaFrac
    /** @type {number} */
    const mantissaLen = mantissaStr.length

    if (mantissaLen > 0) {
      /** @type {number} */
      const mantissaIntLen = mantissaInt.length + exponent

      /*
      12.145e+7  121450000             ;  mantissa_str: "12145"  mantissa_int_len: 9   ;  小数部が存在しない数値
      12.145e+6   12145000             ;  mantissa_str: "12145"  mantissa_int_len: 8   ;  小数部が存在しない数値
      12.145e+5    1214500             ;  mantissa_str: "12145"  mantissa_int_len: 7   ;  小数部が存在しない数値
      12.145e+4     121450             ;  mantissa_str: "12145"  mantissa_int_len: 6   ;  小数部が存在しない数値
      12.145e+3      12145             ;  mantissa_str: "12145"  mantissa_int_len: 5   ;  小数部が存在しない数値
      12.145e+2       1214.5           ;  mantissa_str: "12145"  mantissa_int_len: 4   ;  小数部が存在し、かつ、1より大きい数値
      12.145e+1        121.45          ;  mantissa_str: "12145"  mantissa_int_len: 3   ;  小数部が存在し、かつ、1より大きい数値
      12.145e0          12.145         ;  mantissa_str: "12145"  mantissa_int_len: 2   ;  小数部が存在し、かつ、1より大きい数値
      12.145e-1          1.2145        ;  mantissa_str: "12145"  mantissa_int_len: 1   ;  小数部が存在し、かつ、1より大きい数値
      12.145e-2          0.12145       ;  mantissa_str: "12145"  mantissa_int_len: 0   ;  小数部が存在し、かつ、1未満の数値
      12.145e-3          0.012145      ;  mantissa_str: "12145"  mantissa_int_len: -1  ;  小数部が存在し、かつ、1未満の数値
      12.145e-4          0.0012145     ;  mantissa_str: "12145"  mantissa_int_len: -2  ;  小数部が存在し、かつ、1未満の数値
      12.145e-5          0.00012145    ;  mantissa_str: "12145"  mantissa_int_len: -3  ;  小数部が存在し、かつ、1未満の数値
      12.145e-6          0.000012145   ;  mantissa_str: "12145"  mantissa_int_len: -4  ;  小数部が存在し、かつ、1未満の数値
      12.145e-7          0.0000012145  ;  mantissa_str: "12145"  mantissa_int_len: -5  ;  小数部が存在し、かつ、1未満の数値
      */

      if (mantissaLen <= mantissaIntLen) {
        /*
         * 小数部が存在しない数値（ex: 0, 12, 176, 1214500）の場合の処理
         */
        returnValue = mantissaStr.padEnd(mantissaIntLen, "0")
      } else if (mantissaIntLen > 0) {
        /*
         * 小数部が存在し、かつ、1より大きい数値（ex: 1.26, 1.0009, 121.45）の場合の処理
         */
        returnValue = `${mantissaStr.slice(
          0,
          mantissaIntLen
        )}.${mantissaStr.slice(mantissaIntLen)}`
      } else {
        /*
         * 小数部が存在し、かつ、1未満の数値（ex: 0.26, 0.20098, 0.0012145）の場合の処理
         */
        returnValue = `0.${"0".repeat(-mantissaIntLen) + mantissaStr}`
      }
    }
  } else if (mantissaFrac) {
    /*
     * 少数表記の場合
     */
    returnValue = `${mantissaInt || "0"}.${mantissaFrac}`
  } else if (mantissaInt) {
    /*
     * 整数表記の場合
     */
    returnValue = mantissaInt
  }

  return returnValue
    ? sign +
        returnValue
          /* 先頭の余計なゼロを削除 */
          .replace(/^(?:0(?!\.|$))+/, "")
          /* 末尾の余計なゼロを削除 */
          .replace(/(?:\.0+|(\.[0-9]*[1-9])0+)$/, "$1")
    : "0"
}

export const getCurrencyFormat = (price, currency = "yen") => {
  if (currency.toLowerCase() === "yen") {
    const yenformat = toJPformatter.format(Number(price))
    const symbol = "\xA5"
    return `${symbol}${yenformat}`
  }
  if (currency.toLowerCase() === "matic") {
    return `${num2FracStr(Number(price))} MATIC`
  }
  if (currency.toLowerCase() === "eth") {
    return `${num2FracStr(Number(price))} ETH`
  }
  return `${price} ${currency}`
}

export const DEPRECATED = "DEPRICATED"
export const isAirdrop = (paymentMethod, price = 0) => {
  if (String(paymentMethod) === PAYMENT_METHOD_MASTER.AIRDROP_BY_API) {
    return true
  }
  // paymentMethodが使えないコンテキストの際にやむなく使用するパターン
  return paymentMethod === DEPRECATED && Number(price) === 0
}

// エアドロップ販売時の"FREE"表記に対応した価格表記
// 通貨表記がある場合は表示がおかしくなるため使用箇所に注意
export const getCurrencyFormatCanBeAirdrop = (
  price,
  currency = "yen",
  paymentMethod
) => {
  if (isAirdrop(paymentMethod, price)) {
    return "Free"
  }
  return getCurrencyFormat(price, currency)
}

export const getCurrencyFormattWithoutSymbol = (price, currency = "yen") => {
  // priceをnumberに変換
  let priceNum
  if (typeof price === "string") {
    priceNum = Number(price.replace(/,/g, ""))
  } else {
    priceNum = price
  }

  if (currency.toLowerCase() === "yen") {
    return toJPformatter.format(priceNum)
  }
  if (currency.toLowerCase() === "eth" || currency.toLowerCase() === "matic") {
    return num2FracStr(priceNum)
  }
  return `${priceNum}`
}

// エアドロップ販売時の"FREE"表記に対応した価格表記
// 通貨表記がある場合は表示がおかしくなるため使用箇所に注意
export const getCurrencyFormatWithoutSymbolCanBeAirdrop = (
  price,
  currency = "yen",
  paymentMethod
) => {
  if (isAirdrop(paymentMethod, price)) {
    return "Free"
  }
  return getCurrencyFormattWithoutSymbol(price, currency)
}

// リッチエディターJSONからHTMLへconvert
export const convertFromJSONToHTML = (text) => {
  try {
    if (text)
      return {
        __html: DOMPurify.sanitize(
          draftToHtml(text).replace(/<p><\/p>/g, "<br />"),
          // デフォルトだとiframeはsanitize時に除去されてしまう
          {
            ADD_TAGS: ["iframe"],
            ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
          }
        ),
      }
    return null
  } catch (exp) {
    return { __html: "" }
  }
}

// ブロックチェーン 取引履歴 イベントステータス (売却、購入、オファー、新規作成)
export const getEventStatus = (status) => {
  switch (status) {
    case "1":
      return "売却"
    case "2":
      return "購入"
    case "3":
      return "オファー"
    case "4":
      return "新規作成"
    default:
      return null
  }
}

// お知らせ・速報 メッセージタイプ（お知らせ、速報）
export const getMessageType = (status) => {
  switch (status) {
    case "news":
      return "お知らせ"
    case "breakingnews":
      return "速報"
    default:
      return null
  }
}

// お知らせ・速報 宛先定義
export const sendTypeOptions = [
  { name: "公式アカウント", value: "official" },
  {
    name: "一般アカウント\n（ログイン済みユーザー＋未ログインユーザー）",
    value: "user",
  },
  { name: "ログイン済みユーザー", value: "member" },
  { name: "未ログインユーザー", value: "non_member" },
]

// お知らせ・速報 宛先（お知らせ、速報）
export const getSendType = (status) => {
  switch (status) {
    case "all":
      return "全体"
    case "user":
      return "一般アカウント\n（ログイン済みユーザー＋未ログインユーザー）"
    case "official":
      return "公式アカウント"
    case "member":
      return "ログイン済みユーザー"
    case "non_member":
      return "未ログインユーザー"
    default:
      return null
  }
}

/**
 出品ステータス

 1次出品下書き:primary_draft
 1次出品非公開:primary_private
 1次出品公開:primary_selling
 購入して保有中:own
 2次出品下書き:secondary_draft
 2次出品非公開:secondary_private
 2次出品公開:secondary_selling
 *  */
export const getListingStatus = (status) => {
  switch (status) {
    case "primary_draft":
    case "secondary_draft":
      return "下書き"
    case "primary_private":
    case "secondary_private":
      return "非公開"
    case "primary_selling":
    case "secondary_selling":
      return "公開"
    case "own":
      return "保有中"
    case "primary_suspended":
    case "secondary_suspended":
      return "オークション終了"
    default:
      return null
  }
}

/**
 * 都道府県リスト
 */
export const prefectures = {
  "": "選択してください",
  北海道: "北海道",
  青森県: "青森県",
  岩手県: "岩手県",
  宮城県: "宮城県",
  秋田県: "秋田県",
  山形県: "山形県",
  福島県: "福島県",
  茨城県: "茨城県",
  栃木県: "栃木県",
  群馬県: "群馬県",
  埼玉県: "埼玉県",
  千葉県: "千葉県",
  東京都: "東京都",
  神奈川県: "神奈川県",
  新潟県: "新潟県",
  富山県: "富山県",
  石川県: "石川県",
  福井県: "福井県",
  山梨県: "山梨県",
  長野県: "長野県",
  岐阜県: "岐阜県",
  静岡県: "静岡県",
  愛知県: "愛知県",
  三重県: "三重県",
  滋賀県: "滋賀県",
  京都府: "京都府",
  大阪府: "大阪府",
  兵庫県: "兵庫県",
  奈良県: "奈良県",
  和歌山県: "和歌山県",
  鳥取県: "鳥取県",
  島根県: "島根県",
  岡山県: "岡山県",
  広島県: "広島県",
  山口県: "山口県",
  徳島県: "徳島県",
  香川県: "香川県",
  愛媛県: "愛媛県",
  高知県: "高知県",
  福岡県: "福岡県",
  佐賀県: "佐賀県",
  長崎県: "長崎県",
  熊本県: "熊本県",
  大分県: "大分県",
  宮崎県: "宮崎県",
  鹿児島県: "鹿児島県",
  沖縄県: "沖縄県",
}

export const camel2snake = (value) =>
  value.replace(/[A-Z]/g, (s) => `_${s.charAt(0).toLowerCase()}`)

export const snake2camel = (value) =>
  (value.charAt(0).toLowerCase() + value.slice(1)).replace(
    /[-_](.)/g,
    (_match, group1) => group1.toUpperCase()
  )

export const getAuctionStatus = (won, isPaid) => {
  if (won === true && isPaid === true) {
    return "支払済"
  }
  if (won === true && isPaid === false) {
    return "落札"
  }
  if (won === false && isPaid === false) {
    return "落選"
  }
  return "入札中"
}

export const getLotteryStatus = (won, isPaid) => {
  if (won === true && isPaid === true) {
    return "支払済"
  }
  if (won === true && isPaid === false) {
    return "当選"
  }
  if (won === false && isPaid === false) {
    return "落選"
  }
  return "応募中"
}

export const convertDateFormat = (date, formatString) => {
  if (date == null || date.toString() === "Invalid Date") {
    return ""
  }
  return format(date, formatString)
}

export const getPaymentMethodType = (method) => {
  switch (method) {
    case 1:
      return "クレジットカード"
    case 2:
      return "Ethereum(NowPayments)"
    case 3:
      return "Polygon（MATIC）"
    case 4:
      return "Ethereum"
    default:
      return "不明"
  }
}

export const getMetamaskErrorMessage = (error, action) => {
  let errorMessage
  switch (error.code) {
    case 4001:
      errorMessage = `User canceled the request.`
      break
    case 4100:
      errorMessage = `The requested account and/or method has not been authorized by the user.`
      break
    case 4200:
      errorMessage = `The requested method is not supported by this Ethereum provider.`
      break
    case 4900:
      errorMessage = `The provider is disconnected from all chains.`
      break
    case 4901:
      errorMessage = `The provider is disconnected from the specified chain.`
      break
    case -32700:
      errorMessage = `Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text.`
      break
    case "UNSUPPORTED_OPERATION":
      errorMessage = `Metamaskにログインしてください`
      break
    case -32603:
      errorMessage = error.message.includes("transaction underpriced")
        ? "ガス代が低すぎます"
        : `Internal JSON-RPC error。`
      break
    case -32602:
      errorMessage = `Invalid method parameter(s).`
      break
    case -32601:
      errorMessage = `The method does not exist / is not available.`
      break
    case -32600:
      errorMessage = `The JSON sent is not a valid Request object.`
      break
    case -32000:
      errorMessage = `Invalid input.`
      break
    case -32001:
      errorMessage = `Resource not found.`
      break
    case -32002:
      errorMessage = `Resource unavailable.`
      break
    case -32003:
      errorMessage = `Transaction rejected.`
      break
    case -32004:
      errorMessage = `Method not supported.`
      break
    case -32005:
      errorMessage = `Request limit exceeded.`
      break
    default:
      errorMessage = `${action}に失敗しました。もう一度やり直してください。${error.message}`
      break
  }
  return errorMessage
}

export const getResponseErrorMessage = (error) => {
  const response = error.response.data
  if (response.error?.message) {
    if (response.error.message?.ErrMsg) {
      return response.error.message.ErrMsg
    }
    return response.error.message
  }
  if (response.errors && response.errors[0]?.message) {
    return response.errors[0].message
  }
  if (response.message) {
    return response.message
  }
  return null
}

/**
 * 支払手段が仮想通貨かどうか
 * @param paymentMethodId {string | number}
 * @return {boolean}
 */
export function isPayByCrypto(paymentMethodId) {
  return PAYMENT_METHOD_MASTER.CRYPTO_CURRENCIES.includes(
    String(paymentMethodId)
  )
}

/**
 * 支払手段がEtherかどうか、また複数ある場合はEtherが含まれているか
 * @param paymentMethodIdOrIds
 * @return {boolean}
 */
export function isPayByEther(paymentMethodIdOrIds) {
  if (Array.isArray(paymentMethodIdOrIds)) {
    return paymentMethodIdOrIds.some((id) => isPayByEther(id))
  }

  return String(paymentMethodIdOrIds) === PAYMENT_METHOD_MASTER.ETHEREUM
}

/**
 * 支払手段がMATICかどうか、また複数ある場合はMATICが含まれているか
 * @param paymentMethodIdOrIds
 * @return {boolean}
 */
export function isPayByMatic(paymentMethodIdOrIds) {
  if (Array.isArray(paymentMethodIdOrIds)) {
    return paymentMethodIdOrIds.some((id) => isPayByMatic(id))
  }

  return String(paymentMethodIdOrIds) === PAYMENT_METHOD_MASTER.POLYGON
}
