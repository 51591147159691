export const NOTIFICATIONNEWS_LIST_LOADED = "NOTIFICATIONNEWS_LIST_LOADED"
export const NOTIFICATIONNEWS_LIST_ERROR = "NOTIFICATIONNEWS_LIST_ERROR"

export const NOTIFICATIONNEWS_REQUEST = "NOTIFICATIONNEWS_REQUEST"
export const NOTIFICATIONNEWS_INIT = "NOTIFICATIONNEWS_INIT"
export const NOTIFICATIONNEWS_LOADED = "NOTIFICATIONNEWS_LOADED"
export const NOTIFICATIONNEWS_CREATED = "NOTIFICATIONNEWS_CREATED"
export const NOTIFICATIONNEWS_UPDATED = "NOTIFICATIONNEWS_UPDATED"
export const NOTIFICATIONNEWS_ERROR = "NOTIFICATIONNEWS_ERROR"

export const CATEGORY_SELECT_LIST_LOADED = "CATEGORY_SELECT_LIST_LOADED"
