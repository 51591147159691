import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  makeStyles,
  Box,
  Icon,
  TextField,
  InputAdornment,
  Avatar,
  Drawer,
  IconButton,
  Divider,
} from "@material-ui/core"

const drawerWidth = "180px"

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    height: "600px",
    padding: theme.spacing(0, 1),
  },
  drawerPaper: {
    minWidth: 100,
    width: "250px",
    position: "absolute",
    left: "0",
    height: "600px",
    padding: "5px",
    borderRight: "inherit",
    MsOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawer: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  text: {
    color: theme.palette.bg_dark,
  },
}))

// メッセージスレッドドロワー
const MessageThreadDrawer = ({
  messageData,
  open,
  handleDrawerClose,
  handleDisplayMessage,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.drawerContainer}>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        className={clsx(classes.drawer, {
          [classes.drawerShift]: open,
        })}
        ModalProps={{
          container: document.getElementById("appContainerDiv"),
          style: { position: "absolute" },
        }}
      >
        <Box display="flex">
          <IconButton onClick={handleDrawerClose}>
            <Icon>chevron_left</Icon>
          </IconButton>
          <TextField
            placeholder="検索する"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
            // onChange={handleSearch}
          />
        </Box>
        <Box>
          {messageData.map((data) => (
            <Box key={data.id} onClick={() => handleDisplayMessage(data.id)}>
              <Box mt={2}>
                <Box display="flex" justifyContent="flex-start">
                  <Avatar src={data.user_image} />
                  <Box alignSelf="center" ml={0.5}>
                    <Box
                      fontWeight="fontWeightBold"
                      className={classes.text}
                      fontSize={10}
                    >
                      {data.account_name}
                    </Box>
                    <Box fontWeight="fontWeightBold" fontSize={12}>
                      {data.official_name && data.official_name}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    fontSize={10}
                    variant="body2"
                    style={{
                      width: "9em",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.message}
                  </Box>
                </Box>
                <Box
                  fontWeight="fontWeightBold"
                  textAlign="end"
                  className={classes.text}
                  fontSize={10}
                  mb={1}
                >
                  {new Date(data.time_stamp)
                    .toLocaleString("ja-JP")
                    .slice(0, -3)}
                </Box>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </Drawer>
    </Box>
  )
}

MessageThreadDrawer.propTypes = {
  messageData: PropTypes.arrayOf(PropTypes.objectOf),
  open: PropTypes.bool,
  handleDrawerClose: PropTypes.func,
  handleDisplayMessage: PropTypes.func,
}

export default MessageThreadDrawer
