import { BENEFIT_LIST_LOADED, BENEFIT_LIST_ERROR } from "./types"

const initialState = () => ({
  benefitList: [],
})

const listingBenefitReducers = (state = initialState(), action) => {
  switch (action.type) {
    case BENEFIT_LIST_LOADED:
      return {
        ...state,
        benefitList: action.payload,
      }
    case BENEFIT_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default listingBenefitReducers
