import {
  GENERAL_ACCOUNT_LIST_LOADED,
  GENERAL_ACCOUNT_LIST_ERROR,
  GENERAL_ACCOUNT_LIST_TRANSITION_LOADED,
  GENERAL_ACCOUNT_LOADED,
  GENERAL_ACCOUNT_INIT,
  GENERAL_ACCOUNT_UPDATED,
  GENERAL_ACCOUNT_ERROR,
  GENERAL_ACCOUNT_DELETED,
  GENERAL_CREDIT_LIST_LOADED,
  GENERAL_BANK_LIST_LOADED,
  GENERAL_WALLET_LIST_LOADED,
  GENERAL_CREDIT_LIST_ERROR,
  GENERAL_BANK_LIST_ERROR,
  GENERAL_WALLET_LIST_ERROR,
  COLLECTION_LIST_LOADED,
  COLLECTION_LIST_ERROR,
} from "./types"

const initialState = () => ({
  list: [],
  transition: [],
  form: {
    nickname: "",
    lastName: "",
    firstName: "",
    lastNameKana: "",
    firstNameKana: "",
    birthDate: "",
    email: "",
    emailConfirmation: "",
    password: "",
    passwordConfirmation: "",
  },
  mypageForm: {
    name: "",
    profilePicUrl: "",
    profileOverview: "",
    story: {},
    url1: "",
    url2: "",
    url3: "",
    url4: "",
    url5: "",
    twitter: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    youtube: "",
  },
  creditList: [],
  bankList: [],
  walletList: [],
})

const convertToForm = (data) => {
  const requiredData = Object.fromEntries(
    [
      "nickname",
      "lastName",
      "firstName",
      "lastNameKana",
      "firstNameKana",
      "birthDate",
      "email",
    ].map((key) => [key, data[key]])
  )

  return {
    ...requiredData,
    emailConfirmation: data.email,
    password: "",
    passwordConfirmation: "",
  }
}

const convertToMyPageForm = (profile) => {
  const requiredData = Object.fromEntries(
    [
      "nickname",
      "profilePicUrl",
      "profileOverview",
      "story",
      "url1",
      "url2",
      "url3",
      "url4",
      "url5",
      "twitter",
      "facebook",
      "instagram",
      "tiktok",
      "youtube",
    ].map((key) => [key, profile[key]])
  )

  let story = ""
  try {
    story = JSON.parse(profile.story)
  } catch (_err) {
    // invalid json
  }

  return {
    ...requiredData,
    story,
    name: profile.nickname,
  }
}

const generalAccountReducers = (state = initialState(), action) => {
  switch (action.type) {
    case GENERAL_ACCOUNT_LIST_LOADED:
      return {
        ...state,
        list: action.payload,
      }
    case GENERAL_ACCOUNT_LIST_TRANSITION_LOADED:
      return {
        ...state,
        transition: action.payload,
      }
    case GENERAL_ACCOUNT_LOADED: {
      return {
        ...state,
        form: convertToForm(action.payload),
        mypageForm: convertToMyPageForm(action.payload),
      }
    }
    case COLLECTION_LIST_LOADED:
      return {
        ...state,
        list: action.payload,
      }
    case COLLECTION_LIST_ERROR:
      throw new Error()
    case GENERAL_CREDIT_LIST_LOADED: {
      return {
        ...state,
        creditList: action.payload,
      }
    }
    case GENERAL_BANK_LIST_LOADED: {
      return {
        ...state,
        bankList: action.payload,
      }
    }
    case GENERAL_WALLET_LIST_LOADED: {
      return {
        ...state,
        walletList: action.payload,
      }
    }
    case GENERAL_ACCOUNT_INIT: {
      const newState = initialState()
      return {
        ...state,
        form: newState.form,
        mypageForm: newState.mypageForm,
      }
    }
    case GENERAL_ACCOUNT_UPDATED:
    case GENERAL_ACCOUNT_DELETED:
      return {
        ...state,
      }
    case GENERAL_ACCOUNT_LIST_ERROR:
    case GENERAL_ACCOUNT_ERROR:
    case GENERAL_CREDIT_LIST_ERROR:
    case GENERAL_BANK_LIST_ERROR:
    case GENERAL_WALLET_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default generalAccountReducers
