import {
  OFFER_REQUEST_PROCESSING,
  OFFER_REQUEST_COMPLETED,
  OFFER_REQUEST_FAILED,
} from "./types"

const initialState = {
  requestStatus: "notStarted",
}

const offerReducers = (state = initialState, action) => {
  switch (action.type) {
    case OFFER_REQUEST_PROCESSING:
      return {
        ...state,
        requestStatus: "processing",
      }
    case OFFER_REQUEST_COMPLETED:
      return {
        ...state,
        requestStatus: "completed",
      }
    case OFFER_REQUEST_FAILED:
      return {
        ...state,
        requestStatus: "failed",
      }
    default:
      return state
  }
}

export default offerReducers
