export const PURCHASE_INIT = "PURCHASE_INIT"
export const PURCHASE_NFT_REQUEST = "PURCHASE_NFT_REQUEST"
export const PURCHASE_NFT_LOADED = "PURCHASE_NFT_LOADED"
export const PURCHASE_NFT_ERROR = "PURCHASE_NFT_ERROR"
export const PURCHASE_ERROR = "PURCHASE_ERROR"

export const USER_CREDIT_LIST_REQUEST = "USER_CREDIT_LIST_REQUEST"
export const USER_CREDIT_LIST_LOADED = "USER_CREDIT_LIST_LOADED"
export const USER_CREDIT_LIST_ERROR = "USER_CREDIT_LIST_ERROR"

export const USER_BANK_LIST_LOADED = "USER_BANK_LIST_LOADED"
export const USER_BANK_LIST_ERROR = "USER_BANK_LIST_ERROR"

export const USER_WALLET_LIST_LOADED = "USER_WALLET_LIST_LOADED"
export const USER_WALLET_LIST_ERROR = "USER_WALLET_LIST_ERROR"

export const MARKET_ADDRESS_LOADED = "MARKET_ADDRESS_LOADED"

export const PURCHASE_COMPLETE_REQUEST = "PURCHASE_COMPLETE_REQUEST"
export const PURCHASE_COMPLETE_LOADED = "PURCHASE_COMPLETE_LOADED"
export const PURCHASE_COMPLETE_ERROR = "PURCHASE_COMPLETE_ERROR"

export const PURCHASE_COMPLETE_BLOCKCHAIN_POST =
  "PURCHASE_COMPLETE_BLOCKCHAIN_POST"
export const PURCHASE_COMPLETE_BLOCKCHAIN_ERROR =
  "PURCHASE_COMPLETE_BLOCKCHAIN_ERROR"

export const LOCK_PAYMENT_METHOD_POST = "LOCK_PAYMENT_METHOD_POST"
