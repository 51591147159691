import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import { Icon } from "@material-ui/core"
import Button from "../../components/Button/Button"
import { NFT_MARKET_PATH } from "../../navigation/constatnsPaths"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 221,
    marginBottom: theme.spacing(9),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: "140px",
    marginBottom: 120,
    [theme.breakpoints.down("sm")]: {
      fontSize: 180,
    },
  },
  text: {
    display: "flex",
    textAlign: "center",
    marginBottom: theme.spacing(9),
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
}))

const NotFound = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>404</h1>
      <div className={classes.text}>
        お探しのページが見つかりません。
        <br />
        恐れ入りますが、トップに戻り再度お試しください。
      </div>
      <Link to={NFT_MARKET_PATH} className={classes.text}>
        <Button
          text="トップに戻る"
          icon={<Icon style={{ fontSize: 25 }}>home</Icon>}
        />
      </Link>
    </div>
  )
}

export default NotFound
