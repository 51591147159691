import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core"
import { useField, useFormikContext, ErrorMessage } from "formik"
import TextError from "../TextError/TextError"
import "./CheckBox.scss"

const CheckBoxWrapper = forwardRef(({ name, label, legend, ...props }, ref) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)
  const handleChange = (event) => {
    const { checked } = event.target
    setFieldValue(name, checked)
  }

  const configCheckbox = {
    ...field,
    ...props,
    onChange: handleChange,
  }
  const configFormControl = {}
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true
  }
  return (
    <FormControl {...configFormControl}>
      <FormLabel component="legend">{legend}</FormLabel>
      <FormGroup className="checkboxBtn">
        <FormControlLabel
          control={
            <Checkbox
              ref={ref}
              classes={{ root: "custom-checkbox-root" }}
              {...configCheckbox}
              // textで渡るときがあるので
              checked={!!configCheckbox.checked}
            />
          }
          label={label}
        />
      </FormGroup>
      <ErrorMessage component={TextError} name={name} />
    </FormControl>
  )
})

CheckBoxWrapper.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  legend: PropTypes.string,
}

export default CheckBoxWrapper
