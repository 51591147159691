import {
  BANK_TRANSFERED_HITORY_LIST_LOADED,
  BANK_TRANSFERED_HITORY_LIST_ERROR,
} from "./types"

const getAccountType = (code) => {
  switch (code) {
    case "1":
      return "普通"
    case "2":
      return "当座"
    case "4":
      return "貯蓄"
    case "9":
      return "その他"
    default:
      return ""
  }
}

const initialState = () => ({
  list: [],
})

const convertToList = (data) => ({
  id: data.id,
  orderId: data.orderId,
  createdAt: data.transactionDate,
  transferedAt: data.transferDesignatedDate,
  status: data.status ?? "",
  itemId: data.nftId,
  token: data.userNftId,
  price: data.transferAmount,
  commission: data.tansferDetailFee ?? "0",
  to: data.toUserId,
  bankCode: data.beneficiaryBankCode,
  bank: data.beneficiaryBankName,
  branchCode: data.beneficiaryBranchCode,
  branch: data.beneficiaryBranchName,
  accountType: getAccountType(data.accountTypeCode),
  accountNumber: data.accountNumber,
  name: data.beneficiaryName,
})

const bankTransferManagementReducers = (state = initialState(), action) => {
  switch (action.type) {
    case BANK_TRANSFERED_HITORY_LIST_LOADED:
      return {
        ...state,
        list: action.payload.map((data) => convertToList(data)),
      }
    case BANK_TRANSFERED_HITORY_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default bankTransferManagementReducers
