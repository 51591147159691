/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from "@material-ui/core"
import { useField, FieldArray } from "formik"

const useStyles = makeStyles((_theme) => ({
  root: {
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
    "& .custom-checkbox-root .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
  },
}))

const CheckBoxGroup = React.memo(({ name, options, ...props }) => {
  const classes = useStyles()
  const [field, meta] = useField(name)

  const configCheckbox = {
    ...field,
    ...props,
  }
  const configFormControl = {}
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true
  }

  return (
    <FormControl {...configFormControl} className={classes.root}>
      <FormGroup>
        {options.map((option, i) => (
          <FieldArray name={name} key={i}>
            {({ remove, push }) => (
              <FormControlLabel
                name={option.value}
                control={<Checkbox />}
                {...configCheckbox}
                classes={{ root: "custom-checkbox-root" }}
                checked={field.value.includes(option.value)}
                onChange={(e, checked) => {
                  if (checked) {
                    push(option.value)
                  } else {
                    const optionIndex = field.value.indexOf(option.value)
                    remove(optionIndex)
                  }
                }}
                label={option.name}
              />
            )}
          </FieldArray>
        ))}
      </FormGroup>
    </FormControl>
  )
})
export default CheckBoxGroup

CheckBoxGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
}
