import React from "react"
import PropTypes from "prop-types"
import TextField from "./TextField/TextField"
import Select from "./Select/Select"
import RadioButton from "./RadioButton/RadioButton"
import Checkbox from "./CheckBox/CheckBox"
import Switch from "./Switch/Switch"
import TextArea from "./TextArea/TextArea"
import MethodButton from "./MethodButton/MethodButton"
import MethodCheckButton from "./MethodCheckButton/MethodCheckButton"

const FormControl = ({ control, ...props }) => {
  switch (control) {
    case "textfield":
      return <TextField {...props} />
    case "select":
      return <Select {...props} />
    case "radio":
      return <RadioButton {...props} />
    case "checkbox":
      return <Checkbox {...props} />
    case "switch":
      return <Switch {...props} />
    case "textarea":
      return <TextArea {...props} />
    case "methodbutton":
      return <MethodButton {...props} />
    case "methodcheckbutton":
      return <MethodCheckButton {...props} />
    default:
      return null
  }
}

FormControl.propTypes = {
  control: PropTypes.node,
}

export default FormControl
