import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { parse as queryParse } from "query-string"
import { Drawer, Box, useTheme, useMediaQuery } from "@material-ui/core"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import SwitchComponents from "../SwitchComponents/SwitchComponents"
import Notification from "../NotificationNewsMessages/Notification"
import Messages from "../NotificationNewsMessages/Messages"
import Search from "../../pages/Search/SearchView"
import store from "../../store/store"
import { getCategory, getCategoryList } from "../../store/master/actions"

const Layout = ({ children }) => {
  const theme = useTheme()
  const isSp = useMediaQuery(theme.breakpoints.down("xs"))
  const [isOpenNotificationDrawer, setIsOpenNotificationDrawer] =
    React.useState(false)
  const [isOpenNewsDrawer, setIsOpenNewsDrawer] = React.useState(false)
  const [isOpenMessagesDrawer, setIsOpenMessagesDrawer] = React.useState(false)
  const [isOpenSearchDrawer, setIsOpenSearchDrawer] = React.useState(false)
  const [activeComponent, setActiveComponent] = React.useState("children")
  const { masterData } = useSelector((state) => state)
  const { search } = useLocation()

  React.useEffect(() => {
    store.dispatch(getCategoryList())
  }, [])

  React.useEffect(() => {
    // サイトカテゴリー取得（URLパラメータ）
    const { category } = queryParse(search, { arrayFormat: "bracket" })
    if (category && category !== masterData.category?.urlParameter) {
      store.dispatch(getCategory(category))
    }
  }, [search, masterData.category])

  // ドロワーお知らせOpenメニュー
  const handleToggleNotificationDrawer = React.useCallback(
    (value) => {
      setActiveComponent("notification")
      setIsOpenNotificationDrawer(value)
      setIsOpenSearchDrawer(false)
      if (!value) setActiveComponent("children")
    },
    [setIsOpenNotificationDrawer, setActiveComponent]
  )
  // ドロワー速報メニュー
  const handleToggleNewsDrawer = React.useCallback(
    (value) => {
      setActiveComponent("news")
      setIsOpenNewsDrawer(value)
      if (!value) setActiveComponent("children")
    },
    [setIsOpenNewsDrawer, setActiveComponent]
  )
  // ドロワーメッセージメニュー
  const handleToggleMessagesDrawer = React.useCallback(
    (value) => {
      setActiveComponent("message")
      setIsOpenMessagesDrawer(value)
      if (!value) setActiveComponent("children")
    },
    [setIsOpenMessagesDrawer, setActiveComponent]
  )
  // ドロワー検索メニュー
  const handleToggleSearchDrawer = React.useCallback(
    (value) => {
      setActiveComponent("search")
      setIsOpenSearchDrawer(value)
      if (!value) setActiveComponent("children")
    },
    [setIsOpenSearchDrawer, setActiveComponent]
  )
  return (
    <>
      <Header
        handleToggleNotificationDrawer={handleToggleNotificationDrawer}
        handleToggleNewsDrawer={handleToggleNewsDrawer}
        handleToggleMessagesDrawer={handleToggleMessagesDrawer}
        handleToggleSearchDrawer={handleToggleSearchDrawer}
        isOpenSearchMenu={isOpenSearchDrawer}
        isOpenNotificationDrawer={isOpenNotificationDrawer}
      />
      <div className="noti-modal">
        {isSp ? (
          <SwitchComponents active={activeComponent}>
            <Notification
              name="notification"
              onClose={handleToggleNotificationDrawer}
              title="Information"
              widthSize="100%"
              notificationType="news"
            />
            <Notification
              name="news"
              onClose={handleToggleNewsDrawer}
              title="速報"
              icon="offline_bolt"
              widthSize="100%"
              notificationType="breakingnews"
            />
            <Messages
              name="message"
              onClose={handleToggleMessagesDrawer}
              title="メッセージ"
              icon="mail"
              widthSize="100%"
            />
            <Search
              name="search"
              isOpen
              isSp
              handleToggleSearchDrawer={handleToggleSearchDrawer}
            />
            <Box name="children">{children}</Box>
          </SwitchComponents>
        ) : (
          <>
            {/* PC用 お知らせ、速報、メッセージ */}
            {children}
            <Drawer
              anchor="right"
              open={isOpenNotificationDrawer}
              onClose={handleToggleNotificationDrawer}
            >
              <Notification
                onClose={handleToggleNotificationDrawer}
                title="Information"
                widthSize="350px"
                notificationType="news"
              />
            </Drawer>
            <Drawer
              anchor="right"
              open={isOpenNewsDrawer}
              onClose={handleToggleNewsDrawer}
            >
              <Notification
                onClose={handleToggleNewsDrawer}
                title="速報"
                icon="offline_bolt"
                widthSize="350px"
                notificationType="breakingnews"
              />
            </Drawer>
            <Drawer
              anchor="right"
              open={isOpenMessagesDrawer}
              onClose={handleToggleMessagesDrawer}
            >
              <Messages
                onClose={handleToggleMessagesDrawer}
                title="メッセージ"
                icon="mail"
                widthSize="600px"
              />
            </Drawer>
          </>
        )}
      </div>
      <Footer />
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.node,
}
export default Layout
