import React from "react"
import PropTypes from "prop-types"
import { Form, Formik } from "formik"
import { Box, Icon, IconButton, makeStyles } from "@material-ui/core"
import NotificationNewsMessagesLayout from "./NotificationNewsMessagesLayout"
import MessageLayout from "./Massages/MessageLayout"
import Button from "../Button/Button"
import MessageThreadDrawer from "./Massages/MessageThreadDrawer"
import TextArea from "../Forms/TextArea/TextArea"
import messageThreadData from "../../mock/messageThreadData.json"
import messageData from "../../mock/messageData.json"

const useStyles = makeStyles((theme) => ({
  root: {
    "& .drawer__close": {
      position: "relative",
      "@media (max-width: 767px)": {
        "& .MuiIconButton-root": {
          position: "absolute !important",
          left: "0 !important",
          top: "0 !important",
          bottom: "auto !important",
          right: "auto !important",
          width: "24px !important",
        },
      },
    },
    "& .drawer__open": {
      "& > .MuiBox-root": {
        "& > .MuiBox-root": {
          "&:first-child": {
            width: "280px",
            minWidth: "280px",
            "@media (max-width: 980px)": {
              width: "200px",
              minWidth: "200px",
              "@media (max-width: 440px)": {
                width: "0",
                minWidth: "0",
              },
            },
            "& .MuiDrawer-paper": {
              "@media (max-width: 980px)": {
                width: "200px",
                "@media (max-width: 440px)": {
                  padding: "0 6px 0 0",
                },
              },
            },
          },
        },
      },
    },
  },
  message: {
    "& > .MuiBox-root": {
      "&:first-child": {
        width: "0",
        minWidth: "0",
        transition: "0.3s",
        "& .MuiInputBase-formControl": {
          padding: "0 15px",
          background: theme.palette.bg_light,
          border: "none",
          borderRadius: "50px",
          "&::before": {
            borderBottom: "none",
          },
          "&::after": {
            borderBottom: "none",
          },
        },
        "& .MuiIconButton-label": {
          "& .MuiIcon-root": {
            margin: "-0.3rem 0 0 -0.5rem",
            fontSize: "1.8rem",
          },
        },
        "& .MuiBox-root": {
          alignItems: "center",
        },
        "& .MuiAvatar-circle": {
          width: "25px",
          height: "25px",
        },
        "& .MuiDrawer-paperAnchorLeft": {
          "& > .MuiBox-root": {
            "& > .MuiBox-root": {
              "& > .MuiBox-root": {
                "& > .MuiBox-root": {
                  "&:first-child": {
                    marginBottom: "5px",
                    lineHeight: "1.2",
                    "& ~ .MuiBox-root": {
                      fontSize: "10px",
                      color: "#9D9D9D",
                      fontWeight: "400",
                    },
                  },
                },
              },
            },
          },
        },
      },
      "&:last-child": {
        width: "100%",
        padding: "0 10px",
      },
      "& > .MuiBox-root": {
        "& > .MuiBox-root": {
          "& > .MuiBox-root": {
            maxWidth: "350px",
            margin: "0",
            alignSelf: "flex-start",
            "&:last-child": {
              marginLeft: "auto",
            },
            "& + .MuiBox-root": {
              marginLeft: "0",
            },
          },
        },
      },
    },
    "& .MuiAvatar-circle": {
      marginRight: "14px",
    },
  },
}))

// 組織・個人アカウント向けメッセージ
const Messages = ({ onClose, icon, title, widthSize }) => {
  const [open, setOpen] = React.useState(false)

  const handleSendMessage = (_val) => {}

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleDisplayMessage = () => {}

  const classes = useStyles()

  return (
    <NotificationNewsMessagesLayout
      onClose={onClose}
      icon={icon}
      title={title}
      widthSize={widthSize}
    >
      <Box className={classes.root}>
        <Box
          position="relative"
          mt={2}
          className={open ? "drawer__open" : "drawer__close"}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
          >
            <Icon>chevron_right</Icon>
          </IconButton>
          <Box display="flex" mb={3} className={classes.message}>
            <MessageThreadDrawer
              messageData={messageData}
              open={open}
              handleDrawerClose={handleDrawerClose}
              handleDisplayMessage={handleDisplayMessage}
            />
            <MessageLayout messageThreadData={messageThreadData} />
          </Box>
          <Formik
            initialValues={{
              message: "",
            }}
            onSubmit={(values) => handleSendMessage(values)}
          >
            <Form>
              <TextArea name="message" rowsMin={4} />
              <Box display="flex" justifyContent="flex-end">
                <Button text="送信" type="submit" icon={<Icon>send</Icon>} />
              </Box>
            </Form>
          </Formik>
        </Box>
      </Box>
    </NotificationNewsMessagesLayout>
  )
}

Messages.propTypes = {
  onClose: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  widthSize: PropTypes.string,
}

export default Messages
