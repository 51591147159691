import {
  NFT_LIKE_INIT,
  NFT_LIKE_LOADED,
  NFT_LIKE_CREATED,
  NFT_LIKE_DELETED,
} from "./types"

const initialState = () => ({
  form: {
    nftId: 0,
    userId: 0,
  },
})

const likeReducers = (state = initialState(), action) => {
  switch (action.type) {
    case NFT_LIKE_INIT:
    case NFT_LIKE_LOADED:
    case NFT_LIKE_CREATED:
    case NFT_LIKE_DELETED:
    default:
      return state
  }
}

export default likeReducers
