import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { List, ListItem } from "@material-ui/core"

const FooterSideMenu = React.memo(({ routeList }) => {
  const FooterSideMenuList = routeList.map((route, _) => (
    <ListItem key={route.label}>
      {route.label === "運営会社" ? (
        <a href={route.path} target="_blank" rel="noreferrer">
          {route.label}
        </a>
      ) : (
        <Link to={route.path}>{route.label}</Link>
      )}
    </ListItem>
  ))

  return (
    <>
      <List>{FooterSideMenuList}</List>
    </>
  )
})

FooterSideMenu.propTypes = {
  routeList: PropTypes.arrayOf(PropTypes.object),
}

export default FooterSideMenu
