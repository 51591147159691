import { CONTACT_INIT, CONTACT_SENT } from "./types"

const initialState = () => ({
  form: {
    firstName: "",
    lastName: "",
    firstNameKana: "",
    lastNameKana: "",
    zipcode: "",
    prefecture: "",
    city: "",
    address: "",
    addressOther: "",
    tel: "",
    email: "",
    content: "",
  },
})

const contactReducers = (state = initialState(), action) => {
  switch (action.type) {
    case CONTACT_INIT:
      return {
        ...state,
        form: initialState().form,
      }
    case CONTACT_SENT:
    default:
      return state
  }
}

export default contactReducers
