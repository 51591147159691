import React from "react"
import PropTypes from "prop-types"
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { useField, useFormikContext, ErrorMessage } from "formik"
import TextError from "../TextError/TextError"

const useStyles = makeStyles((theme) => ({
  methodCheckButton: {
    "& .custom-check-method-root": {
      display: "none",
    },
    display: "flex",
    justifyContent: "space-between",
    "& .MuiFormControlLabel-root": {
      margin: "0",
      borderRadius: "5px",
      "& .MuiTypography-body1": {
        border: "1px solid",
        borderRadius: "10px",
        padding: "10px 6px",
        textAlign: "center",
      },
      "& .MuiFormControlLabel-label": {
        borderRadius: "5px",
        padding: "10px 6px",
        display: "block",
        width: "100%",
        "& .material-icons": {
          width: "100%",
          marginBottom: "-4px",
          fontSize: "22px",
          [theme.breakpoints.down("767")]: {
            fontSize: "18px",
          },
        },
        [theme.breakpoints.down("767")]: {
          padding: "10px 2px",
        },
        [theme.breakpoints.down("321")]: {
          padding: "10px 1px",
        },
        "& .MuiTypography-caption": {
          fontSize: "12px",
          lineHeight: "1.3",
          [theme.breakpoints.down("767")]: {
            fontSize: "12px",
          },
        },
      },
    },
    "& .Mui-checked + .MuiTypography-body1": {
      backgroundColor: "#692AF0",
      "& .MuiIcon-root": {
        color: theme.palette.bg_base,
      },
    },
    "& .MuiButtonBase-root": {
      "&.Mui-checked": {
        "& + .MuiFormControlLabel-label": {
          borderColor: "#692AF0",
          "& .currency-method .MuiChip-root": {
            borderColor: "#692AF0",
            "& .MuiChip-label": {
              color: "#692AF0",
            },
          },
          "& .MuiTypography-caption": {
            color: theme.palette.bg_base,
          },
        },
      },
    },
  },
}))

const MethodCheckButton = ({
  name,
  options,
  disabled,
  className,
  ...props
}) => {
  const classes = useStyles()
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)
  const [checkMethods, setCheckMethods] = React.useState({})
  const handleChange = (event) => {
    const { value, checked } = event.target
    const newValues = checked
      ? [...meta.value, value]
      : meta.value.filter((v) => v !== value)
    setFieldValue(name, newValues)
  }

  React.useEffect(() => {
    // フォーム値から支払い方式のチェック状態をセット
    const values = options.reduce((acc, option) => {
      acc[option.value] = meta.value.includes(option.value)
      return acc
    }, {})
    setCheckMethods(values)
  }, [options.value, options, meta.value])

  const configCheckbox = {
    ...field,
    ...props,
    onChange: handleChange,
  }
  const configFormControl = {}
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true
  }
  return (
    <FormControl {...configFormControl}>
      <FormGroup className={className}>
        <Box display="flex" className={classes.methodCheckButton}>
          {options.map(({ typeName, value, checked, icon }, _) => (
            <FormControlLabel
              key={typeName}
              disabled={disabled}
              control={
                <Checkbox
                  classes={{ root: "custom-check-method-root" }}
                  {...configCheckbox}
                  value={value}
                  checked={checkMethods[value] ?? false}
                  defaultChecked={checked}
                />
              }
              label={
                <>
                  {icon}
                  <Typography variant="caption">{typeName}</Typography>
                </>
              }
            />
          ))}
        </Box>
      </FormGroup>
      <ErrorMessage component={TextError} name={name} />
    </FormControl>
  )
}

MethodCheckButton.propTypes = {
  name: PropTypes.string,
  legend: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  className: PropTypes.string,
}

export default MethodCheckButton
