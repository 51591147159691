import { authApi } from "../../../api"
import {
  USER_BANK_LIST_REQUEST,
  USER_BANK_LIST_LOADED,
  USER_BANK_CREATED,
  USER_BANK_DELETED,
  USER_BANK_INIT,
  USER_CREDIT_LIST_REQUEST,
  USER_CREDIT_LIST_LOADED,
  USER_CREDIT_CREATED,
  USER_CREDIT_DELETED,
  USER_CREDIT_INIT,
  USER_WALLET_LIST_REQUEST,
  USER_WALLET_LIST_LOADED,
  USER_WALLET_CREATED,
  USER_WALLET_DELETED,
  USER_WALLET_INIT,
  OFFICIAL_BANK_LIST_REQUEST,
  OFFICIAL_BANK_LIST_LOADED,
  OFFICIAL_BANK_CREATED,
  OFFICIAL_BANK_DELETED,
  OFFICIAL_BANK_INIT,
  OFFICIAL_WALLET_LIST_REQUEST,
  OFFICIAL_WALLET_LIST_LOADED,
  OFFICIAL_WALLET_CREATED,
  OFFICIAL_WALLET_DELETED,
  OFFICIAL_WALLET_INIT,
} from "./types"

const getCreateParams = (values) => values

export const initUserCredit = () => (dispatch) =>
  dispatch({
    type: USER_CREDIT_INIT,
  })

export const initUserBank = () => (dispatch) =>
  dispatch({
    type: USER_BANK_INIT,
  })

export const initUserWallet = () => (dispatch) =>
  dispatch({
    type: USER_WALLET_INIT,
  })

export const initOfficialBank = () => (dispatch) =>
  dispatch({
    type: OFFICIAL_BANK_INIT,
  })

export const initOfficialWallet = () => (dispatch) =>
  dispatch({
    type: OFFICIAL_WALLET_INIT,
  })

export const getUserCreditList = () => (dispatch) => {
  dispatch({
    type: USER_CREDIT_LIST_REQUEST,
  })
  authApi.get(`/settings/credits`).then((res) => {
    dispatch({
      type: USER_CREDIT_LIST_LOADED,
      payload: res.data.data,
    })
  })
}

export const createUserCredit = (values) => (dispatch) =>
  authApi
    .post(`/settings/credits/register`, getCreateParams(values))
    .then(() => {
      dispatch({
        type: USER_CREDIT_CREATED,
        payload: values,
      })
    })

export const deleteUserCredit = (values) => (dispatch) =>
  authApi.post(`/settings/credits/delete`, getCreateParams(values)).then(() => {
    dispatch({
      type: USER_CREDIT_DELETED,
      payload: values,
    })
  })

export const getUserBankList = () => (dispatch) => {
  dispatch({
    type: USER_BANK_LIST_REQUEST,
  })
  return authApi.get(`/settings/banks`).then((res) => {
    dispatch({
      type: USER_BANK_LIST_LOADED,
      payload: res.data.data,
    })
  })
}

export const createUserBank = (values) => (dispatch) =>
  authApi.post(`/settings/banks/register`, getCreateParams(values)).then(() => {
    dispatch({
      type: USER_BANK_CREATED,
      payload: values,
    })
  })

export const deleteUserBank = (values) => (dispatch) =>
  authApi.post(`/settings/banks/delete`, getCreateParams(values)).then(() => {
    dispatch({
      type: USER_BANK_DELETED,
      payload: values,
    })
  })

export const getUserWalletList = () => (dispatch) => {
  dispatch({
    type: USER_WALLET_LIST_REQUEST,
  })
  return authApi.get(`/settings/wallets`).then((res) => {
    dispatch({
      type: USER_WALLET_LIST_LOADED,
      payload: res.data.data,
    })
  })
}

export const createUserWallet = (values) => (dispatch) =>
  authApi
    .post(`/settings/wallets/register`, getCreateParams(values))
    .then(() => {
      dispatch({
        type: USER_WALLET_CREATED,
        payload: values,
      })
    })

export const deleteUserWallet = (values) => (dispatch) =>
  authApi.post(`/settings/wallets/delete`, getCreateParams(values)).then(() => {
    dispatch({
      type: USER_WALLET_DELETED,
      payload: values,
    })
  })

export const getOfficialBankList = () => (dispatch) => {
  dispatch({
    type: OFFICIAL_BANK_LIST_REQUEST,
  })
  return authApi.get(`/admin/banks`).then((res) => {
    dispatch({
      type: OFFICIAL_BANK_LIST_LOADED,
      payload: res.data.data,
    })
  })
}

export const createOfficialBank = (values) => (dispatch) =>
  authApi.post(`/admin/banks/register`, getCreateParams(values)).then(() => {
    dispatch({
      type: OFFICIAL_BANK_CREATED,
      payload: values,
    })
  })

export const deleteOfficialBank = (values) => (dispatch) =>
  authApi.post(`/admin/banks/delete`, getCreateParams(values)).then(() => {
    dispatch({
      type: OFFICIAL_BANK_DELETED,
      payload: values,
    })
  })

export const getOfficialWalletList = () => (dispatch) => {
  dispatch({
    type: OFFICIAL_WALLET_LIST_REQUEST,
  })
  return authApi.get(`/admin/wallets`).then((res) => {
    dispatch({
      type: OFFICIAL_WALLET_LIST_LOADED,
      payload: res.data.data,
    })
  })
}

export const createOfficialWallet = (values) => (dispatch) =>
  authApi.post(`/admin/wallets/register`, getCreateParams(values)).then(() => {
    dispatch({
      type: OFFICIAL_WALLET_CREATED,
      payload: values,
    })
  })

export const deleteOfficialWallet = (values) => (dispatch) =>
  authApi.post(`/admin/wallets/delete`, getCreateParams(values)).then(() => {
    dispatch({
      type: OFFICIAL_WALLET_DELETED,
      payload: values,
    })
  })
