/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getNotificationNewsListAdd } from "../../store/notification/actions"
import store from "../../store/store"
import NotificationNewsMessagesLayout from "./NotificationNewsMessagesLayout"
import ThreadLayout from "./ThreadLayout"

const Notification = ({
  notificationType,
  onClose,
  icon,
  title,
  widthSize,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const data = useSelector((state) => state.notification[notificationType])
  const { category } = useSelector((state) => state.masterData)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      await store.dispatch(
        getNotificationNewsListAdd(notificationType, category.id)
      )
      setIsLoading(false)
    })()
  }, [notificationType, category])

  const handleOnVisible = async () => {
    if (!isLoading && !data.isLast) {
      setIsLoading(true)
      await store.dispatch(
        getNotificationNewsListAdd(
          notificationType,
          category.id,
          data.current + 1
        )
      )
      setIsLoading(false)
    }
  }

  return (
    <NotificationNewsMessagesLayout
      onClose={onClose}
      icon={icon}
      title={title}
      widthSize={widthSize}
    >
      {data.list.map((item, index) =>
        index === data.list.length - 1 ? (
          <ThreadLayout
            key={index}
            date={new Date(item.publishedAt).toLocaleDateString("ja-JP")}
            htmlText={item.descriptionHTML}
            onVisible={handleOnVisible}
          />
        ) : (
          <ThreadLayout
            key={index}
            date={new Date(item.publishedAt).toLocaleDateString("ja-JP")}
            htmlText={item.descriptionHTML}
          />
        )
      )}
    </NotificationNewsMessagesLayout>
  )
}

Notification.propTypes = {
  onClose: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  notificationType: PropTypes.string,
  widthSize: PropTypes.string,
}

export default Notification
