import { precautions } from "../../../utils/precautions"
import {
  SEARCH_INIT,
  SEARCH_LIST_LOADED,
  SEARCH_LIST_ERROR,
  CATEGORY_LIST_LOADED,
  CATEGORY_LIST_ERROR,
  BRAND_LIST_LOADED,
  BRAND_LIST_ERROR,
  SEARCH_HISTORY_LIST_LOADED,
  SEARCH_HISTORY_LIST_CLEAR,
  SEARCH_HISTORY_LIST_ERROR,
  NFT_DETAIL_LOADED,
  NFT_DETAIL_ERROR,
  PRIMARY_NFT_LIST_LOADED,
  PRIMARY_NFT_LIST_ERROR,
  SECONDARY_NFT_LIST_LOADED,
  SECONDARY_NFT_LIST_ERROR,
  SEARCH_SET,
  NFT_UUID_LIST_LOADED,
  NFT_UUID_TRANSACTION_LIST_LOADED,
  NFT_UUID_LIST_ERROR,
  NFT_UUID_TRANSACTION_LIST_ERROR,
  BANNER_LIST_ERROR,
  BANNER_LIST_LOADED,
  NFT_UUID_UPDATED,
  NFT_UUID_ERROR,
  NFT_UUID_BULK_UPDATED,
  NFT_UUID_BULK_ERROR,
} from "./types"

const salesMethods = {
  list: "定価販売",
  lottery: "抽選",
  auction: "オークション",
  エアドロップ: "エアドロップ",
}

const initialState = () => ({
  searchList: [],
  categoryList: [],
  brandList: [],
  bannerList: [],
  searchHistoryList: [],
  primaryNftList: [],
  secondaryNftList: [],
  error: null,
  nft: {
    coverImage: "",
    title: "",
    // detail: "",
    category: "",
    brand: "",
    web: "",
    salesMethod: "",
    salesStartedAt: null,
    salesEndedAt: null,
    price: "",
    currencyMethod: "",
    quantity: "",
    story: "",
    benefits: [],
    status: "",
    officialId: "",
    like: 0,
    user: null,
    official: {},
    buyoutPrice: "",
    maxAmount: "",
    resultAnnouncement: false,
    lottery: null,
    auction: null,
    isLike: false,
    isFavorite: false,
    isSalesStarted: true,
    userOfferedAmount: "",
    precaution: "",
    isExpiredPaymentDueDate: false,
    userAuction: [],
    userNft: [
      {
        transactions: [{}],
      },
    ],
  },
  form: {
    keyword: "",
    nftCategoryId: [],
    nftBrandId: [],
    detail: "",
    order: "new",
    date: [],
    dateValue: new Date(),
    salesMethod: [],
    currencyMethod: [],
    salesStatus: [],
    status: [],
  },
  searchQuery: {},
  uuidList: [],
  transactionList: [],
})

const convertToNftList = (data) => ({
  ...data,
})

const convertToTransactionList = (data) => {
  // TokenID変換履歴の場合
  if (data.userNft?.isChangedTokenId) return data
  // 取引履歴の場合
  return {
    ...data,
    salesMethod: salesMethods[data.salesMethod],
    to: data.toUser.nickname,
    from:
      data.fromUser === null ? data.fromOfficial.name : data.fromUser.nickname,
  }
}

const convertToCategoryBrand = (data) => ({
  id: data.id,
  name: data.name,
  image: data?.image,
  urlParameter: data?.urlParameter,
  value: data.id,
  checked: false,
})

const convertToDetail = (data) => {
  // convert story from string to object
  let storyObj = {}
  try {
    if (data.story) {
      storyObj = JSON.parse(data.story)
    }
  } catch (err) {
    console.error(err)
  }

  // convert private media contents description from string to object
  let privateMediaContentsDescriptionObj = {}
  try {
    if (data.privateMediaContentsDescription) {
      privateMediaContentsDescriptionObj = JSON.parse(
        data.privateMediaContentsDescription
      )
    }
  } catch (err) {
    console.error(err)
  }

  return {
    ...data,
    story: storyObj,
    nftPrivateMediaContentsDescription: privateMediaContentsDescriptionObj,
    price: String(data.price),
    royalty: String(data.royalty),
    secondaryCommission: String(data.secondaryCommission),
    salesStartedAt:
      data.salesStartedAt === null ? null : new Date(data.salesStartedAt),
    salesEndedAt:
      data.salesEndedAt === null ? null : new Date(data.salesEndedAt),
    salesMethod: data.salesMethod,
    precaution: data.precaution ?? precautions,
    nftPrivateMediaContents: data.nftPrivateMediaContents
      ? data.nftPrivateMediaContents
      : [],
  }
}

const convertToForm = (params) => {
  const requiredData = [
    "keyword",
    "nft_category_id",
    "nft_brand_id",
    "detail",
    "order",
    "date",
    "detail",
    "sales_method",
    "currency_method",
    "status",
    "sales_status",
    "category",
  ]
    .map((key) => [key, params[key]])
    .filter(
      ([_key, value]) =>
        typeof value !== "undefined" && value !== null && value !== ""
    )

  const searchQuery = Object.fromEntries(requiredData)

  const formValueEntries = requiredData.map(([key, value]) => {
    const camelKey = (key.charAt(0).toLowerCase() + key.slice(1)).replace(
      /[-_](.)/g,
      (_match, group1) => group1.toUpperCase()
    )
    return [camelKey, value]
  })

  const formValues = Object.fromEntries(formValueEntries)

  if (formValues.date) {
    formValues.dateValue = new Date(formValues.date)
    formValues.date = ["date"]
  }

  if (formValues.nftBrandId) {
    formValues.nftBrandId = formValues.nftBrandId.map((id) => Number(id))
  }
  if (formValues.nftCategoryId) {
    formValues.nftCategoryId = formValues.nftCategoryId.map((id) => Number(id))
  }

  return {
    form: {
      ...initialState().form,
      ...formValues,
    },
    searchQuery,
  }
}

const searchNftReducers = (state = initialState(), action) => {
  switch (action.type) {
    case SEARCH_LIST_LOADED:
      return {
        ...state,
        searchList: action.payload.map((value) => convertToNftList(value)),
      }
    case PRIMARY_NFT_LIST_LOADED:
      return {
        ...state,
        primaryNftList: action.payload.map((value) => convertToNftList(value)),
      }
    case SECONDARY_NFT_LIST_LOADED:
      return {
        ...state,
        secondaryNftList: action.payload.map((value) =>
          convertToNftList(value)
        ),
      }
    case CATEGORY_LIST_LOADED:
      return {
        ...state,
        categoryList: action.payload.map((value) =>
          convertToCategoryBrand(value)
        ),
      }
    case BANNER_LIST_LOADED:
      return {
        ...state,
        bannerList: action.payload,
      }
    case SEARCH_SET: {
      const { form, searchQuery } = convertToForm(action.payload)
      return {
        ...state,
        form,
        searchQuery,
      }
    }
    case BRAND_LIST_LOADED:
      return {
        ...state,
        brandList: action.payload.map((value) => convertToCategoryBrand(value)),
      }
    case SEARCH_HISTORY_LIST_LOADED:
      return {
        ...state,
        searchHistoryList: action.payload,
      }
    case SEARCH_HISTORY_LIST_CLEAR:
      return {
        ...state,
        searchHistoryList: [],
      }
    case NFT_DETAIL_LOADED:
      return {
        ...state,
        error: null,
        nft: convertToDetail(action.payload),
      }
    case SEARCH_INIT:
      return {
        ...state,
        form: initialState().form,
      }
    case NFT_UUID_LIST_LOADED:
      return {
        ...state,
        uuidList: action.payload,
      }
    case NFT_UUID_TRANSACTION_LIST_LOADED:
      return {
        ...state,
        transactionList: action.payload.map((data) =>
          convertToTransactionList(data)
        ),
      }
    case NFT_UUID_UPDATED:
      return {
        ...state,
        uuidList: state.uuidList.map((data) => {
          if (data.uuid === action.payload.uuid) {
            const { polygonSignature, ethereumSignature } = action.payload
            if (polygonSignature) {
              data.polygonSignature = polygonSignature
            }
            if (ethereumSignature) {
              data.ethereumSignature = ethereumSignature
            }
          }
          return data
        }),
      }
    case NFT_UUID_BULK_UPDATED:
      return {
        ...state,
        uuidList: action.payload,
      }
    case SEARCH_LIST_ERROR:
    case CATEGORY_LIST_ERROR:
    case BANNER_LIST_ERROR:
    case BRAND_LIST_ERROR:
    case SEARCH_HISTORY_LIST_ERROR:
    case NFT_DETAIL_ERROR:
    case NFT_UUID_LIST_ERROR:
    case NFT_UUID_TRANSACTION_LIST_ERROR:
      return {
        ...initialState(),
        error: action.payload,
      }
    case PRIMARY_NFT_LIST_ERROR:
    case SECONDARY_NFT_LIST_ERROR:
    case NFT_UUID_ERROR:
    case NFT_UUID_BULK_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default searchNftReducers
