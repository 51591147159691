import MasterCard from "../assets/creditcards/master.svg"
import Visa from "../assets/creditcards/visa.svg"
import American from "../assets/creditcards/american.svg"
import JCB from "../assets/creditcards/jcb.svg"
import Diners from "../assets/creditcards/diners.svg"

export const creditcardImages = [
  { name: "MasterCard", icon: MasterCard },
  { name: "Visa", icon: Visa },
  { name: "American Express", icon: American },
  { name: "JCB", icon: JCB },
  { name: "Diners", icon: Diners },
]
