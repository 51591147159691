import {
  USER_BANK_LIST_LOADED,
  USER_BANK_CREATED,
  USER_BANK_DELETED,
  USER_BANK_LIST_ERROR,
  USER_BANK_INIT,
  USER_CREDIT_LIST_LOADED,
  USER_CREDIT_CREATED,
  USER_CREDIT_DELETED,
  USER_CREDIT_LIST_ERROR,
  USER_CREDIT_INIT,
  USER_WALLET_LIST_LOADED,
  USER_WALLET_CREATED,
  USER_WALLET_DELETED,
  USER_WALLET_LIST_ERROR,
  USER_WALLET_INIT,
  OFFICIAL_BANK_LIST_LOADED,
  OFFICIAL_BANK_CREATED,
  OFFICIAL_BANK_DELETED,
  OFFICIAL_BANK_LIST_ERROR,
  OFFICIAL_BANK_INIT,
  OFFICIAL_WALLET_LIST_LOADED,
  OFFICIAL_WALLET_CREATED,
  OFFICIAL_WALLET_DELETED,
  OFFICIAL_WALLET_LIST_ERROR,
  OFFICIAL_WALLET_INIT,
} from "./types"

const initialState = () => ({
  creditList: [],
  bankList: [],
  walletList: [],
  creditForm: {
    cardHolder: "",
    cardMonth: "",
    cardYear: "",
    cardNumber: "",
    cvc: "",
  },
  bankForm: {
    bankName: "",
    branchName: "",
    accountType: "普通",
    accountNumber: "",
    accountHolder: "",
  },
  walletForm: {
    walletAddress: "",
  },
})

const convertToCreditForm = (data) => ({
  ...data,
})

const convertToBankForm = (data) => ({
  ...data,
  bankId: data.id,
})

const bankManagementReducers = (state = initialState(), action) => {
  switch (action.type) {
    case USER_CREDIT_LIST_LOADED:
      return {
        ...state,
        creditList: action.payload.map((data) => convertToCreditForm(data)),
      }
    case USER_BANK_LIST_LOADED:
    case OFFICIAL_BANK_LIST_LOADED:
      return {
        ...state,
        bankList: action.payload.map((data) => convertToBankForm(data)),
      }
    case USER_WALLET_LIST_LOADED:
    case OFFICIAL_WALLET_LIST_LOADED:
      return {
        ...state,
        walletList: action.payload.map((data) => convertToBankForm(data)),
      }
    case USER_BANK_INIT:
    case OFFICIAL_BANK_INIT:
      return {
        ...state,
        bankForm: initialState().bankForm,
      }
    case USER_CREDIT_INIT:
      return {
        ...state,
        creditForm: initialState().creditForm,
      }
    case USER_WALLET_INIT:
    case OFFICIAL_WALLET_INIT:
      return {
        ...state,
        walletForm: initialState().walletForm,
      }
    case USER_CREDIT_CREATED:
    case USER_CREDIT_DELETED:
    case USER_BANK_CREATED:
    case USER_BANK_DELETED:
    case USER_WALLET_CREATED:
    case USER_WALLET_DELETED:
    case OFFICIAL_BANK_CREATED:
    case OFFICIAL_BANK_DELETED:
    case OFFICIAL_WALLET_CREATED:
    case OFFICIAL_WALLET_DELETED:
      return {
        ...state,
      }
    case USER_BANK_LIST_ERROR:
    case USER_CREDIT_LIST_ERROR:
    case USER_WALLET_LIST_ERROR:
    case OFFICIAL_BANK_LIST_ERROR:
    case OFFICIAL_WALLET_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default bankManagementReducers
