export const NFT_MARKET_PATH = "/"
export const NFT_MARKET_LIST_PATH = "/nft"
export const MARKET_ITEM_PATH = "/items"
export const PURCHASE_PATH = "/purchase"
export const PURCHASE_COMPLETE_PATH = "/nfts/complete"
export const COLLECTION_PATH = "/collection"
export const MY_PAGE_PATH = "/mypage"
export const MY_PAGE_EDIT_PATH = "/mypage/edit"
export const SECONDARY_LISTING_PATH = "/secondary_listing"
export const EDIT_SECONDARY_LISTING_PATH = "/secondary_listing/edit"
export const NFT_MANAGEMENT_PATH = "/nft_management"
export const NFT_MANAGEMENT_OFFICIAL_PATH = "/nft_management_official"
export const CREATE_NEW_NFT_PATH = "/nft_management/new"
export const DUPLICATE_NFT_PATH = "/nft_management/duplicate"
export const NFT_MANAGEMENT_ITEM_PATH = "/nft_management/items"
export const EDIT_NFT_PATH = "/nft_management/edit"
export const TICKET_MANAGEMENT_PATH = "/ticket_management"
export const CREATE_NEW_BENEFIT_PATH = "/benefit_new"
export const BENEFITS_MANAGEMENT_ACCOUNT_SELECT_PATH =
  "/benefits_account_select"
export const USE_BENEFITS_MANAGEMENT_PATH = "/use_benefits_management"
export const BENEFITS_PATH = "/benefits"
export const BENEFITS_MANAGEMENT_PATH = "/benefits_management"
export const BENEFITS_MANAGEMENT_OFFICIAL_PATH = "/benefits_management_official"
export const SALES_MANAGEMENT_PATH = "/sales_management"
export const SALES_OFFICIAL_MANAGEMENT_PATH = "/sales_official_management"
export const PAYMENT_OFFICIAL_MANAGEMENT_PATH = "/payment_official_management"
export const PAYMENT_MANAGEMENT_PATH = "/payment_management"
export const TRADING_HISTORY_PATH = "/trading_history"
export const ACCOUNT_MANAGEMENT_PATH = "/account_management"
export const BANK_CREDIT_INFO_PATH = "/bank_credit_information"
export const USER_MANAGEMENT_PATH = "/user_management"
export const CREATE_NEW_USER_PATH = "/user_management/new"
export const EDIT_USER_PATH = "/user_management/edit"
export const ADMIN_GENERAL_ACCOUNT_MANAGEMENT_PATH = "/admin_general"
export const ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT_PATH = "/admin_official"
export const CREATE_NEW_OFFICIAL_PATH = "/official_management/new"
export const ADMIN_NFT_MARKET_MANAGEMENT_PATH = "/admin_nft_market_management"

export const ADMIN_ACCOUNT_MANAGEMENT_PATH = "/admin_account_management"
export const CREATE_NEW_ADMIN_PATH = "/admin_account_management/new"
export const EDIT_ADMIN_PATH = "/admin_account_management/edit"
export const NOTIFICATION_NEWS_MANAGEMANT_PATH = "/notification_news_management"
export const CREATE_NEW_NOTIFICATION_NEWS_PATH =
  "/notification_news_management/new"
export const EDIT_NOTIFICATION_NEWS_PATH = "/notification_news_management/edit"
export const BANK_TRANSFERE_HISTORY_PATH = "/bank_transfer"
export const LINK_BANK_PATH = "/linkbank"
export const LOG_IN_PATH = "/login"
export const LOGIN_BY_TID_PATH = "/login_by_tid"
export const SIGN_UP_PATH = "/signup"
export const FORGOT_PASSWORD_PATH = "/forgot_password"
export const RESET_PASSWORD_PATH = "/reset_password"
export const SNS_CHECK_PATH = "/sns_check"
export const SETTINGS_PATH = "/settings"
export const SYSTEM_MANAGEMENT_PATH = "/system"
export const MAINTENANCE_PATH = "/maintenance"
export const TERMS_PATH = `/terms/`
export const COMPANY_PATH = `/about/`
export const FLOW_PATH = `/flow/`
export const ABOUT_MARK_PATH = `/official/`
export const USAGE_GUIDE_PATH = `/guide/`
export const HELP_PATH = `/help/`
export const ABOUT_RETIRNS_PATH = `/return/`
export const PRIVACY_POLICY_PATH = `/privacy-policy/`
export const SCTA_PATH = `/commercial/`
export const ETH_PAYMANT_PATH = `/ethereum-payment/`
export const NOT_FOUND_PATH = "/404"
export const SYSTEM_ERROR_PATH = "/system_error"
export const CONTACT_PATH = "/contact"
export const AUCTION_PAYMENT_PATH = "/auction_payment"
export const FAQ_PATH = `/faq/`
export const API_PATH =
  `${process.env.REACT_APP_REST_BASE_URL}/api` ?? "http://localhost:8080/api"
