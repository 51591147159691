export const OFFICIAL_ACCOUNT_USER_LIST_LOADED =
  "OFFICIAL_ACCOUNT_USER_LIST_LOADED"
export const OFFICIAL_ACCOUNT_USER_LIST_ERROR =
  "OFFICIAL_ACCOUNT_USER_LIST_ERROR"

export const OFFICIAL_ACCOUNT_USER_REQUEST = "OFFICIAL_ACCOUNT_USER_REQUEST"
export const OFFICIAL_ACCOUNT_USER_INIT = "OFFICIAL_ACCOUNT_USER_INIT"
export const OFFICIAL_ACCOUNT_USER_LOADED = "OFFICIAL_ACCOUNT_USER_LOADED"
export const OFFICIAL_ACCOUNT_USER_CREATED = "OFFICIAL_ACCOUNT_USER_CREATED"
export const OFFICIAL_ACCOUNT_USER_UPDATED = "OFFICIAL_ACCOUNT_USER_UPDATED"
export const OFFICIAL_ACCOUNT_USER_DELETED = "OFFICIAL_ACCOUNT_USER_DELETED"
export const OFFICIAL_ACCOUNT_USER_ERROR = "OFFICIAL_ACCOUNT_USER_ERROR"
