import {
  SETTINGS_LANGUAGE_SET,
  SETTINGS_MAIL_SET,
  SETTINGS_MAINTENANCE_SET,
  SETTINGS_SYSTEM_LOADED,
  SETTINGS_USER_LOADED,
  SETTINGS_MAINTENANCE_LOADED,
} from "./types"

const initialState = () => ({
  user: {
    isMail: false,
    language: "ja",
  },
  maintenance: {
    maintenance: "OFF",
    maintenance_text: "",
  },
  maintenanceMode: {
    status: 200,
    maintenanceMessage: "",
  },
})

const settingsReducers = (state = initialState(), action) => {
  switch (action.type) {
    case SETTINGS_USER_LOADED:
      return {
        ...state,
        user: action.payload,
      }
    case SETTINGS_SYSTEM_LOADED:
      return {
        ...state,
        maintenance: action.payload,
      }
    case SETTINGS_MAINTENANCE_LOADED:
      return {
        ...state,
        maintenanceMode: {
          ...action.payload.data,
          status: action.payload.status,
        },
      }
    case SETTINGS_MAIL_SET:
      return {
        ...state,
        user: {
          ...state.user,
          isMail: action.payload.data.isMail,
        },
      }
    case SETTINGS_LANGUAGE_SET:
      return {
        ...state,
        user: {
          ...state.user,
          language: action.payload.data.language,
        },
      }
    case SETTINGS_MAINTENANCE_SET:
      return {
        ...state,
        maintenance: action.payload,
      }
    default:
      return state
  }
}

export default settingsReducers
