import React from "react"
import { Button } from "@material-ui/core"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  // ダークボタン (default)
  buttonDark: {
    color: theme.palette.bg_base,
    backgroundColor: theme.palette.main,
    // fontSize: "13px",
    "&:hover": {
      backgroundColor: theme.palette.bg_base,
      color: theme.palette.main,
    },
    "&:disabled": {
      color: "#d4d4d4",
      background: "#eee",
    },
  },
  // ライトボタン
  buttonLight: {
    color: theme.palette.main,
    backgroundColor: theme.palette.bg_base,
    // fontSize: "13px",
    "&:hover": {
      backgroundColor: theme.palette.main,
      color: theme.palette.bg_base,
    },
  },
  // デンジャーボタン
  buttonDanger: {
    color: theme.palette.bg_base,
    backgroundColor: theme.palette.danger,
    borderColor: theme.palette.danger,
    // fontSize: "13px",
    "&:hover": {
      backgroundColor: theme.palette.bg_base,
      color: theme.palette.main,
      borderColor: theme.palette.main,
    },
  },
}))

const ButtonWrapper = ({
  text = "",
  icon,
  isLight = false,
  isDanger = false,
  onClick,
  ...props
}) => {
  const classes = useStyles()

  if (isLight && isDanger)
    throw new Error("Only one of isDark and isDanger can be true.")

  let styleClass = classes.buttonDark
  if (isLight) styleClass = classes.buttonLight
  else if (isDanger) styleClass = classes.buttonDanger

  return (
    <Button
      {...props}
      className={styleClass}
      startIcon={icon}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

ButtonWrapper.propTypes = {
  text: PropTypes.node,
  icon: PropTypes.node,
  isLight: PropTypes.bool,
  isDanger: PropTypes.bool,
  onClick: PropTypes.func,
}

export default ButtonWrapper
