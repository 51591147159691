export const OFFICIAL_ACCOUNT_LIST_LOADED = "OFFICIAL_ACCOUNT_LIST_LOADED"
export const OFFICIAL_ACCOUNT_LIST_ERROR = "OFFICIAL_ACCOUNT_LIST_ERROR"

export const OFFICIAL_ACCOUNT_INIT = "OFFICIAL_ACCOUNT_INIT"
export const OFFICIAL_ACCOUNT_REQUEST = "OFFICIAL_ACCOUNT_REQUEST"
export const OFFICIAL_ACCOUNT_LOADED = "OFFICIAL_ACCOUNT_LOADED"
export const OFFICIAL_ACCOUNT_CREATED = "OFFICIAL_ACCOUNT_CREATED"
export const OFFICIAL_ACCOUNT_UPDATED = "OFFICIAL_ACCOUNT_UPDATED"
export const OFFICIAL_ACCOUNT_DELETED = "OFFICIAL_ACCOUNT_DELETED"
export const OFFICIAL_ACCOUNT_ERROR = "OFFICIAL_ACCOUNT_ERROR"

export const OFFICIAL_BANK_LIST_REQUEST = "OFFICIAL_BANK_LIST_REQUEST"
export const OFFICIAL_BANK_LIST_LOADED = "OFFICIAL_BANK_LIST_LOADED"
export const OFFICIAL_BANK_LIST_ERROR = "OFFICIAL_BANK_LIST_ERROR"
export const OFFICIAL_WALLET_LIST_REQUEST = "OFFICIAL_WALLET_LIST_REQUEST"
export const OFFICIAL_WALLET_LIST_LOADED = "OFFICIAL_WALLET_LIST_LOADED"
export const OFFICIAL_WALLET_LIST_ERROR = "OFFICIAL_WALLET_LIST_ERROR"

export const COLLECTION_LIST_REQUEST = "COLLECTION_LIST_REQUEST"
export const COLLECTION_LIST_LOADED = "COLLECTION_LIST_LOADED"
export const COLLECTION_LIST_ERROR = "COLLECTION_LIST_ERROR"
