export const USER_REQUEST = "USER_REQUEST"
export const USER_LOADED = "USER_LOADED"
export const USER_INIT = "USER_INIT"
export const USER_ERROR = "USER_ERROR"
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGIN_INIT = "LOGIN_INIT"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"
