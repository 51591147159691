import React from "react"
import PropTypes from "prop-types"
import { useField, useFormikContext } from "formik"
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  makeStyles,
  Icon,
  Box,
} from "@material-ui/core"

const useStyles = makeStyles((_theme) => ({
  root: {
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
  },
}))

const RadioButtonGroup = React.memo(({ name, options, ...props }) => {
  const classes = useStyles()
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)
  const handleChange = (event) => {
    const { value } = event.target
    setFieldValue(name, value)
  }

  const configRadioButton = {
    ...field,
    ...props,
    onChange: handleChange,
  }
  const configFormControl = {}
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true
  }

  return (
    <FormControl {...configFormControl} className={classes.root}>
      <RadioGroup {...configRadioButton}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio classes={{ root: "custom-radio-root" }} />}
            label={
              option.icon ? (
                <Box display="flex">
                  <Icon>{option.icon}</Icon>
                  <span>{option.name}</span>
                </Box>
              ) : (
                option.name
              )
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
})
export default RadioButtonGroup

RadioButtonGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
}
