import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import {
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormControl,
} from "@material-ui/core"
import { useField, useFormikContext, ErrorMessage } from "formik"
import TextError from "../TextError/TextError"

const RadioButtonWrapper = ({ name, legend, options, disabled, ...props }) => {
  const location = useLocation()
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)
  const handleChange = (event) => {
    const { value } = event.target
    setFieldValue(name, value)
  }

  const configRadioButton = {
    ...field,
    ...props,
    onChange: handleChange,
  }
  const configFormControl = {}
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true
  }
  return (
    <FormControl {...configFormControl}>
      <FormLabel component="legend">{legend}</FormLabel>
      <RadioGroup
        {...configRadioButton}
        className={configRadioButton.row ? "rowRadioBtn" : "radioBtn"}
      >
        {options.map(({ typeName, explain, icon, attention }) => (
          <Fragment key={typeName}>
            <FormControlLabel
              key={typeName}
              value={typeName}
              disabled={disabled}
              control={<Radio classes={{ root: "custom-radio-root" }} />}
              label={
                location.pathname.includes("signup") ? (
                  <>
                    {icon}
                    <p className="typeName">{typeName}</p>
                    <p>{explain}</p>
                  </>
                ) : (
                  explain
                )
              }
            />
            {attention && meta.value === typeName && (
              <Box
                fontSize="14px"
                mb={2}
                dangerouslySetInnerHTML={{ __html: attention }}
              />
            )}
          </Fragment>
        ))}
      </RadioGroup>
      <ErrorMessage component={TextError} name={name} />
    </FormControl>
  )
}

RadioButtonWrapper.propTypes = {
  name: PropTypes.string,
  legend: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
}

export default RadioButtonWrapper
