import {
  CATEGORY_SELECT_LIST_LOADED,
  NOTIFICATIONNEWS_CREATED,
  NOTIFICATIONNEWS_ERROR,
  NOTIFICATIONNEWS_INIT,
  NOTIFICATIONNEWS_LIST_ERROR,
  NOTIFICATIONNEWS_LIST_LOADED,
  NOTIFICATIONNEWS_LOADED,
  NOTIFICATIONNEWS_UPDATED,
} from "./types"

const initialState = () => ({
  categoryList: [],
  list: [],
  form: {
    messageType: "",
    nftCategoryId: "",
    sendType: [],
    description: "",
    publishedAt: null,
    expiredAt: null,
    _has_description: false,
  },
})

const convertToForm = (data) => {
  // convert to draft object
  let descriptionObj = {}
  try {
    descriptionObj = JSON.parse(data.description)
  } catch (_err) {
    console.error(_err)
  }
  return {
    ...data,
    description: descriptionObj,
    publishedAt: new Date(data.publishedAt),
    expiredAt: new Date(data.expiredAt),
    sendType: [data.sendType],
  }
}

const convertToCategoryList = (data) =>
  data.reduce(
    (acc, record) => {
      acc[record.id] = record.name
      return acc
    },
    { "": "未設定" }
  )

const notificationNewsManagementReducers = (state = initialState(), action) => {
  switch (action.type) {
    case CATEGORY_SELECT_LIST_LOADED:
      return {
        ...state,
        categoryList: convertToCategoryList(action.payload),
      }
    case NOTIFICATIONNEWS_LIST_LOADED:
      return {
        ...state,
        list: action.payload.map((data) => convertToForm(data)),
      }
    case NOTIFICATIONNEWS_LOADED:
      return {
        ...state,
        form: convertToForm(action.payload),
      }
    case NOTIFICATIONNEWS_INIT:
      return {
        ...state,
        form: initialState().form,
      }
    case NOTIFICATIONNEWS_CREATED:
    case NOTIFICATIONNEWS_UPDATED:
      return {
        ...state,
      }
    case NOTIFICATIONNEWS_LIST_ERROR:
    case NOTIFICATIONNEWS_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default notificationNewsManagementReducers
