export const USE_BENEFIT_MANAGEMENT_LIST_REQUEST =
  "USE_BENEFIT_MANAGEMENT_LIST_REQUEST"
export const USE_BENEFIT_MANAGEMENT_LIST_LOADED =
  "USE_BENEFIT_MANAGEMENT_LIST_LOADED"
export const USE_BENEFIT_MANAGEMENT_LIST_ERROR =
  "USE_BENEFIT_MANAGEMENT_LIST_ERROR"

export const USE_BENEFIT_MANAGEMENT_REQUEST = "USE_BENEFIT_MANAGEMENT_REQUEST"
export const USE_BENEFIT_MANAGEMENT_LOADED = "USE_BENEFIT_MANAGEMENT_LOADED"
export const USE_BENEFIT_MANAGEMENT_ERROR = "USE_BENEFIT_MANAGEMENT_ERROR"

export const USE_BENEFIT_MANAGEMENT_BENEFIT_USED =
  "USE_BENEFIT_MANAGEMENT_BENEFIT_USED"
