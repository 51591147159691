/* eslint-disable no-underscore-dangle */
import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { stringify as queryStringify } from "query-string"
import { Formik, Form, useFormikContext } from "formik"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container,
  Icon,
  List,
  ListItem,
  InputAdornment,
  IconButton,
  Box,
} from "@material-ui/core"
import TextField from "../../components/Forms/TextField/TextField"
import Button from "../../components/Button/Button"
import SearchSection from "../../components/Search/SearchSection/SearchSection"
import CheckBoxGroup from "../../components/Search/CheckBoxGroup/CheckBoxGroup"
import DatePicker from "../../components/Forms/DatePicker/DatePicker"
import { filter } from "../../utils/searchSectionList"
import { NFT_MARKET_LIST_PATH } from "../../navigation/constatnsPaths"
import store from "../../store/store"
import {
  getCategoryList,
  getBrandList,
  getSearchList,
  getSearchHistoryList,
} from "../../store/pages/search_nft/actions"
import { camel2snake } from "../../utils/helper"
import RadioButtonGroup from "../../components/Search/RadioButtonGroup/RadioButtonGroup"
import searchIcon from "../../assets/img/menu_icon_find.svg"
import HistoryIcon from "../../assets/img/search_icon_history.svg"
import CategoryIcon from "../../assets/img/search_icon_category.svg"
import { SEARCH_FORM_VALIDATION } from "../../utils/formValidation"
import ChangeIcon from "../../assets/img/search_icon_change.svg"
import ShoppingIcon from "../../assets/img/search_icon_shopping.svg"
import FilterIcon from "../../assets/img/search_icon_filter.svg"
import Close from "../../assets/img/close_icon.svg"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "428px",
    padding: "0 20px 100px",
    background: "#F7F7F7",
    overflow: "hidden",
    "& .form-inner": {
      width: "calc(100% + 8px)",
      height: "calc(100vh - 170px)",
      padding: "0 2px 0 0",
      marginRight: "-8px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        WebkitAppearance: "none",
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        backgroundColor: "rgba(0,0,0,0.5)",
        boxShadow: "0 0 1px rgba(255,255,255,.5)",
      },
      "@media (max-width: 767px)": {
        width: "100%",
        height: "auto",
        margin: "0",
      },
    },
    "@media (max-width: 767px)": {
      width: "100%",
    },
    "& .MuiContainer-root": {
      padding: "0 !important",
      "& .MuiList-padding": {
        padding: "0",
      },
    },

    "& .stickyBtn": {
      position: "absolute",
      left: "0",
      bottom: "0",
      width: "100%",
      padding: "20px",
      background: "#fff",
      borderRadius: "0",
      zIndex: "100",
      "& .MuiButton-text": {
        display: "flex",
        width: "100%",
        height: "60px",
        padding: "0 20px",
        margin: "0",
        background: "linear-gradient(32deg, #692AF0, #692AF0)",
        alignItems: "center",
        justifyContent: "center",
        transition: "0.3s ease-in-out",
        "& .MuiButton-label": {
          fontSize: "20px",
          fontWeight: "bold",
          color: "#fff",
          "& .MuiButton-startIcon": {
            display: "none",
          },
        },
        "&:hover": {
          background: "linear-gradient(32deg, #8F00FF, #5A2DDA)",
        },
      },
    },
  },
  formWrapper: {
    background: "#F7F7F7",
    "& label": {
      "& .MuiTypography-body1": {
        fontSize: "16px",
      },
    },
    "& .MuiList-padding": {
      marginBottom: "20px",
      border: "1px solid #DEDEDE",
      borderRadius: "16px",
      background: "#fff",
      overflow: "hidden",
      "& > .MuiBox-root": {
        "&:first-child": {
          display: "flex",
          height: "70px",
          padding: "20px",
          borderBottom: "1px solid #DEDEDE",
          alignItems: "center",
          "& .MuiBox-root": {
            paddingTop: "3px",
            fontSize: "16px",
          },
        },
      },
      "& hr": {
        display: "none !important",
      },
      "& .MuiListItem-root": {
        padding: "0",
        "& .MuiFormControl-root": {
          width: "100%",
        },
        "& .MuiFormControlLabel-root": {
          display: "flex",
          padding: "23px 20px",
          margin: "0",
          borderBottom: "1px solid #DEDEDE",
          "&:last-child": {
            borderBottom: "none",
          },
        },
      },
    },
    "& .correct": {
      "& .MuiList-padding": {
        marginTop: "4px",
        marginBottom: "18px",
        background: "transparent",
        border: "none",
        "& > .MuiBox-root": {
          "&:first-child": {
            display: "none",
          },
        },
      },
    },
    "& .check": {
      margin: "4px 20px 6px",
      color: "#9D9D9D",
    },
  },
  closeBtn: {
    position: "absolute",
    right: "20px",
    top: "25px",
    padding: "0",
    color: theme.palette.grey[500],
    "& span": {
      lineHeight: "1",
    },
  },
  search: {
    textAlign: "center",
    "& .MuiFormControl-root.MuiTextField-root": {
      width: "100%",
      height: "60px",
      padding: "0 20px",
      marginBottom: "11px",
      background: "transparent",
      border: "1px solid #DEDEDE",
      boxSizing: "border-box",
      borderRadius: "40px",
      [theme.breakpoints.down("767")]: {
        width: "100%",
      },
      "& .MuiInput-underline:before": {
        content: "inherit",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "transparent",
      },
      "& input": {
        height: "60px",
        padding: "0 5px",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    "& .MuiButton-text": {
      padding: "7px 20px 8px",
      [theme.breakpoints.down("414")]: {
        padding: "7px 14px 8px",
        "& .MuiButton-label": {
          fontSize: "14px",
        },
      },
    },
  },
  searchRadio: {
    display: "flex",
    flexDirection: "initial",
    justifyContent: "center",
  },
  searchSection: {
    "& .MuiList-root": {
      [theme.breakpoints.down("980")]: {
        marginRight: 0,
        marginLeft: 0,
        width: "100%",
      },
    },
    [theme.breakpoints.down("980")]: {
      display: "block",
    },
  },
  searchHistory: {
    width: "100% !important",
    margin: "0px !important",
    padding: "27px 30px 8px !important",
    border: "none !important",
    "& li": {
      display: "inline-block",
      width: "auto",
      margin: "0 40px 28px 0",
      fontSize: "16px",
      "&.searchWrd": {
        cursor: "pointer",
        textDecoration: "underline",
        color: "#692AF0",
        "&::before": {
          content: "'#'",
        },
      },
    },
  },
  top: {
    display: "flex",
    height: "70px",
    alignItems: "center",
    justifyContent: "space-between",
    "& .L-title": {
      fontSize: "26px",
      fontWeight: "600",
      fontFamily: "futura-pt, sans-serif",
      fontStyle: "italic",
    },
  },
}))

const HistoryItem = ({ keyword, ...props }) => {
  const { setFieldValue, submitForm } = useFormikContext()

  return (
    <ListItem
      {...props}
      onClick={() => {
        setFieldValue("_selectedKeyword", keyword)
        submitForm()
      }}
    >
      {keyword}
    </ListItem>
  )
}

HistoryItem.propTypes = {
  keyword: PropTypes.string,
}

const Search = ({ isOpen, onClose, isSp, handleToggleSearchDrawer }) => {
  const classes = useStyles()
  const history = useHistory()
  const data = useSelector((state) => state.searchNft)
  const masterData = useSelector((state) => state.masterData)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  React.useEffect(() => {
    // store.dispatch(initSearch())
    store.dispatch(getSearchList())
    store.dispatch(getCategoryList())
    store.dispatch(getBrandList())
  }, [])

  React.useEffect(() => {
    // ログイン時のみ履歴を取得
    if (isOpen && isAuthenticated) store.dispatch(getSearchHistoryList())
  }, [isOpen, isAuthenticated])

  const handleClickSearch = (values) => {
    const newValues = { ...values }

    // 日付のフォーマットを変換
    if (values.date.length > 0 && values.dateValue) {
      ;[newValues.date] = values.dateValue.toISOString().split("T")
    }
    delete newValues.dateValue

    // 検索履歴から検索
    if (values._selectedKeyword) {
      newValues.keyword = values._selectedKeyword
      delete newValues._selectedKeyword
    }

    // カテゴリーIDの再編成
    if (values.nftCategoryId) {
      newValues.nftCategoryId = [...new Set(values.nftCategoryId)]
      if (!newValues.nftCategoryId.includes(masterData.category.id)) {
        delete newValues.category
      }
    }

    // URLクエリ化
    const queryValue = Object.entries(newValues)
      .filter(([_key, value]) => value !== "" && value !== null)
      .filter(([_key, value]) => !Array.isArray(value) || value.length > 0)
      .map(([key, value]) =>
        Array.isArray(value) ? [key, value.sort()] : [key, value]
      )
      .map(([key, value]) => [camel2snake(key), value])
    const query = queryStringify(Object.fromEntries(queryValue), {
      arrayFormat: "bracket",
    })

    history.push({
      pathname: NFT_MARKET_LIST_PATH,
      search: query,
    })
    if (handleToggleSearchDrawer) {
      handleToggleSearchDrawer(false)
    } else {
      onClose()
    }
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.top}>
          <p className="L-title">Search</p>
          {!isSp && (
            <IconButton onClick={onClose} className={classes.closeBtn}>
              <Icon>
                <img src={Close} alt="" />
              </Icon>
            </IconButton>
          )}
        </Box>
        <Container>
          <div className={classes.formWrapper}>
            <Formik
              initialValues={{ ...data.form }}
              enableReinitialize
              onSubmit={(values) => handleClickSearch(values)}
              validationSchema={SEARCH_FORM_VALIDATION}
            >
              {({ values }) => (
                <Form>
                  <Box className="form-inner">
                    <div className={classes.search}>
                      <TextField
                        placeholder="検索する"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon>
                                <img src={searchIcon} alt="" />
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                        name="keyword"
                      />
                    </div>
                    <CheckBoxGroup
                      name="detail"
                      options={filter.detail.values}
                      className="check"
                    />
                    <List>
                      <Box display="flex">
                        <Icon>
                          <img src={HistoryIcon} alt="" />
                        </Icon>
                        <Box
                          fontSize={{ xs: 15, sm: 18 }}
                          fontWeight={700}
                          color="textPrimary"
                        >
                          検索ワード履歴
                        </Box>
                      </Box>
                      <ListItem disableGutters>
                        <List className={classes.searchHistory}>
                          {data.searchHistoryList.length > 0 ? (
                            <>
                              {data.searchHistoryList.map((search) => (
                                <HistoryItem
                                  className="searchWrd"
                                  key={search.id}
                                  keyword={search.keyword}
                                />
                              ))}
                            </>
                          ) : (
                            <ListItem>履歴はありません</ListItem>
                          )}
                        </List>
                      </ListItem>
                    </List>
                    <Box className={classes.searchSection}>
                      <SearchSection
                        title="並び替え"
                        icon={<img src={ChangeIcon} alt="" />}
                        isDisplay
                      >
                        <RadioButtonGroup
                          name="order"
                          type="brands"
                          options={filter.order.values}
                        />
                      </SearchSection>
                      <Box>
                        <SearchSection
                          title="カテゴリ"
                          icon={<img src={CategoryIcon} alt="" />}
                          isDisplay
                        >
                          <CheckBoxGroup
                            name="nftCategoryId"
                            type="categories"
                            options={data.categoryList}
                          />
                        </SearchSection>

                        <SearchSection
                          title="フィルター"
                          icon={<img src={FilterIcon} alt="" />}
                          isDisplay
                        >
                          <CheckBoxGroup
                            name="date"
                            options={filter.date.values}
                          />
                          <DatePicker
                            backgroundColor="transparent"
                            name="dateValue"
                            label="YYYY/MM/DD"
                            disabled={values.date.length === 0}
                          />
                        </SearchSection>
                        <SearchSection
                          title="販売方式"
                          icon={<img src={ShoppingIcon} alt="" />}
                          isDisplay
                        >
                          <CheckBoxGroup
                            name="salesMethod"
                            options={filter.salesMethod.values}
                          />
                        </SearchSection>

                        <SearchSection
                          title="流通"
                          icon={<img src={ShoppingIcon} alt="" />}
                          isDisplay
                        >
                          <CheckBoxGroup
                            name="status"
                            options={filter.status.values}
                          />
                        </SearchSection>
                        <SearchSection
                          title="販売状況"
                          icon={<img src={ShoppingIcon} alt="" />}
                          isDisplay
                        >
                          <CheckBoxGroup
                            name="salesStatus"
                            options={filter.salesStatus.values}
                          />
                        </SearchSection>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="stickyBtn"
                    display={{ xs: "block", sm: "inline-block" }}
                  >
                    <Button
                      text="検索する"
                      icon={<Icon>arrow_forward</Icon>}
                      type="submit"
                    />
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Box>
    </>
  )
}

Search.propTypes = {
  isOpen: PropTypes.bool,
  isSp: PropTypes.bool,
  onClose: PropTypes.func,
  handleToggleSearchDrawer: PropTypes.func,
}

export default Search
