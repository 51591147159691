/* eslint-disable prettier/prettier */
import React from "react"
import PropTypes from "prop-types"
import { TextareaAutosize, FormControl } from "@material-ui/core"
import { useField, ErrorMessage } from "formik"
import { omit } from "lodash"
import TextError from "../TextError/TextError"

const TextAreaWrapper = ({
  name,
  bgColor = "#EEEEEE",
  rowsMin = 2,
  ...props
}) => {
  const [field, meta] = useField(name)

  const configTextfield = {
    ...field,
    ...omit(props, "imageType"),
    style: {
      backgroundColor: bgColor,
      border: "1px solid #9D9D9D",
      borderRadius: "5px",
      width: "100%",
      boxSizing: "border-box",
      alignItems: "center",
      padding: "8px 10px",
      color: "#9D9D9D",
      maxHeight: "380px",
      overflow: "auto",
      resize: "none",
    },
  }

  const configFormControl = {}
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true
  }
  return (
    <FormControl {...configFormControl} style={{ width: "100%" }}>
      <TextareaAutosize minRows={rowsMin} {...configTextfield} />
      <ErrorMessage component={TextError} name={name} />
    </FormControl>
  )
}

TextAreaWrapper.propTypes = {
  name: PropTypes.string,
  bgColor: PropTypes.string,
  rowsMin: PropTypes.number,
}

export default TextAreaWrapper
