import React from "react"
import { Link } from "react-router-dom"
import { Box, makeStyles } from "@material-ui/core"
import logo from "../../assets/img/SAIN_LOGO_LIGHT.svg"
import { footerRoutes, footerRoutesOther } from "../../navigation/routes"
import FooterSideMenu from "../FooterSideMenu/FooterSideMenu"
import "./Footer.scss"
import { NFT_MARKET_PATH } from "../../navigation/constatnsPaths"

const useStyles = makeStyles((theme) => ({
  onlySp: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
      "& .footer-list": {
        marginLeft: "0",
      },
    },
  },
  footer: {
    [theme.breakpoints.down("767")]: {
      display: "block",
      "& .footer-list": {
        marginLeft: "0",
      },
    },
  },
  footerlist: {
    paddingTop: "10px",
    paddingLeft: "40px",
    [theme.breakpoints.down("767")]: {
      paddingLeft: "0",
    },
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className="footer">
      <Box display="flex" className={classes.footer}>
        <div>
          <Link to={NFT_MARKET_PATH} className="footer-logo">
            <img src={logo} alt="logo" width="331" height="auto" />
          </Link>
          <Box component="div" className="copyright sp-copyright">
            <Box className="copyright" textAlign="center">
              © Fanplus, Inc.
            </Box>
          </Box>
        </div>
        <div>
          <Box className={classes.footerlist}>
            <div className="footer-list">
              <FooterSideMenu routeList={footerRoutes} />
            </div>
            <div className="footer-list">
              <FooterSideMenu routeList={footerRoutesOther} />
            </div>
          </Box>
          <Box component="div" className="copyright">
            <Box className="copyright" textAlign="center">
              © Fanplus, Inc.
            </Box>
          </Box>
        </div>
      </Box>
    </footer>
  )
}

export default Footer
