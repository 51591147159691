import {
  getAuthInfo,
  removeAuthInfo,
  setAuthInfo,
} from "../../utils/authStorage"
import {
  USER_REQUEST,
  USER_LOADED,
  USER_INIT,
  USER_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_INIT,
  LOGOUT_SUCCESS,
  SIGNUP_SUCCESS,
} from "./types"

const initialState = () => {
  const auth = getAuthInfo()
  return {
    token: auth.token,
    role: auth.role,
    userId: auth.userId,
    userName: auth.userName,
    isAuthenticated: null,
    isLoading: false,
    error: null,
    form: {
      nickname: "",
      lastName: "",
      firstName: "",
      lastNameKana: "",
      firstNameKana: "",
      email: "",
      emailConfirmation: "",
      password: "",
      passwordConfirmation: "",
      birthDate: "",
    },
    loginForm: {
      email: "",
      password: "",
      keepLogIn: false,
    },
    user: {},
  }
}

const convertToForm = (data) => {
  const validKeys = [
    "nickname",
    "lastName",
    "firstName",
    "lastNameKana",
    "firstNameKana",
    "birthDate",
    "email",
  ]
  const requiredData = Object.fromEntries(
    Object.entries(data).filter(([key, _value]) => validKeys.includes(key))
  )
  return {
    ...requiredData,
    nickname: data.nickname || "",
    emailConfirmation: data.email,
    password: "",
    passwordConfirmation: "",
  }
}

const authReducer = (state = initialState(), action) => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        role: getAuthInfo().role,
        isLoading: true,
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload.data,
      }
    case SIGNUP_SUCCESS:
      return {
        ...state,
      }
    case LOGIN_SUCCESS: {
      const { user, token } = action.payload
      setAuthInfo({
        role: user.role,
        token,
        userId: user.id,
        userName: user.nickname,
      })
      return {
        ...state,
        ...action,
        token,
        role: user.role,
        userName: user.nickname,
        userId: user.id,
        isAuthenticated: true,
        isLoading: false,
        form: convertToForm(user),
        user,
        error: false,
      }
    }
    case LOGIN_FAIL:
      return {
        ...state,
        error: true,
      }
    case USER_INIT:
      return {
        ...state,
        isLoading: false,
        form: initialState().form,
      }
    case LOGIN_INIT:
      return {
        ...state,
        isLoading: false,
        loginForm: initialState().loginForm,
      }
    case LOGOUT_SUCCESS:
    case USER_ERROR:
      removeAuthInfo()
      return {
        ...state,
        form: initialState().user,
        token: null,
        isAuthenticated: false,
        userId: null,
        userName: null,
        role: null,
        isLoading: false,
        user: initialState().user,
      }
    default:
      return state
  }
}

export default authReducer
