import {
  OFFICIAL_MYPAGE_LOADED,
  OFFICIAL_MYPAGE_ERROR,
  USER_MYPAGE_LOADED,
  USER_MYPAGE_ERROR,
} from "./types"

const initialState = () => ({
  mypage: {
    name: "",
    profilePicUrl: "",
    profileOverview: "",
    story: {},
    url1: "",
    url2: "",
    url3: "",
    url4: "",
    url5: "",
    twitter: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    youtube: "",
    badgeImage: "",
    badgeText: "",
    badgeColor: "",
    error: null,
  },
})

const convertToMyPageForm = (profile) => {
  const requiredData = Object.fromEntries(
    [
      "name",
      "profilePicUrl",
      "profileOverview",
      "story",
      "url1",
      "url2",
      "url3",
      "url4",
      "url5",
      "twitter",
      "facebook",
      "instagram",
      "tiktok",
      "youtube",
      "badgeImage",
      "badgeText",
      "badgeColor",
      "nickname",
    ].map((key) => [key, profile[key]])
  )

  let story = ""
  try {
    story = JSON.parse(profile.story)
  } catch (_err) {
    // invalid json
  }

  const badgeStyle = profile.badgeImage ? "2" : "1"

  return {
    ...requiredData,
    story,
    name: requiredData.nickname ?? requiredData.name,
    _badgeStyle: badgeStyle,
  }
}

const sellerMyPageReducers = (state = initialState(), action) => {
  switch (action.type) {
    case OFFICIAL_MYPAGE_LOADED:
    case USER_MYPAGE_LOADED:
      return {
        ...state,
        mypage: convertToMyPageForm(action.payload),
      }
    case OFFICIAL_MYPAGE_ERROR:
    case USER_MYPAGE_ERROR:
      return {
        mypage: {
          error: action.payload,
        },
      }
    default:
      return state
  }
}

export default sellerMyPageReducers
