import {
  CATEGORY_LIST_LOADED,
  CATEGORY_LIST_ERROR,
  BRAND_LIST_LOADED,
  BRAND_LIST_ERROR,
} from "./types"

const initialState = () => ({
  categoryList: [],
  brandList: [],
})

const nftMarketCategoryBrandReducers = (state = initialState(), action) => {
  switch (action.type) {
    case CATEGORY_LIST_LOADED:
      return {
        ...state,
        categoryList: action.payload,
      }
    case BRAND_LIST_LOADED:
      return {
        ...state,
        brandList: action.payload,
      }
    case CATEGORY_LIST_ERROR:
    case BRAND_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default nftMarketCategoryBrandReducers
