export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST"
export const BRAND_LIST_REQUEST = "BRAND_LIST_REQUEST"
export const BANNER_LIST_REQUEST = "BANNER_LIST_REQUEST"
export const CATEGORY_LIST_LOADED = "CATEGORY_LIST_LOADED"
export const CATEGORY_SELECT_LIST_LOADED = "CATEGORY_SELECT_LIST_LOADED"
export const BRAND_LIST_LOADED = "BRAND_LIST_LOADED"
export const BANNER_LIST_LOADED = "BANNER_LIST_LOADED"
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR"
export const BRAND_LIST_ERROR = "BRAND_LIST_ERROR"
export const BANNER_LIST_ERROR = "BANNER_LIST_ERROR"

export const CATEGORY_CREATED = "CATEGORY_CREATED"
export const BRAND_CREATED = "BRAND_CREATED"
export const BANNER_CREATED = "BANNER_CREATED"
export const CATEGORY_UPDATED = "CATEGORY_UPDATED"
export const BRAND_UPDATED = "BRAND_UPDATED"
export const BANNER_UPDATED = "BANNER_UPDATED"
export const CATEGORY_DELETED = "CATEGORY_DELETED"
export const BRAND_DELETED = "BRAND_DELETED"
export const BANNER_DELETED = "BANNER_DELETED"

export const BRAND_INIT = "BRAND_INIT"
export const CATEGORY_INIT = "CATEGORY_INIT"
export const BANNER_INIT = "BANNER_INIT"
