import { precautions } from "../../../../utils/precautions"
import { getCurrencyFormattWithoutSymbol } from "../../../../utils/helper"
import {
  SECONDARY_NFT_MANAGEMENT_INIT,
  SECONDARY_NFT_MANAGEMENT_CREATED,
  SECONDARY_NFT_MANAGEMENT_ERROR,
  SECONDARY_NFT_MANAGEMENT_LOADED,
  SECONDARY_NFT_MANAGEMENT_DRAFT_LOADED,
  USER_BANK_LIST_LOADED,
  USER_WALLET_LIST_LOADED,
  CHANGE_SECONDARY_AUCTION_WINNER_LOADED,
  SECONDARY_AUCTION_CLOSED_LOADED,
  SECONDARY_LIST_CLOSED_LOADED,
} from "./types"

const initialState = () => ({
  nft: {
    purchasePrice: "",
    coverImage: "",
    thumbnail: "",
    title: "",
  },
  form: {
    salesMethod: "",
    salesStartedAt: new Date(),
    salesEndedAt: null,
    isReleaseNow: "",
    isPublishNow: false,
    price: "",
    buyoutPrice: "",
    bank: "",
    bankName: "",
    branchName: "",
    accountType: "普通",
    accountNumber: "",
    accountHolder: "",
    keepRemember: "1",
    wallet: "",
    walletAddress: "",
    status: "",
    bankError: "",
    walletError: "",
    hasBank: true,
    currencyMethod: "yen",
    ecdsaR: "",
    ecdsaS: "",
    ecdsaV: "",
    terms: false,
    accept: false,
    availablePaymentMethod: [],
    paymentDueDateAt: null,
    limitMaxPriceOnSecondarySelling: "",
  },
  bank: [],
  wallet: [],
  isError: false,
})

// 価格入力フォームの変換
const convertToPriceForm = (price, currencyMethod) =>
  price === null
    ? ""
    : getCurrencyFormattWithoutSymbol(price, currencyMethod).replace(/,/g, "")

const convertToDetail = (data) => {
  // convert to draft object
  let storyObj = {}
  try {
    if (data.story) {
      storyObj = JSON.parse(data.story)
    }
  } catch (err) {
    console.error(err)
  }
  return {
    status: data.status,
    story: storyObj,
    category: data.nftCategoryId,
    brand: data.nftBrandId ?? "",
    royalty: String(data.royalty),
    quantity:
      data.quantity > 1 && data.serialNumber.length > 0
        ? "3"
        : data.quantity > 1
        ? "2"
        : "1",
    benefitsChoice: data.benefits,
    coverImage: data.coverImage,
    thumbnail: data.thumbnail,
    title: data.title,
    userNft: data.userNft,
    // detail: data.detail,
    web: data.web,
    commission: String(data.commission),
    secondaryCommission: String(data.secondaryCommission),
    currencyMethod: data.currencyMethod,
    hasBank: data.hasBank,
    purchasePrice: data.purchasePrice,
    precaution: data.precaution ?? precautions,
    latestTransaction: data.latestTransaction,
    limitMaxPriceOnSecondarySelling: data.limitMaxPriceOnSecondarySelling,
    sellOrder: data.sellOrder,
    contractAddress: data.nftAvailablePaymentMethod[0]?.contractAddress,
    contractAbi: data.nftAvailablePaymentMethod[0]?.contractAbi,
    contractVersion: data.contractVersion,
    nftAvailablePaymentMethods: data.nftAvailablePaymentMethod,
  }
}

const convertToForm = (data) => ({
  salesMethod: data.salesMethod === null ? "" : data.salesMethod,
  salesStartedAt:
    data.salesStartedAt === null ? null : new Date(data.salesStartedAt),
  salesEndedAt: data.salesEndedAt === null ? null : new Date(data.salesEndedAt),
  price: convertToPriceForm(data.price, data.currencyMethod),
  buyoutPrice: convertToPriceForm(data.buyoutPrice, data.currencyMethod),
  currencyMethod: data.currencyMethod,
  bank: String(data.bankId),
  bankName: data.bankName,
  branchName: data.branchName,
  accountType: data.accountType,
  accountNumber: data.accountNumber,
  accountHolder: data.accountHolder,
  keepRemember: data.keepRemember === "2",
  // wallet: "",
  // walletAddress: "",
  status: data.status,
  terms: false,
  accept: false,
  availablePaymentMethod:
    data?.availablePaymentMethod?.map((v) => String(v)) ?? [],
})

const secondaryListingnftManagementReducers = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case SECONDARY_NFT_MANAGEMENT_LOADED:
      return {
        ...state,
        nft: convertToDetail(action.payload),
        form: {
          ...state.form,
          // 自身が購入した価格を初期値として設定
          price: action.payload.latestTransaction.salesPrice,
          buyoutPrice: convertToPriceForm(
            action.payload.limitMaxPriceOnSecondarySelling,
            action.payload.currencyMethod
          ),
        },
      }
    case SECONDARY_NFT_MANAGEMENT_DRAFT_LOADED:
      return {
        ...state,
        form: {
          ...convertToForm(action.payload),
          salesStartedAt: new Date(),
        },
      }
    case USER_BANK_LIST_LOADED:
      return {
        ...state,
        bank: action.payload.map((data) => data),
      }
    case USER_WALLET_LIST_LOADED:
      return {
        ...state,
        wallet: action.payload.map((data) => data),
      }
    case SECONDARY_NFT_MANAGEMENT_INIT:
      return {
        ...state,
        form: initialState().form,
      }
    case SECONDARY_NFT_MANAGEMENT_CREATED:
    case CHANGE_SECONDARY_AUCTION_WINNER_LOADED:
    case SECONDARY_AUCTION_CLOSED_LOADED:
    case SECONDARY_LIST_CLOSED_LOADED:
      return {
        ...state,
      }
    case SECONDARY_NFT_MANAGEMENT_ERROR:
      return {
        ...state,
        isError: true,
      }
    default:
      return state
  }
}

export default secondaryListingnftManagementReducers
