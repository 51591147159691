import React from "react"
import { Box, CircularProgress } from "@material-ui/core"
import "./Loading.scss"

const Loading = () => (
  <Box className="loading__container" zIndex={1100}>
    <CircularProgress className="loading" />
  </Box>
)

export default Loading
