import React from "react"
import PropTypes from "prop-types"
import { useLocation, Link } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  makeStyles,
  IconButton,
  Toolbar,
  AppBar,
  Icon,
  Hidden,
  Drawer,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  Box,
  ListItemText,
} from "@material-ui/core"
import logo from "../../assets/img/SAIN_LOGO.svg"
import Button from "../Button/Button"
import HeaderSideMenu from "../HeaderSideMenu/HeaderSideMenu"
import Search from "../../pages/Search/SearchView"
import HeaderRibbon from "../HeaderRibbon/HeaderRibbon"
import HeaderCategoryNav from "./HeaderCategoryNav"
import {
  LOG_IN_PATH,
  NFT_MARKET_PATH,
  SIGN_UP_PATH,
  COMPANY_PATH,
} from "../../navigation/constatnsPaths"
import { getHeaderSideMenuListByAccountType } from "../../utils/getMenuListByAccountType"
import notiIcon from "../../assets/img/noti_icon.svg"
import notiIconMenu from "../../assets/img/menu_icon_noti.svg"
import searchIcon from "../../assets/img/search_icon.svg"
import searchIconActive from "../../assets/img/search_icon_open.svg"
import MenuSp from "../../assets/img/sp_menu.svg"
import MenuSpOpen from "../../assets/img/sp_menu_open.svg"
import loginIcon from "../../assets/img/menu_icon_login.svg"
import newAccountIcon from "../../assets/img/menu_icon_newaccount.svg"
import arwIcon from "../../assets/img/menu_icon_right.svg"
import aboutIcon from "../../assets/img/menu_icon_about.svg"

const useStyles = makeStyles((theme) => ({
  grow: {
    // flexGrow: 3,
    marginRight: "10px",
    [theme.breakpoints.down("480")]: {
      paddingTop: "1px",
      fontSize: "0",
      alignSelf: "end",
    },
  },
  appbar: {
    backgroundColor: theme.palette.bg_base,
    boxShadow: "none",
    "& .logo-box": {
      flex: "1 1 auto",
      [theme.breakpoints.down("600")]: {
        paddingTop: "0",
      },
      "& .fo-about": {
        flex: "1 1 auto",
        textAlign: "right",
        marginRight: "10px",
        [theme.breakpoints.down("600")]: {
          display: "none",
        },
      },
    },
    "& .header_btns": {
      display: "flex",
      marginRight: "0",
      "& .MuiIconButton-root": {
        width: "32px",
        padding: "0",
        marginRight: "10px",
        [theme.breakpoints.down("xs")]: {
          marginRight: "0",
        },
        "& + a": {
          marginLeft: "4px",
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      height: "70px",
      padding: "0 10px",
      boxShadow: "4px 8px 10px rgba(153,153,153,0.33)",
      justifyContent: "center",
      "& .header_btns": {
        "& > a": {
          display: "none",
        },
      },
    },
    "& a": {
      [theme.breakpoints.down("480")]: {
        height: "26px",
      },
      "&.MuiButton-text": {
        padding: "8px 26px",
        marginLeft: "16px",
        marginRight: "0",
        borderWidth: "2px",
        whiteSpace: "nowrap",
        [theme.breakpoints.down("850")]: {
          padding: "8px 10px",
        },
        [theme.breakpoints.down("810")]: {
          padding: "6px 8px",
        },
      },
      "& .MuiButton-label": {
        fontSize: "12px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down("480")]: {
          fontSize: "12px",
        },
      },
      "& span": {
        fontSize: "16px",
      },
      '&[href="/login"]': {
        color: "#692AF0",
        borderColor: "#692AF0",
        "&:hover": {
          color: "#FFF",
          backgroundColor: "#692AF0",
        },
      },
      '&[href="/signup"]': {
        backgroundColor: "#692AF0",
        borderColor: "#692AF0",
        "&:hover": {
          color: "#FFF",
        },
      },
    },
    "& .MuiIconButton-root": {
      fontWeight: "bold",
      [theme.breakpoints.down("480")]: {
        padding: "0",
        marginRight: "10px",
        fontSize: "12px",
      },
      "& .material-icons": {
        fontSize: "22px",
        [theme.breakpoints.down("xs")]: {
          "& + .icon_txt": {
            fontSize: "0",
          },
        },
        [theme.breakpoints.down("480")]: {
          color: "#000",
        },
      },
      "&.language-icon": {
        "& .material-icons": {
          color: "#692AF0",
          "& + span": {
            color: "#692AF0",
          },
        },
      },
    },

    "& .loggedinIcons": {
      display: "flex",
      flex: "0 0 auto",
      overflow: "hidden",
      "& .MuiIconButton-root": {
        padding: "0",
        fontWeight: "bold",
        [theme.breakpoints.down("480")]: {
          margin: "0",
          fontSize: "14px",
        },
        "&:first-child": {
          marginRight: "10px",
          [theme.breakpoints.down("480")]: {
            margin: "0",
          },
        },
        "& .material-icons": {
          paddingTop: "1px",
          marginRight: "5px",
          fontSize: "26px",
          color: "#692AF0",
          [theme.breakpoints.down("767")]: {
            display: "block",
            marginRight: "0",
            marginLeft: "-5px",
            fontSize: "33px",
          },
        },
      },
      "& .icon_black": {
        display: "inline-block",
        flex: "0 0 auto",
        alignSelf: "center",
        "& span.material-icons": {
          color: "#333",
        },
        "& .MuiIconButton-root": {
          padding: "0",
          marginRight: "10px",
          [theme.breakpoints.down("480")]: {
            margin: "0",
          },
        },
        "& + .MuiIconButton-root": {
          padding: "0",
          marginLeft: "10px",
        },
      },
      "& .MuiIconButton-edgeEnd": {
        marginRight: "1px",
        "& .MuiTouchRipple-root": {
          borderRadius: "2px",
          "& .MuiTouchRipple-ripple": {
            width: "100% !important",
            height: "100% !important",
            top: "0 !important",
            left: "0 !important",
            borderRadius: "2px",
            "& .MuiTouchRipple-child": {
              borderRadius: "20px",
            },
          },
        },
      },
    },
    "& .header-sp__menu": {
      position: "relative",
      display: "none",
      zIndex: "101",
      [theme.breakpoints.down("767")]: {
        display: "inline-block",
        width: "22px",
        fontSize: "0",
        verticalAlign: "middle",
      },
    },
  },
  spmenu: {
    width: "428px",
    height: "100%",
    padding: "0 20px 20px",
    background: "#F7F7F7",
    "@media (max-width: 767px)": {
      width: "320px",
    },
    "& > .MuiBox-root": {
      "&:first-child": {
        display: "flex",
        height: "70px",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiIconButton-root:only-child": {
          marginLeft: "auto",
        },
        "& .L-title": {
          fontSize: "26px",
          fontWeight: "600",
          fontFamily: "futura-pt, sans-serif",
          fontStyle: "italic",
        },
      },
      "&:not(:first-child)": {
        marginBottom: "20px",
        background: "#fff",
        border: "1px solid #DEDEDE !important",
        borderRadius: "16px",
        overflow: "hidden",
        boxShadow: "none",
        "&:empty": {
          display: "none",
        },
        "& a": {
          display: "block",
        },
        "& .MuiListItem-button": {
          position: "relative",
          display: "flex",
          height: "70px",
          alignItems: "center",
          "& .MuiTypography-root": {
            fontSize: "16px",
            color: "#333",
            fontWeight: "bold",
          },
          "& .MuiListItemIcon-root": {
            minWidth: "39px",
            color: "#000",
          },
        },
      },
    },
    "& .MuiList-padding": {
      paddingTop: "0",
      paddingBottom: "0",
      borderBottom: "1px solid #DEDEDE",
      "&:last-child": {
        borderBottom: "none",
      },
    },
    "& .header-sp__menu": {
      position: "absolute",
      top: "5px",
      right: "14px",
      color: "#692AF0",
    },
  },
  logo: {
    width: 331,
    marginRight: "10px",
    [theme.breakpoints.down("625")]: {
      width: "240px",
      marginRight: "1px",
    },
    [theme.breakpoints.down("480")]: {
      marginRight: "5px",
    },
  },
  category: {
    minWidth: 331,
    padding: "2px 1em",
    position: "absolute",
    bottom: "3px",
    background:
      "linear-gradient(92.68deg, #6D28FF 0.13%, #8F36FF 27.52%, #4901E1 100%)",
    borderRadius: "0.75em",
    textAlign: "center",
    [theme.breakpoints.down("934")]: {
      minWidth: "260px",
    },
    [theme.breakpoints.down("785")]: {
      minWidth: "200px",
      padding: "0px 1em",
      top: "-15px",
      height: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      fontSize: "12px",
    },
    [theme.breakpoints.down("767")]: {
      top: "-10px",
    },
    [theme.breakpoints.down("600")]: {
      minWidth: "168px",
      padding: "2px 1em",
      top: "-28px",
    },
  },
  inputRoot: {
    color: theme.palette.bg_dark,
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(2.5)}px)`,
    width: "150px",
    fontSize: "15px",
  },
  icons: {
    fontSize: "24px",
  },
  userName: {
    marginLeft: "4px",
    fontSize: "14px",
    color: "#692AF0",
  },
  purple: {
    color: "#692AF0",
  },
}))

const Header = ({
  handleToggleNotificationDrawer,
  handleToggleNewsDrawer,
  handleToggleSearchDrawer,
  isOpenSearchMenu,
  isOpenNotificationDrawer,
  // handleToggleMessagesDrawer,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const theme = useTheme()
  const isSp = useMediaQuery(theme.breakpoints.down("xs"))
  const [isOpenMenuDrawer, setIsOpenMenuDrawer] = React.useState(false)
  const { auth, settings, masterData } = useSelector((state) => state)
  const [isOpenSearchScreen, setIsOpenSearchScreen] = React.useState(false)
  const [isOpenSpMenu, setIsOpenSpMenu] = React.useState(false)
  const [logoUrl, setLogoUrl] = React.useState("")
  const [selectedCategory, setSelectedCategory] = React.useState("")
  const isMaintenanceMode =
    settings.maintenanceMode.status === 253 &&
    (auth.role !== "super" || !auth.isAuthenticated)

  React.useEffect(() => {
    // サイトカテゴリー取得
    const { category } = masterData

    // 選択中サイトカテゴリの設定
    if (category.urlParameter) {
      setSelectedCategory(category.urlParameter)
    } else {
      setSelectedCategory("")
    }
    // ヘッダーロゴの設定（リンクURL）
    setLogoUrl(NFT_MARKET_PATH)
  }, [location.search, masterData])

  const handleToggleSearchScreen = React.useCallback(() => {
    setIsOpenSearchScreen((prev) => !prev)
  }, [setIsOpenSearchScreen])

  // ドロワーヘッダーサイドメニュー
  const handleToggleMenuDrawer = React.useCallback(
    (isClose) => {
      setIsOpenMenuDrawer((prev) => !prev)
      if (isClose !== false) {
        handleToggleNotificationDrawer(false)
        handleToggleNewsDrawer(false)
      }
    },
    [handleToggleNewsDrawer, handleToggleNotificationDrawer]
  )

  const handleToggleSpMenuDrawer = React.useCallback(() => {
    setIsOpenSpMenu((prev) => !prev)
    handleToggleNotificationDrawer(false)
    handleToggleNewsDrawer(false)
    handleToggleSearchDrawer(false)
  }, [
    handleToggleNewsDrawer,
    handleToggleNotificationDrawer,
    handleToggleSearchDrawer,
  ])

  // お知らせサイドメニュー
  const handleClickNotification = () => {
    setIsOpenSpMenu((prev) => !prev)
    handleToggleNotificationDrawer(true)
  }

  // ヘッダーメニューリンク

  // ログイン状態のアイコンリスト
  const loggedInIconList = (
    <div className="loggedinIcons">
      {isSp ? (
        <div className="icon_black">
          <IconButton
            onClick={() => handleToggleSearchDrawer(!isOpenSearchMenu)}
          >
            {isOpenSearchMenu ? (
              <img src={searchIconActive} alt="検索" />
            ) : (
              <img src={searchIcon} alt="検索" />
            )}
          </IconButton>
        </div>
      ) : (
        <>
          <IconButton
            onClick={() =>
              handleToggleNotificationDrawer(!isOpenNotificationDrawer)
            }
          >
            <img src={notiIcon} alt="お知らせ" />
          </IconButton>
          <div className="icon_black">
            <IconButton onClick={() => handleToggleSearchScreen()}>
              <img src={searchIcon} alt="検索" />
            </IconButton>
          </div>
        </>
      )}
      {/* <IconButton onClick={() => handleToggleNewsDrawer(true)}>
        <Icon className={classes.icons}>offline_bolt</Icon>
      </IconButton> */}
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        onClick={() => handleToggleMenuDrawer()}
      >
        {isSp ? (
          <>
            {isOpenSpMenu ? (
              <Icon className={classes.icons}>
                <img src={MenuSpOpen} alt="" />
              </Icon>
            ) : (
              <Icon className={classes.icons}>
                <img src={MenuSp} alt="" />
              </Icon>
            )}
          </>
        ) : (
          <Icon className={classes.icons}>account_circle</Icon>
        )}
        <Hidden smDown>
          <span className={classes.userName}>{auth.user.nickname}</span>
        </Hidden>
        {/* <Icon className={classes.icons}>expand_more</Icon> */}
      </IconButton>
    </div>
  )

  // ログインしていない状態のアイコンリスト
  const noLoggedInIconList = (
    <div className="header_btns">
      {isSp ? (
        <IconButton onClick={() => handleToggleSearchDrawer(!isOpenSearchMenu)}>
          {isOpenSearchMenu ? (
            <img src={searchIconActive} alt="検索" />
          ) : (
            <img src={searchIcon} alt="検索" />
          )}
        </IconButton>
      ) : (
        <>
          <IconButton
            onClick={() =>
              handleToggleNotificationDrawer(!isOpenNotificationDrawer)
            }
          >
            <img src={notiIcon} alt="お知らせ" />
          </IconButton>
          <IconButton onClick={() => handleToggleSearchScreen()}>
            <img src={searchIcon} alt="検索" />
          </IconButton>
        </>
      )}
      {/* <IconButton onClick={() => handleToggleNewsDrawer(true)}>
        <Icon className={clsx(classes.icons, classes.purple)}>
          offline_bolt
        </Icon>
      </IconButton> */}
      <Button
        text="ログイン"
        isLight
        component={Link}
        to={{
          pathname: LOG_IN_PATH,
          state: { background: location },
        }}
      />
      <Button
        text="新規登録"
        component={Link}
        to={{
          pathname: SIGN_UP_PATH,
          state: { background: location },
        }}
      />
      {/* <IconButton className="language-icon">
        <Icon className={classes.icons}>language</Icon>
        <span>JPN</span>
      </IconButton> */}
      <IconButton
        className="header-sp__menu"
        onClick={() => handleToggleSpMenuDrawer()}
      >
        <Icon className={classes.icons}>menuicon</Icon>
      </IconButton>
      <Drawer
        anchor="right"
        open={isOpenSpMenu}
        onClose={handleToggleSpMenuDrawer}
      >
        <Box className={classes.spmenu}>
          <Box style={{ textAlign: "end" }}>
            <p className="L-title">Menu</p>
            <IconButton onClick={() => handleToggleSpMenuDrawer()}>
              <Icon>close</Icon>
            </IconButton>
          </Box>
          <Box>
            <List component="nav">
              <Link
                to={{
                  pathname: LOG_IN_PATH,
                  state: { background: location },
                }}
                onClick={() => handleToggleSpMenuDrawer()}
              >
                <ListItem button>
                  <ListItemIcon>
                    <Icon>
                      <img src={loginIcon} alt="" />
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary="ログイン" />
                  <Box className="arw">
                    <img src={arwIcon} alt="" />
                  </Box>
                </ListItem>
              </Link>
            </List>
          </Box>
          <Box>
            <List component="nav">
              <Link
                to={{
                  pathname: SIGN_UP_PATH,
                  state: { background: location, fromSignInButton: true },
                }}
                onClick={() => handleToggleSpMenuDrawer()}
              >
                <ListItem button>
                  <ListItemIcon>
                    <Icon>
                      <img src={newAccountIcon} alt="" />
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary="新規登録" />
                  <Box className="arw">
                    <img src={arwIcon} alt="" />
                  </Box>
                </ListItem>
              </Link>
            </List>
          </Box>
          <Box>
            <List component="nav">
              <ListItem button onClick={handleClickNotification}>
                <ListItemIcon>
                  <Icon>
                    <img src={notiIconMenu} alt="" />
                  </Icon>
                </ListItemIcon>
                <ListItemText>お知らせ</ListItemText>
                <Box className="arw">
                  <img src={arwIcon} alt="" />
                </Box>
              </ListItem>
            </List>
          </Box>
          <Box>
            <List component="nav">
              <ListItem
                button
                onClick={() => handleToggleSpMenuDrawer()}
                component={Link}
                to={COMPANY_PATH}
              >
                <ListItemIcon>
                  <Icon>
                    <img src={aboutIcon} alt="" />
                  </Icon>
                </ListItemIcon>
                <ListItemText>Fanpla Owner とは？</ListItemText>
                <Box className="arw">
                  <img src={arwIcon} alt="" />
                </Box>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
    </div>
  )

  const maintenanceNoLoggedInIconList = (
    <div className="header_btns">
      <Button
        text="ログイン"
        isLight
        component={Link}
        to={{
          pathname: LOG_IN_PATH,
          state: { background: location },
        }}
      />
      <IconButton
        className="header-sp__menu"
        onClick={() => handleToggleSpMenuDrawer()}
      >
        <Icon className={classes.icons}>menuicon</Icon>
      </IconButton>
      <Drawer
        anchor="right"
        open={isOpenSpMenu}
        onClose={handleToggleSpMenuDrawer}
      >
        <Box className={classes.spmenu}>
          <Box style={{ textAlign: "end" }}>
            <p className="L-title">Menu</p>
            <IconButton onClick={() => handleToggleSpMenuDrawer()}>
              <Icon>close</Icon>
            </IconButton>
          </Box>
          <Box>
            <List component="nav">
              <Link
                to={{
                  pathname: LOG_IN_PATH,
                  state: { background: location },
                }}
                onClick={() => handleToggleSpMenuDrawer()}
              >
                <ListItem button>
                  <ListItemIcon>
                    <Icon>
                      <img src={loginIcon} alt="" />
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary="ログイン" />
                  <Box className="arw">
                    <img src={arwIcon} alt="" />
                  </Box>
                </ListItem>
              </Link>
            </List>
          </Box>
          <Box>
            <List component="nav">
              <ListItem
                button
                onClick={() => handleToggleSpMenuDrawer()}
                component={Link}
                to={COMPANY_PATH}
              >
                <ListItemIcon>
                  <Icon>
                    <img src={aboutIcon} alt="" />
                  </Icon>
                </ListItemIcon>
                <ListItemText>Fanpla Owner とは？</ListItemText>
                <Box className="arw">
                  <img src={arwIcon} alt="" />
                </Box>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
    </div>
  )

  if (isMaintenanceMode && auth.role !== "super") {
    return (
      <>
        <AppBar position="static" className={classes.appbar}>
          <Toolbar>
            <div className="logo-box">
              <a href={logoUrl} className={classes.grow}>
                <img src={logo} alt="Fanpla Owner" className={classes.logo} />
              </a>
              <HeaderCategoryNav
                selectedCategory={selectedCategory}
                categoryList={masterData.categoryList}
              />
              <Link to={COMPANY_PATH} className="fo-about">
                Fanpla Owner とは
              </Link>
            </div>
            {maintenanceNoLoggedInIconList}
          </Toolbar>
          <Drawer
            anchor="right"
            open={isOpenMenuDrawer}
            onClose={handleToggleMenuDrawer}
          >
            <HeaderSideMenu
              onClose={(e) => handleToggleMenuDrawer(e)}
              routeList={getHeaderSideMenuListByAccountType(auth.role)}
              handleToggleNotificationDrawer={handleToggleNotificationDrawer}
              handleToggleNewsDrawer={handleToggleNewsDrawer}
              // handleToggleMessagesDrawer={handleToggleMessagesDrawer}
              type={auth.role}
            />
          </Drawer>
        </AppBar>
      </>
    )
  }

  return (
    <>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <div className="logo-box">
            <a href={logoUrl} className={classes.grow}>
              <img src={logo} alt="Fanpla Owner" className={classes.logo} />
            </a>
            <HeaderCategoryNav
              selectedCategory={selectedCategory}
              categoryList={masterData.categoryList}
            />
            <Link to={COMPANY_PATH} className="fo-about">
              Fanpla Owner とは
            </Link>
          </div>
          {auth.isAuthenticated ? loggedInIconList : noLoggedInIconList}
        </Toolbar>
        <Drawer
          anchor="right"
          open={isOpenMenuDrawer}
          onClose={handleToggleMenuDrawer}
        >
          <HeaderSideMenu
            onClose={(e) => handleToggleMenuDrawer(e)}
            routeList={getHeaderSideMenuListByAccountType(auth.role)}
            handleToggleNotificationDrawer={handleToggleNotificationDrawer}
            handleToggleNewsDrawer={handleToggleNewsDrawer}
            // handleToggleMessagesDrawer={handleToggleMessagesDrawer}
            type={auth.role}
          />
        </Drawer>
        {!isSp && (
          <Drawer
            anchor="right"
            open={isOpenSearchScreen}
            onClose={handleToggleSearchScreen}
          >
            <Search
              isOpen={isOpenSearchScreen}
              onClose={handleToggleSearchScreen}
            />
          </Drawer>
        )}
      </AppBar>
      <HeaderRibbon
        type={auth.role}
        handleToggleNotificationDrawer={handleToggleNotificationDrawer}
      />
    </>
  )
}

Header.propTypes = {
  handleToggleNotificationDrawer: PropTypes.func,
  handleToggleNewsDrawer: PropTypes.func,
  handleToggleSearchDrawer: PropTypes.func,
  isOpenSearchMenu: PropTypes.bool,
  isOpenNotificationDrawer: PropTypes.bool,
  // handleToggleMessagesDrawer: PropTypes.func,
}

export default Header
