import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Field, useField, useFormikContext } from "formik"
import { Grid, Box } from "@material-ui/core"
import { useSelector } from "react-redux"
import Accordion from "../../Accordion/Accordion"
import CreditRadioButton from "../../Forms/CreditCardForm/CreditRadioButton"
import CreditCardForm from "../../Forms/CreditCardForm/CreditCardForm"
import FormControl from "../../Forms/FormControl"
import { useWeb3 } from "../../../hooks/useWeb3"
import { PAYMENT_METHOD_MASTER as constantPaymentMethodMaster } from "../../../constants/paymentMethodMaster"
import { isPayByEther, isPayByMatic } from "../../../utils/helper"
import Tds2CredentialForm from "../../Forms/CreditCardForm/Tds2CredentialForm"
import { tds2CredentialPhoneTypeList } from "../../../utils/purchaseSectionList"

// もともとこのファイルにあった内容をよりグローバルな定数置き場に格上げ
// ここからimportしているコンポーネントが非常に多いため、一旦エイリアス的に残しておく
export const PAYMENT_METHOD_MASTER = constantPaymentMethodMaster

export const paymentAttributes = {
  [PAYMENT_METHOD_MASTER.JPY_BY_CARD]: {
    typeName: PAYMENT_METHOD_MASTER.JPY_BY_CARD,
    explain: "クレジットカード",
    attention:
      "※独自のプライベートチェーンにて発行されたNFTを購入できます。<br />※二次流通以降は出品時に決済方式としてクレジットカードを選択可能です。",
  },
  [PAYMENT_METHOD_MASTER.NOWPAYMENT]: {
    typeName: PAYMENT_METHOD_MASTER.NOWPAYMENT,
    explain: "Ethereum ※外部サイトに遷移します",
    attention:
      "※独自のプライベートチェーンにて発行されたNFTを購入できます。<br />※二次流通以降は出品時に決済方式としてクレジットカード、Ethereum（イーサリアム）を選択可能です。",
  },
  [PAYMENT_METHOD_MASTER.POLYGON]: {
    typeName: PAYMENT_METHOD_MASTER.POLYGON,
    explain: "Polygon（MATIC）",
    attention:
      "※Polygon上で発行されたNFTを購入できます。<br>" +
      "<span style='color: #ED1C24'>※二次流通以降は出品時に決済方式としてPolygon（MATIC）のみを選択可能です。日本円での出品はできませんのでご注意ください。</span><br>" +
      "※Metamaskでの購入方法についてはFAQ「Polygon(MATIC)決済について」をご確認ください。<br>" +
      "※購入ボタンを押してから、15分以内にMetamaskでの決済を完了させてください。<br>",
  },
  [PAYMENT_METHOD_MASTER.ETHEREUM]: {
    typeName: PAYMENT_METHOD_MASTER.ETHEREUM,
    explain: "Ethereum（ETH）",
    attention:
      "※Ethereum上で発行されたNFTを購入できます。<br>" +
      "<span style='color: #ED1C24'>※二次流通以降は出品時に決済方式としてEthereumのみを選択可能です。日本円での出品はできませんのでご注意ください。</span><br>" +
      "※Metamaskでの購入方法についてはFAQ「Ethereum決済について」をご確認ください。<br>" +
      "※購入ボタンを押してから、15分以内にMetamaskでの決済を完了させてください。<br>",
  },
}

// オークション強制決済用
const paymentAttributesForAutoCreditSettlement = {
  ...paymentAttributes,
  1: {
    ...paymentAttributes[1],
    attention:
      "※入札時に登録したクレジットカードにて、入札金額分のご利用枠の確認をさせていただきます。<br>" +
      "※追加で入札した場合は、一番高い入札金額のみご利用枠の確認をさせていただきます。<br>" +
      "※落札後、独自のプライベートチェーンにて発行されたNFTを購入できます。<br>" +
      "※二次流通以降は出品時に決済方式としてクレジットカードを選択可能です。<br>",
  },
  3: {
    ...paymentAttributes[3],
    attention:
      "※入札時に入札金額に相当するPolygon(MATIC)を連携したウォレット内に保有しているか確認させていただきます。不足している場合は入札ができませんのでご了承ください。<br>" +
      "<span style='color: #ED1C24'>※二次流通以降は出品時に決済方式としてPolygon（MATIC）のみを選択可能です。日本円での出品はできませんのでご注意ください。</span><br>" +
      "※落札後のMetamaskでの購入方法についてはFAQ「Polygon(MATIC)決済について」をご確認ください。<br>",
  },
}

/**
 * 支払い方法
 */
const PaymentMethod = ({
  registeredCard,
  disabled,
  variant,
  price,
  nftAvailablePaymentMethods,
  userOfferedPaymentMethodMasterId,
  salesMethod,
  autoCreditSettlement,
}) => {
  const { setFieldValue } = useFormikContext()
  const [meta] = useField("paymentMethod")
  const { value } = meta
  const [paymentOptions, setPaymentOptions] = useState([])
  const { prepareWallet } = useWeb3()
  const attributes = useMemo(
    () =>
      autoCreditSettlement && salesMethod === "auction"
        ? paymentAttributesForAutoCreditSettlement
        : paymentAttributes,
    [autoCreditSettlement, salesMethod]
  )
  const {
    auth: { user },
  } = useSelector((state) => state)
  const [tds2CredentialPhoneType] = useState(tds2CredentialPhoneTypeList)
  const masterData = useSelector((state) => state.masterData)

  React.useEffect(() => {
    let mounted = true
    let options = []
    // nftAvailablePaymentMethodsから支払い方法の選択肢を生成
    if (Array.isArray(nftAvailablePaymentMethods)) {
      options = nftAvailablePaymentMethods.map(
        (method) => attributes[method.paymentMethodMasterId]
      )
    }
    if (options.length === 0) {
      // 古いデータなどで絵指定がない時はクレカのみにする
      options = [paymentAttributes[PAYMENT_METHOD_MASTER.JPY_BY_CARD]]
    }
    if (userOfferedPaymentMethodMasterId) {
      // 前回入札時に選択済なら支払い方式を絞り込む
      options = [attributes[userOfferedPaymentMethodMasterId]]
    }
    if (Number(price) > 9999999) {
      // 1000万以上ならクレカ決済を支払い方法から外す
      options = options.filter(
        (option) => option.typeName !== PAYMENT_METHOD_MASTER.JPY_BY_CARD
      )
    }
    if (mounted) {
      if (options?.length === 1) {
        // デフォルトで選択する（選択肢が１つの場合）
        setFieldValue("paymentMethod", options[0].typeName)
      }
      setPaymentOptions(options)
    }

    return () => {
      mounted = false
    }
  }, [
    nftAvailablePaymentMethods,
    price,
    userOfferedPaymentMethodMasterId,
    setFieldValue,
    attributes,
  ])

  React.useEffect(() => {
    let mounted = true
    if (mounted) {
      if (isPayByMatic(value)) {
        prepareWallet("polygon").finally(/* noop */)
      } else if (isPayByEther(value)) {
        prepareWallet(
          process.env.REACT_APP_ETHEREUM_NETWORK
        ).finally(/* noop */)
      }
    }

    return () => {
      mounted = false
    }
  }, [prepareWallet, value])

  return (
    <Accordion title="支払い方法">
      {paymentOptions.length === 0 ? (
        <>
          <Box>クレジットカード決済の限度額は1,000万円未満になります。</Box>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item xs>
              支払い方法を選択
            </Grid>
            <Grid item xs={12} sm={8}>
              {salesMethod === "auction" && autoCreditSettlement && (
                <Box fontSize="14px" color="#ED1C24" my={1}>
                  ※複数回入札する場合、1回目の入札時の決済方式から変更することはできません
                </Box>
              )}
              <FormControl
                control="radio"
                name="paymentMethod"
                options={paymentOptions}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Box className="payment-container">
            {value === PAYMENT_METHOD_MASTER.JPY_BY_CARD ? (
              <Box mt={3}>
                <Box fontWeight={700}>クレジットカード</Box>
                <CreditRadioButton
                  name="payment"
                  control="radio"
                  options={registeredCard}
                  disabled={disabled}
                />
                <CreditCardForm disabled={disabled} variant={variant} />
                {!user.emailValidated && (
                  <>
                    {/* 3DS2.0認証情報 */}
                    <Box fontWeight={700}>本人認証情報入力</Box>
                    電話番号を入力してください。
                    <Tds2CredentialForm
                      disabled={disabled}
                      tds2CredentialPhoneType={tds2CredentialPhoneType}
                      countryCodeList={masterData.countryCodeList}
                    />
                  </>
                )}
                <Field type="hidden" name="useCredential" />
                <Box maxWidth="400px" fontWeight="light" fontSize="10px">
                  本サイトのクレジットカード決済では、第三者の不正利用（なりすまし）を阻止するために
                  「３Ｄセキュア」による本人認証をおこなっています。
                  <br />
                  事前にクレジットカード発行会社でパスワード登録を行う必要があります。
                  <br />
                  ご利用のクレジットカード会社に、設定状況をご確認ください。
                  <br />
                  <br />
                  クレジットカード決済の限度額は1,000万円未満になります。
                  <br />
                  <br />
                  <Box fontSize="14px" color="danger">
                    ※「購入する」を押すと15分以内に支払い手続きを完了する必要がございますので、
                    あらかじめクレジットカードをお手元にご用意ください。
                  </Box>
                </Box>
              </Box>
            ) : value === PAYMENT_METHOD_MASTER.NOWPAYMENT ? (
              <Box mt={3} fontWeight="light" fontSize="14px">
                <>
                  {value === PAYMENT_METHOD_MASTER.NOWPAYMENT && (
                    <>
                      <Box color="danger">
                        ※Ethereumによるお支払いは、購入価格が暗号資産決済代行会社（NOWPayments）の設定する最小支払可能額以上の場合にのみご利用いただけます。
                        <br />
                        例えば、購入価格が5,000円、最小支払可能額が日本円換算で5,000円を超える場合はご利用頂けません。
                      </Box>
                      NOWPaymentsの最小支払可能額は、
                      <a
                        href="https://nowpayments.io/status-page"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "underline",
                          color: "rgb(218, 12, 61)",
                        }}
                      >
                        こちら
                      </a>
                      からご確認いただけます。(金額は常に変動いたします)
                      <br />
                      ※購入価格が最小支払可能額を下回っている状態で決済画面に進むと、遷移先のページでエラーが表示されます。
                      <br />
                      ※上記の状態でエラーが表示された場合、Ethereumは送金されませんのでご安心ください。
                      <br />
                      ※事前に
                      <a
                        href="https://owner.fanpla.jp/faq/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "underline",
                          color: "rgb(218, 12, 61)",
                        }}
                      >
                        FAQ
                      </a>
                      「暗号資産払いの決済方法について」をご確認ください。
                      <br />
                      <br />
                    </>
                  )}
                  <Box color="danger">
                    ※「購入する」を押すと15分以内に支払い手続きを完了する必要がございます。
                  </Box>
                  ※お支払いいただくEthereumは支払い確定時のレートにより実際のお支払い総額は変動いたします。
                  <br />
                  ※誤操作防止のため、パソコンからの暗号資産の送金を推奨いたします。
                  <br />
                  ※送金先ウォレットアドレス、送金金額の誤り等による暗号資産の返金対応は行なっておりません。
                  <br />
                </>
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box mt={5}>
            <Box fontSize="h6.fontSize" fontWeight={700}>
              注意事項
            </Box>
            <Box fontSize="14px" mt={2}>
              購入内容をご確認ください。
              <Box display="inline" fontWeight={700}>
                契約成立後の取消は原則できず、購入後の返品やキャンセルは致しかねます。
              </Box>
              {salesMethod === "auction" ? (
                <>
                  <br />
                  お支払い手続き完了と同時に本アイテムは受け渡され、「メニュー」＞「アイテム一覧」よりご確認頂けます。
                </>
              ) : (
                <>
                  <br />
                  定価販売の場合、ご購入と同時にお支払いが発生いたします。お支払い手続き完了と同時に本アイテムは受け渡され、「メニュー」＞「アイテム一覧」よりご確認頂けます。
                  <br />
                  <Box mt={1}>
                    ※複数のシリアルナンバーが存在するアイテムの一次販売時は、ランダムでシリアルナンバーが割り当てられます。シリアルナンバーを指定しての購入はできません。
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Accordion>
  )
}

PaymentMethod.propTypes = {
  registeredCard: PropTypes.arrayOf(PropTypes.object),
  nftAvailablePaymentMethods: PropTypes.arrayOf(PropTypes.object),
  userOfferedPaymentMethodMasterId: PropTypes.number,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  price: PropTypes.number,
  salesMethod: PropTypes.string,
  autoCreditSettlement: PropTypes.bool,
}

export default PaymentMethod
