import {
  USER_REQUEST,
  USER_INIT,
  USER_LOADED,
  USER_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SIGNUP_SUCCESS,
  LOGIN_FAIL,
} from "./types"
import { api, authApi } from "../api"
import { SEARCH_HISTORY_LIST_CLEAR } from "../pages/search_nft/types"

const getCreateParams = (values) => ({
  ...values,
})

export const initUser = () => (dispatch) =>
  dispatch({
    type: USER_INIT,
  })

// Check token & load user
export const loadUser = () => (dispatch) => {
  dispatch({ type: USER_REQUEST })
  return authApi
    .get("/settings/account")
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      })
    })
    .catch((_err) => {
      dispatch({
        type: USER_ERROR,
      })
    })
}

// Signup User
export const signup = (values) => (dispatch) =>
  api.post(`/signup`, getCreateParams(values)).then(() =>
    dispatch({
      type: SIGNUP_SUCCESS,
      payload: values,
    })
  )

// Login User with pass and email
export const login = (values) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST })
  return api
    .post(`/login`, getCreateParams(values))
    .then((_res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: _res.data.data,
          token: _res.data.token,
        },
      })
    })
    .catch((_err) => {
      dispatch({
        type: LOGIN_FAIL,
      })
    })
}

// tidによるログイン TixPlusからのシームレスログインに使用
export const loginWithTid = (values) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST })
  return api
    .post(`/login_with_tid`, getCreateParams(values))
    .then((_res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: _res.data.data,
          token: _res.data.token,
        },
      })
    })
    .catch((_err) => {
      dispatch({
        type: LOGIN_FAIL,
      })
    })
}

// Logout User
export const logout = () => async (dispatch) => {
  await authApi.get(`/logout`, {
    withCredentials: true,
  })
  dispatch({
    type: LOGOUT_SUCCESS,
  })
  dispatch({
    type: SEARCH_HISTORY_LIST_CLEAR,
  })
}
