export const NFT_MANAGEMENT_LIST_REQUEST = "NFT_MANAGEMENT_LIST_REQUEST"
export const NFT_MANAGEMENT_LIST_LOADED = "NFT_MANAGEMENT_LIST_LOADED"
export const NFT_MANAGEMENT_LIST_ERROR = "NFT_MANAGEMENT_LIST_ERROR"

export const NFT_MANAGEMENT_INIT = "NFT_MANAGEMENT_INIT"
export const NFT_MANAGEMENT_REQUEST = "NFT_MANAGEMENT_REQUEST"
export const NFT_MANAGEMENT_LOADED = "NFT_MANAGEMENT_LOADED"
export const NFT_MANAGEMENT_CREATED = "NFT_MANAGEMENT_CREATED"
export const NFT_MANAGEMENT_DUPLICATED = "NFT_MANAGEMENT_DUPLICATED"
export const NFT_MANAGEMENT_UPDATED = "NFT_MANAGEMENT_UPDATED"
export const NFT_MANAGEMENT_DELETED = "NFT_MANAGEMENT_DELETED"
export const NFT_MANAGEMENT_ERROR = "NFT_MANAGEMENT_ERROR"

export const NFT_MANAGEMENT_LOTTERY_LIST_REQUEST =
  "NFT_MANAGEMENT_LOTTERY_LIST_REQUEST"
export const NFT_MANAGEMENT_LOTTERY_LIST_LOADED =
  "NFT_MANAGEMENT_LOTTERY_LIST_LOADED"
export const NFT_MANAGEMENT_LOTTERY_LIST_ERROR =
  "NFT_MANAGEMENT_LOTTERY_LIST_ERROR"

export const NFT_MANAGEMENT_AUCTION_LIST_REQUEST =
  "NFT_MANAGEMENT_AUCTION_LIST_REQUEST"
export const NFT_MANAGEMENT_AUCTION_LIST_LOADED =
  "NFT_MANAGEMENT_AUCTION_LIST_LOADED"
export const NFT_MANAGEMENT_AUCTION_LIST_ERROR =
  "NFT_MANAGEMENT_AUCTION_LIST_ERROR"

export const APPROVE_LOADED = "APPROVE_LOADED"
export const APPROVE_UPDATED = "APPROVE_UPDATED"

export const AUCTION_LIST_REQUEST = "AUCTION_LIST_REQUEST"
export const AUCTION_LIST_LOADED = "AUCTION_LIST_LOADED"

export const CHANGE_AUCTION_WINNER_LOADED = "CHANGE_AUCTION_WINNER_LOADED"

export const NFT_AUCTION_CLOSED_LOADED = "NFT_AUCTION_CLOSED_LOADED"

export const TRANSFER_NFT_REQUEST = "TRANSFER_NFT_REQUEST"

export const NFT_PRIVATE_MEDIA_CONTENTS_DESCRIPTION_LOADED =
  "NFT_PRIVATE_MEDIA_CONTENTS_DESCRIPTION_LOADED"
export const NFT_PRIVATE_MEDIA_CONTENTS_DESCRIPTION_UPDATED =
  "NFT_PRIVATE_MEDIA_CONTENTS_DESCRIPTION_UPDATED"
export const NFT_PRIVATEMEDIACONTENT_INIT = "NFT_PRIVATEMEDIACONTENT_INIT"
export const NFT_PRIVATEMEDIACONTENT_REQUES = "NFT_PRIVATEMEDIACONTENT_REQUES"
export const NFT_PRIVATEMEDIACONTENT_LOADED = "NFT_PRIVATEMEDIACONTENT_LOADED"
export const NFT_PRIVATEMEDIACONTENT_CREATED = "NFT_PRIVATEMEDIACONTENT_CREATED"
export const NFT_PRIVATEMEDIACONTENT_UPDATED = "NFT_PRIVATEMEDIACONTENT_UPDATED"
export const NFT_PRIVATEMEDIACONTENT_DELETED = "NFT_PRIVATEMEDIACONTENT_DELETED"
export const NFT_PRIVATEMEDIACONTENT_ERROR = "NFT_PRIVATEMEDIACONTENT_ERROR"
