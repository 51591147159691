import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Box, makeStyles, Avatar } from "@material-ui/core"
import { useOnScreen } from "../../hooks/useOnScreen"

const useStyles = makeStyles(() => ({
  container: {
    padding: "32px 32px 40px",
    background: "#fff",
    border: "1px solid #DEDEDE",
    borderRadius: "16px",
    "& img": {
      width: "100%",
    },
    "& .innerbox": {
      "& > p": {
        fontSize: "14px",
        textAlign: "left !important",
        wordBreak: "break-all",
        "&:first-child": {
          marginBottom: "16px",
          "& span": {
            fontSize: "16px",
            lineHeight: "1.8",
          },
        },
        "&:empty": {
          display: "none",
        },
      },
      "& img": {
        display: "block",
        maxWidth: "100%",
        margin: "auto 16px",
        fontSize: "0",
      },
    },
  },
  date: {
    display: "block",
    marginBottom: "8px",
    fontSize: "12px",
    lineHeight: "1",
    color: "#000",
    fontWeight: "bold",
    textAlign: "left",
  },
}))

// メッセージ、お知らせ、速報用の受信日時、外枠
const ThreadLayout = ({ onVisible, date, senderImage, htmlText }) => {
  const classes = useStyles()
  const ref = useRef(null)
  const [lastIsVisible, setLastIsVisible] = useState(false)
  const isVisible = useOnScreen(ref, !!onVisible)

  useEffect(() => {
    if (!onVisible) return

    if (isVisible && !lastIsVisible) {
      onVisible()
    }
    setLastIsVisible(isVisible)
  }, [isVisible, lastIsVisible, onVisible])

  return (
    <Box width="100%" mb={3} ref={ref} className={classes.container}>
      <Box
        fontWeight="fontWeightBold"
        textAlign="end"
        fontSize={15}
        className={classes.date}
      >
        {date}
      </Box>
      <Box display="flex">
        {senderImage && (
          <Box alignSelf="flex-end">
            <Avatar src={senderImage} />
          </Box>
        )}
        <Box
          mx="auto"
          className="innerbox"
          dangerouslySetInnerHTML={htmlText}
        />
      </Box>
    </Box>
  )
}

ThreadLayout.propTypes = {
  forwardRef: PropTypes.shape({}),
  date: PropTypes.string,
  senderImage: PropTypes.string,
  htmlText: PropTypes.shape({}),
  onVisible: PropTypes.func,
}

export default ThreadLayout
