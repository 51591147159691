import React from "react"
import PropTypes from "prop-types"
import {
  TextField,
  MenuItem,
  Select,
  ListItemText,
  Checkbox,
  Chip,
} from "@material-ui/core"
import { ErrorMessage, useField, useFormikContext } from "formik"
import TextError from "../TextError/TextError"
import "./Select.scss"

const SelectWrapper = ({ name, options, ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)
  const [benefitsChoices, setbenefitsChoices] = React.useState(field.value)
  const handleChange = (event) => {
    const { value } = event.target
    if (name === "benefitsChoice") {
      setFieldValue(name, [...value])
      setbenefitsChoices(typeof value === "string" ? value.split(",") : value)
    } else {
      setFieldValue(name, value)
    }
  }

  const configSelect = {
    select: true,
    ...field,
    ...props,
    onChange: handleChange,
  }
  if (meta && meta.touched && meta.error) {
    configSelect.error = true
    configSelect.helperText = meta.error
  }

  React.useEffect(() => {
    setbenefitsChoices(meta.value)
  }, [meta.value, options])

  return (
    <>
      {name !== "benefitsChoice" && (
        <TextField {...configSelect} className="MuiFormControl-fullWidth">
          {Object.keys(options).map((item, _) => (
            <MenuItem key={item} value={item}>
              {options[item]}
            </MenuItem>
          ))}
        </TextField>
      )}
      {name === "benefitsChoice" && (
        <>
          <Select
            className="MuiFormControl-fullWidth"
            multiple
            value={benefitsChoices}
            onChange={handleChange}
            {...configSelect}
            renderValue={(selected) => (
              <div>
                {selected.map((value) => (
                  <Chip key={value} label={options[value]} />
                ))}
              </div>
            )}
          >
            {Object.keys(options).map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox checked={benefitsChoices.indexOf(item) > -1} />
                <ListItemText primary={options[item]} />
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage component={TextError} name={name} />
        </>
      )}
    </>
  )
}

SelectWrapper.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
}

export default SelectWrapper
