import axios from "axios"
import { format, isMatch, subMinutes, parse } from "date-fns"
import {
  API_PATH,
  LOG_IN_PATH,
  // SYSTEM_ERROR_PATH,
} from "../navigation/constatnsPaths"
import { getAuthInfo } from "../utils/authStorage"
import { camel2snake, convertDateFormat, snake2camel } from "../utils/helper"
import { returnApiErrors } from "./error/actions"
import store from "./store"

const TIMEZONE_OFFSET_TOKYO = 9 * 60
const TIMEZONE_OFFSET_MINUTES =
  TIMEZONE_OFFSET_TOKYO + new Date().getTimezoneOffset()

/**
 * JSTの日付文字列からJSTのDateオブジェクトを返す
 */
const convertTimeStringToJSTDate = (jstDateString, formatString) => {
  const date = subMinutes(
    parse(jstDateString, formatString, new Date()),
    TIMEZONE_OFFSET_MINUTES
  )
  const dateString = format(date, formatString)
  return parse(dateString, formatString, new Date())
}

function mutateRecursive(obj, ...mutateFunc) {
  if (Array.isArray(obj)) {
    return obj.map((item) => mutateRecursive(item, ...mutateFunc))
  }
  if (
    typeof obj === "object" &&
    obj !== null &&
    obj.constructor.name === "Object"
  ) {
    return Object.fromEntries(
      Object.entries(obj)
        .map((kv) =>
          mutateFunc.reduce((current, mutate) => mutate(current), kv)
        )
        .filter((kv) => kv.length === 2)
        .map(([key, value]) => [key, mutateRecursive(value, ...mutateFunc)])
    )
  }

  return obj
}

function camel2snakeKey([key, value]) {
  return [camel2snake(key), value]
}

function snake2camelKey([key, value]) {
  return [snake2camel(key), value]
}

function trimValue([key, value]) {
  // 空文字とundeifnedが値になっているキーを排除
  if (value === "" || typeof value === "undefined") return []
  return [key, value]
}

function serverDateFormat([key, value]) {
  let newVal = value
  if (
    typeof value === "object" &&
    value !== null &&
    (value.constructor.name === "Date" ||
      value.constructor.name === "ShiftedDate")
  ) {
    if (key.endsWith("_date")) {
      newVal = convertDateFormat(value, "yyyy-MM-dd")
    } else {
      newVal = convertDateFormat(value, "yyyy-MM-dd HH:mm:ss")
    }
  }
  return [key, newVal]
}

function clientDateFormat([key, value]) {
  if (key === "birthDate") {
    try {
      if (value && value.includes("T")) {
        return [key, value.split("T")[0]]
      }
    } catch (_err) {
      // noop
    }
  } else if (
    typeof value === "string" &&
    isMatch(value, "yyyy-MM-dd HH:mm:ss")
  ) {
    // ローカル時間に変更
    return [key, convertTimeStringToJSTDate(value, "yyyy-MM-dd HH:mm:ss")]
  }
  return [key, value]
}

function mutateRequest(config) {
  if (config.params) {
    config.params = mutateRecursive(config.params, camel2snakeKey, trimValue)
  }
  if (config.data) {
    config.data = mutateRecursive(config.data, camel2snakeKey, serverDateFormat)
  }
}

function mutateResponse(config) {
  if (config.data) {
    config.data = mutateRecursive(config.data, snake2camelKey, clientDateFormat)
  }
}

/**
 * unauthorized API
 */

const axiosDefault = axios.create({
  baseURL: API_PATH,
  headers: {
    "Content-Type": "application/json",
    "X-APPLICATION": "fanpla-owner",
  },
})

axiosDefault.interceptors.request.use((config) => {
  mutateRequest(config)
  return config
})

axiosDefault.interceptors.response.use(
  (config) => {
    mutateResponse(config)
    return config
  },
  async (error) => {
    if (error.response?.data) {
      await store.dispatch(
        returnApiErrors(error.response.data, error.response.status)
      )
    }
    throw error
  }
)

export const api = axiosDefault

/**
 * autorized API
 */
const axiosWithAuth = axios.create({
  baseURL: API_PATH,
  headers: {
    "Content-Type": "application/json",
    "X-APPLICATION": "fanpla-owner",
  },
})

axiosWithAuth.interceptors.request.use((config) => {
  mutateRequest(config)
  const { token } = getAuthInfo()
  config.headers["X-Authorization"] = `Bearer ${token}`
  return config
})

axiosWithAuth.interceptors.response.use(
  (config) => {
    mutateResponse(config)
    return config
  },
  async (error) => {
    if (error?.response?.status === 401) {
      sessionStorage.setItem(
        "authRequestFrom",
        window.location.pathname + window.location.search
      )
      window.location = LOG_IN_PATH
      return
    }
    if (error.response?.data) {
      await store.dispatch(
        returnApiErrors(error.response.data, error.response.status)
      )
    }
    throw error
  }
)

export const authApi = axiosWithAuth

/**
 * Upload API
 * @param {file} imageFile
 * @param type
 * @returns
 */
export const uploadImage = (imageFile, type) => {
  const formData = new FormData()
  formData.append("file", imageFile)
  return authApi
    .post(`/${type}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (!res.data.success) throw Error()
      return { data: { link: res.data.data } }
    })
}

export const uploadNFTImage = (imageFile, mediaType) => {
  const formData = new FormData()
  formData.append("file", imageFile)
  return authApi
    .post(`/nft/${mediaType}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (!res.data.success) throw Error()
      return { data: { link: res.data.data } }
    })
}

export const uploadTixplusContentImage = (imageFile) => {
  const formData = new FormData()
  formData.append("file", imageFile)
  return authApi
    .post(`/nft/content/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (!res.data.success) throw Error()
      return { data: { link: res.data.data } }
    })
}

export const uploadSerialNumberCSV = (csv) => {
  const formData = new FormData()
  formData.append("file", csv)
  return authApi
    .post(`/serial/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (!res.data.success) throw Error()
      return { data: { serialNumber: res.data.data } }
    })
}
