import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useSnackbar } from "notistack"
import { ethers } from "ethers"
import { METAMASK_NETWORK_CONFIG } from "../utils/blockchain/network"
import { Web3Context } from "../context/web3"
import {
  getOfficialWalletList,
  getUserWalletList,
} from "../store/pages/admin/bank_management/actions"
import store from "../store/store"

export function useWeb3() {
  let mounted = true
  const web3Context = useContext(Web3Context)
  if (!web3Context) {
    console.error("[DEBUG]: context未設定(Web3Context)")
  }

  const [currentNetworkId, setCurrentNetworkId] = useState()
  const { auth, bankManagement } = useSelector((state) => state)
  const { enqueueSnackbar } = useSnackbar()
  const isUser = auth.role === "user"
  const isOfficial = auth.role === "official"

  const hasMetaMask = useMemo(() => {
    if (!window.ethereum) {
      console.error("Not found MetaMask.")
      return false
    }
    return true
  }, [])

  const provider = useMemo(() => {
    if (!hasMetaMask) return null
    return new ethers.providers.Web3Provider(window.ethereum, "any")
  }, [hasMetaMask])

  const currentNetwork = useMemo(() => {
    switch (currentNetworkId) {
      case 1:
        return "ethereum"
      case 5:
        return "goerli"
      case 137:
        return "polygon"
      case 11155111:
        return "sepolia"
      case undefined:
        return undefined
      default:
        return null
    }
  }, [currentNetworkId])

  const confirmAndChangeNetworkFor = useCallback(
    async (network) => {
      if (!hasMetaMask) return

      if (!Object.keys(METAMASK_NETWORK_CONFIG).includes(network)) {
        throw new Error(
          `指定されたネットワークには対応していません: ${network}`
        )
      }

      // 特に何もしなくてもいいパターン
      if (network === currentNetwork) return

      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: METAMASK_NETWORK_CONFIG[network].chainId }],
        })
        if (mounted) {
          setCurrentNetworkId(METAMASK_NETWORK_CONFIG[network].chainId)
        }
      } catch (e) {
        // メタマスクにまだそのネットワーク設定が登録されていない場合は追加リクエスト
        if (e.code === 4902) {
          window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [METAMASK_NETWORK_CONFIG[network]],
          })
        } else {
          console.error(e)
        }
      }
    },
    [currentNetwork, hasMetaMask, mounted]
  )
  const establishConnection = useCallback(async () => {
    const address = bankManagement.walletList[0]
      ? bankManagement.walletList[0].wallet
      : undefined
    try {
      if (!hasMetaMask) return

      // 複数接続していても現在選択されているEOAのAddressが帰ってくる
      const addresses =
        (await window.ethereum.request({
          method: "eth_requestAccounts",
        })) || []
      if (address && addresses[0] !== address) {
        enqueueSnackbar("決済情報と異なるWalletアカウントが選択されています", {
          variant: "warning",
        })
      }
    } catch (e) {
      console.error(e)
    }
  }, [bankManagement.walletList, enqueueSnackbar, hasMetaMask])

  /**
   * 上記のメソッドたちをまとめてやるメソッド
   * @type {(function(*): Promise<void>)|*}
   */
  const prepareWallet = useCallback(
    async (network) => {
      await establishConnection()
      await confirmAndChangeNetworkFor(network)
    },
    [confirmAndChangeNetworkFor, establishConnection]
  )

  useEffect(() => {
    if (isUser) {
      store.dispatch(getUserWalletList())
    } else if (isOfficial) {
      store.dispatch(getOfficialWalletList())
    }
  }, [isUser, isOfficial])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    mounted = true

    if (provider) {
      provider.getNetwork().then(({ chainId }) => {
        if (mounted) {
          setCurrentNetworkId(chainId)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [provider])

  return {
    ...(web3Context || {}),
    confirmAndChangeNetworkFor,
    establishConnection,
    prepareWallet,
    currentNetwork,
    hasMetaMask,
  }
}
