export const getAuthInfo = () => ({
  role: localStorage.getItem("role"),
  token: localStorage.getItem("token"),
  userId: localStorage.getItem("userId"),
  userName: localStorage.getItem("userName"),
})

export const hasAuthToken = () => !!getAuthInfo().token

export const setAuthInfo = ({ role, token, userId, userName }) => {
  localStorage.setItem("role", role)
  localStorage.setItem("token", token)
  localStorage.setItem("userId", userId)
  localStorage.setItem("userName", userName)
}

export const removeAuthInfo = () => {
  localStorage.removeItem("role")
  localStorage.removeItem("token")
  localStorage.removeItem("userId")
  localStorage.removeItem("userName")
}
