import { convertFromJSONToHTML } from "../../utils/helper"
import {
  NOTIFICATION_LIST_INIT,
  NOTIFICATION_LIST_LOADED,
  NOTIFICATION_LIST_CLEAR,
} from "./types"

const initialState = () => ({
  breakingnews: {
    list: [],
    current: 0,
    isLast: false,
  },
  news: {
    list: [],
    current: 0,
    isLast: false,
  },
  topnews: {
    list: [],
    current: 0,
    isLast: false,
  },
})

const convertNotificationList = (currentState, payload) => ({
  list: currentState.list.concat(
    payload.data.map((item) => ({
      ...item,
      descriptionHTML: convertFromJSONToHTML(JSON.parse(item.description)),
    }))
  ),
  current: payload.pagination.current,
  isLast: payload.pagination.isLast,
})

const notificationReducers = (state = initialState(), action) => {
  switch (action.type) {
    case NOTIFICATION_LIST_INIT:
      return initialState()
    case NOTIFICATION_LIST_CLEAR:
      return {
        ...state,
        [action.messageType]: initialState()[action.messageType],
      }
    case NOTIFICATION_LIST_LOADED: {
      const newState = { ...state }
      newState[action.messageType] = convertNotificationList(
        state[action.messageType],
        action.payload
      )
      return newState
    }
    default:
      return state
  }
}

export default notificationReducers
