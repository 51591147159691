export const SECONDARY_NFT_MANAGEMENT_INIT = "SECONDARY_NFT_MANAGEMENT_INIT"
export const SECONDARY_NFT_MANAGEMENT_REQUEST =
  "SECONDARY_NFT_MANAGEMENT_REQUEST"
export const SECONDARY_NFT_MANAGEMENT_LOADED = "SECONDARY_NFT_MANAGEMENT_LOADED"
export const SECONDARY_NFT_MANAGEMENT_DRAFT_REQUEST =
  "SECONDARY_NFT_MANAGEMENT_DRAFT_REQUEST"
export const SECONDARY_NFT_MANAGEMENT_DRAFT_LOADED =
  "SECONDARY_NFT_MANAGEMENT_DRAFT_LOADED"
export const SECONDARY_NFT_MANAGEMENT_CREATED =
  "SECONDARY_NFT_MANAGEMENT_CREATED"
export const SECONDARY_NFT_MANAGEMENT_ERROR = "SECONDARY_NFT_MANAGEMENT_ERROR"

export const USER_BANK_LIST_LOADED = "USER_BANK_LIST_LOADED"
export const USER_WALLET_LIST_LOADED = "USER_WALLET_LIST_LOADED"

export const CHANGE_SECONDARY_AUCTION_WINNER_LOADED =
  "CHANGE_SECONDARY_AUCTION_WINNER_LOADED"

export const SECONDARY_AUCTION_CLOSED_LOADED = "SECONDARY_AUCTION_CLOSED_LOADED"

export const SECONDARY_LIST_CLOSED_LOADED = "SECONDARY_LIST_CLOSED_LOADED"
