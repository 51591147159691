/* eslint-disable import/no-cycle */
import { lazy } from "react"

const NFTMarket = lazy(() => import("../pages/Market/NFTMarket/NFTMarket")) // NFTマーケット
const NFTMarketList = lazy(() =>
  import("../pages/Market/NFTMarketList/NFTMarketList")
) // NFTマーケット一覧
const MarketItem = lazy(() => import("../pages/Market/MarketItem/MarketItem")) // マーケット詳細
const Purchase = lazy(() => import("../pages/Purchase/PurchaseContainer")) // マーケット購入
const PurchaseComplete = lazy(() =>
  import("../pages/Purchase/PurchaseComplete")
) // 購入完了
const Collection = lazy(() =>
  import("../pages/NFTManagement/SecondaryListing/Collection/Collection")
) // コレクション
const Mypage = lazy(() => import("../pages/Mypage/Maypage/Mypage")) // マイページ
const MypageEdit = lazy(() => import("../pages/Mypage/MaypageEdit/MypageEdit")) // マイページ編集
const SellerMypage = lazy(() =>
  import("../pages/Market/SellerMaypage/SellerMypage")
) // 出品者マイページ
const SellFromCollection = lazy(() =>
  import(
    "../pages/NFTManagement/SecondaryListing/NFTSell/SellFromCollection/SellFromCollectionContainer"
  )
) // コレクションから出品
const NFTManagement = lazy(() =>
  import("../pages/NFTManagement/NFTManagement/NFTManagement")
) // NFT管理
const OfficialNFTManagement = lazy(() =>
  import("../pages/NFTManagement/NFTManagement/OfficialNFTManagement")
) // 公式NFT管理
const CreateNewNFT = lazy(() =>
  import(
    "../pages/NFTManagement/NFTManagement/CreateNewNFT/CreateNewNFTContainer"
  )
) // NFT新規作成
const DuplicateNFT = lazy(() =>
  import(
    "../pages/NFTManagement/NFTManagement/DuplicateNFT/DuplicateNFTContainer"
  )
) // NFT複製
const NFTManagementItem = lazy(() =>
  import(
    "../pages/NFTManagement/NFTManagement/EditNFT/NFTItem/NFTItemContainer"
  )
) // NFT管理マーケット詳細 削除・非公開機能
const EditNFT = lazy(() =>
  import(
    "../pages/NFTManagement/NFTManagement/EditNFT/EditNFT/EditNFTContainer"
  )
) // NFT編集
const TicketManagementSelectTour = lazy(() =>
  import("../pages/TicketManagement/TicketManagement/SelectTour")
) // チケット管理 公演選択
const TicketManagementSelectConcert = lazy(() =>
  import("../pages/TicketManagement/TicketManagement/SelectConcert")
) // チケット管理 公演選択
const TicketManagement = lazy(() =>
  import("../pages/TicketManagement/TicketManagement/TicketManagement")
) // チケット管理 公演詳細
const BenefitsManagementAccountSelect = lazy(() =>
  import("../pages/BenefitsManagement/BenefitsManagement/AccountSelect")
) // 特典管理 アカウント選択
const BenefitsManagementTable = lazy(() =>
  import(
    "../pages/BenefitsManagement/BenefitsManagement/BenefitsManagementTable"
  )
) // 特典管理 一覧
const BenefitsManagement = lazy(() =>
  import("../pages/BenefitsManagement/BenefitsManagement/BenefitsManagement")
) // 特典管理 詳細
const CreateBenefit = lazy(() =>
  import("../pages/BenefitsManagement/CreateNewBenefit/CreateNewBenefit")
) // 特典新規作成
const BenefitsManagementHolding = lazy(() =>
  import(
    "../pages/BenefitsManagement/BenefitsManagement/BenefitsManagementHolding"
  )
) // 特典管理 保有者
const UseBenefitsManagement = lazy(() =>
  import("../pages/BenefitsManagement/UseBenifitsManagement/BenefitsList")
) // 特典使用管理
const Benefits = lazy(() =>
  import("../pages/BenefitsManagement/UseBenifitsManagement/BenefitsDetail")
) // 特典使用管理
const SalesManagement = lazy(() =>
  import("../pages/AccountManagement/SalesManagement/SalesManagement")
) // 売上管理
const SalesOfficialManagement = lazy(() =>
  import(
    "../pages/AccountManagement/SalesOfficialManagement/SalesOfficialManagement"
  )
) // 売上管理(公式アカウント)
const AdminPaymentOfficialManagement = lazy(() =>
  import("../pages/Admin/PaymentOfficialManagement/PaymentOfficialManagement")
) // 売上管理(一般アカウント)
const AdminPaymentManagement = lazy(() =>
  import("../pages/Admin/PaymentManagement/PaymentManagement")
) // 入金管理(公式アカウント)
const TradingHistory = lazy(() =>
  import("../pages/AccountManagement/TradingHistory/TradingHistory")
) // 取引履歴
const AccountManagement = lazy(() =>
  import(
    "../pages/AccountManagement/AccountManagement/AccountManagementContainer"
  )
) // アカウント管理
const BankCreditInfo = lazy(() =>
  import("../pages/AccountManagement/BankCreditInfo/BankCreditInfoContainer")
) // 銀行・クレジット情報
const TransferAccountInfo = lazy(() =>
  import(
    "../pages/AccountManagement/OfficialAccount/TransferAccountInfo/TransferAccountInfo"
  )
) // 組織用 振り込み口座情報
const UserManagement = lazy(() =>
  import(
    "../pages/AccountManagement/OfficialAccount/UserManagement/UserManagement"
  )
) // 組織用 ユーザー管理
const CreateNewUser = lazy(() =>
  import(
    "../pages/AccountManagement/OfficialAccount/UserManagement/CreateNewUserContainer"
  )
) // 組織用 ユーザー管理 新規ユーザー作成
const EditUser = lazy(() =>
  import(
    "../pages/AccountManagement/OfficialAccount/UserManagement/EditUserContainer"
  )
) // 組織用 ユーザー管理 ユーザー編集
const AdminGeneralAccountManagement = lazy(() =>
  import(
    "../pages/AccountManagement/AdminAccount/GeneralAccount/GeneralAccountManagement"
  )
) // 運営用 個人ユーザー管理
const AdminOfficialAccountManagement = lazy(() =>
  import(
    "../pages/AccountManagement/AdminAccount/OfficialAccount/OfficialAccountManagement"
  )
) // 運営用 組織ユーザー管理
const AdminGeneralAccount = lazy(() =>
  import(
    "../pages/AccountManagement/AdminAccount/GeneralAccount/GeneralAccount"
  )
) // 運営用 個人アカウント情報、マイページ、口座・カード情報
const AdminOfficialAccount = lazy(() =>
  import(
    "../pages/AccountManagement/AdminAccount/OfficialAccount/OfficialAccount"
  )
) // 運営用 組織アカウント情報、マイページ、組織ユーザー管理、口座・カード情報
const AdminEditGeneralAccountInfo = lazy(() =>
  import(
    "../pages/AccountManagement/AdminAccount/GeneralAccount/GeneralAccountInfo/GeneralAccountInfo"
  )
) // 運営用 個人アカウント情報 編集ページ
const AdminEditProfileInfo = lazy(() =>
  import("../pages/AccountManagement/AdminAccount/Profile/EditProfile")
) // 運営用 個人 & 組織プロフィール情報 編集ページ
// ) // 運営用 個人 & 組織銀行口座・クレジットカード情報 編集ページ
const CreateOfficialAccount = lazy(() =>
  import(
    "../pages/AccountManagement/AdminAccount/OfficialAccount/OfficialAccountInfo/CreateOfficialAccount"
  )
) // 運営用 組織アカウント作成ページ
const AdminEditOfficialAccountInfo = lazy(() =>
  import(
    "../pages/AccountManagement/AdminAccount/OfficialAccount/OfficialAccountInfo/OfficialAccountInfo"
  )
) // 運営用 組織アカウント情報 編集ページ
const AdminEditOfficialUserInfo = lazy(() =>
  import(
    "../pages/AccountManagement/AdminAccount/OfficialAccount/OfficialUserManagement/OfficialEditUserManagement"
  )
) // 運営用 組織ユーザー 編集ページ
const BlockChain = lazy(() => import("../pages/Admin/BlockChain/BlockChain")) // 運営用 ブロックチェーン監視
const AdminAccountManagement = lazy(() =>
  import("../pages/Admin/AdminAccountManagement/AdminAccountManagement")
) // 運営用 運営アカウント テーブル
const CreateNewAdminAccount = lazy(() =>
  import("../pages/Admin/AdminAccountManagement/CreateNewAdminAccount")
) // 運営用 運営アカウント 新規作成
const EditAdminAccount = lazy(() =>
  import("../pages/Admin/AdminAccountManagement/EditAdminAccount")
) // 運営用 運営アカウント 編集
const NotificationNewsManagement = lazy(() =>
  import("../pages/Admin/NotificationNewsManagement/NotificationNewsManagement")
) // 運営用 お知らせ・速報管理 テーブル
const CreateNewNotificationNews = lazy(() =>
  import("../pages/Admin/NotificationNewsManagement/CreateNewNotificationNews")
) // 運営用 お知らせ・速報管理 新規作成
const EditNotificationNews = lazy(() =>
  import("../pages/Admin/NotificationNewsManagement/EditNewNotificationNews")
) // 運営用 お知らせ・速報管理 編集
// 運営用 メッセージ送受信
const LogIn = lazy(() => import("../pages/LogIn/LogInContainer")) // ログイン
const LoginByTid = lazy(() => import("../pages/LogIn/LoginByTid")) // TIDログイン (TixPlusからのログインなど)
const SignUp = lazy(() => import("../pages/SignUp/SignUpContainer")) // 新規登録
const ForgotPassword = lazy(() =>
  import("../pages/ResetPassword/ForgotPasswordContainer")
) // パスワード忘れ
const ResetPassword = lazy(() =>
  import("../pages/ResetPassword/ResetPasswordContainer")
) // パスワード再発行
const Terms = lazy(() => import("../pages/SignUp/Terms/TermsContainer")) // 新規登録時に表示する利用規約
const SNSCheck = lazy(() =>
  import("../pages/SignUp/General/AuthSNS/AuthSNSContainer")
) // SNS認証
const NotFound = lazy(() => import("../pages/NotFound/NotFound")) // 404
const SystemError = lazy(() =>
  import("../pages/SystemErrorPage/SystemErrorPage")
) // システムエラー
const Settings = lazy(() =>
  import("../pages/AccountManagement/Settings/Settings")
) // 各種設定
const SystemManagement = lazy(() =>
  import("../pages/Admin/SystemManagement/SystemManagement")
) // システム管理
const Maintenance = lazy(() => import("../pages/Maintenance/Maintenance")) // メンテナンス画面
const NFTMarketManagement = lazy(() =>
  import("../pages/Admin/NFTMarketManagement/NFTMarketManagement")
)
const CreateNewPrivateMediaContent = lazy(() =>
  import(
    "../pages/NFTManagement/NFTManagement/PrivateMediaContent/CreateNewPMC/CreateNewPrivateMediaContentContainer"
  )
) // 限定コンテンツ新規作成
const NFTPrivateMediaContents = lazy(() =>
  import(
    "../pages/NFTManagement/NFTManagement/PrivateMediaContent/NFTPrivateMediaContents"
  )
) // 限定コンテンツ管理
const NFTPrivateMediaContentsItem = lazy(() =>
  import(
    "../pages/NFTManagement/NFTManagement/PrivateMediaContent/EditPMC/PMCItem/NFTPrivateMediaContentsItemContainer"
  )
) // 限定コンテンツ管理詳細
const EditPrivateMediaContent = lazy(() =>
  import(
    "../pages/NFTManagement/NFTManagement/PrivateMediaContent/EditPMC/EditPMC/EditPrivateMediaContentContainer"
  )
) // 限定コンテンツ編集
const SelectOfficialAccount = lazy(() =>
  import("../pages/SelectOfficialAccount/SelectOfficialAccount")
) // 運営アカウントが公式アカウント選択(NFT出品・特典管理)
const BankTransferHistory = lazy(() =>
  import(
    "../pages/AccountManagement/AdminAccount/BankTransferedHistory/BankTransferHistory"
  )
) // 振込履歴
const LinkBank = lazy(() =>
  import(
    "../pages/AccountManagement/AdminAccount/BankTransferedHistory/LinkBank"
  )
) // あおぞら銀行連携後リダイレクト先
const Contact = lazy(() => import("../pages/Contact/Contact")) // お問い合わせ
const WPStatic = lazy(() => import("../pages/WPStatic/WPStatic")) // WordPress静的ページ

export default {
  NFTMarket,
  NFTMarketList,
  MarketItem,
  Purchase,
  PurchaseComplete,
  Collection,
  Mypage,
  MypageEdit,
  // NFTSell,
  SellerMypage,
  SellFromCollection,
  NFTManagement,
  OfficialNFTManagement,
  CreateNewNFT,
  DuplicateNFT,
  NFTManagementItem,
  NFTPrivateMediaContents,
  CreateNewPrivateMediaContent,
  NFTPrivateMediaContentsItem,
  EditPrivateMediaContent,
  EditNFT,
  TicketManagementSelectTour,
  TicketManagementSelectConcert,
  TicketManagement,
  Benefits,
  BenefitsManagementTable,
  BenefitsManagementAccountSelect,
  BenefitsManagement,
  BenefitsManagementHolding,
  UseBenefitsManagement,
  CreateBenefit,
  SalesManagement,
  SalesOfficialManagement,
  TradingHistory,
  AccountManagement,
  AdminGeneralAccountManagement,
  AdminOfficialAccountManagement,
  AdminGeneralAccount,
  AdminOfficialAccount,
  AdminEditGeneralAccountInfo,
  AdminEditProfileInfo,
  CreateOfficialAccount,
  AdminEditOfficialAccountInfo,
  AdminEditOfficialUserInfo,
  AdminPaymentOfficialManagement,
  AdminPaymentManagement,
  BlockChain,
  AdminAccountManagement,
  EditAdminAccount,
  CreateNewAdminAccount,
  NotificationNewsManagement,
  CreateNewNotificationNews,
  EditNotificationNews,
  BankCreditInfo,
  TransferAccountInfo,
  UserManagement,
  CreateNewUser,
  EditUser,
  LogIn,
  LoginByTid,
  SignUp,
  ForgotPassword,
  ResetPassword,
  Terms,
  SNSCheck,
  NotFound,
  SystemError,
  Settings,
  SystemManagement,
  Maintenance,
  NFTMarketManagement,
  SelectOfficialAccount,
  BankTransferHistory,
  LinkBank,
  Contact,
  WPStatic,
}
