export const USER_PAYMENT_LIST_REQUEST = "USER_PAYMENT_LIST_REQUEST"
export const USER_PAYMENT_LIST_LOADED = "USER_PAYMENT_LIST_LOADED"
export const USER_PAYMENT_LIST_ERROR = "USER_PAYMENT_LIST_ERROR"

export const USER_PAYMENT_CREATED = "USER_PAYMENT_CREATED"
export const USER_PAYMENT_REQUEST = "USER_PAYMENT_REQUEST"
export const USER_PAYMENT_ERROR = "USER_PAYMENT_ERROR"

export const OFFICIAL_PAYMENT_LIST_REQUEST = "OFFICIAL_PAYMENT_LIST_REQUEST"
export const OFFICIAL_PAYMENT_LIST_LOADED = "OFFICIAL_PAYMENT_LIST_LOADED"
export const OFFICIAL_PAYMENT_LIST_ERROR = "OFFICIAL_PAYMENT_LIST_ERROR"

export const OFFICIAL_PAYMENT_CREATED = "OFFICIAL_PAYMENT_CREATED"
export const OFFICIAL_PAYMENT_REQUEST = "OFFICIAL_PAYMENT_REQUEST"
export const OFFICIAL_PAYMENT_ERROR = "OFFICIAL_PAYMENT_ERROR"

export const USER_PAYMENT_LIST_INIT = "USER_PAYMENT_LIST_INIT"
export const OFFICIAL_PAYMENT_LIST_INIT = "OFFICIAL_PAYMENT_LIST_INIT"
