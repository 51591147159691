import { PAGINATION_INIT, PAGINATION_SET } from "./types"

const initialState = () => ({
  current: 1,
  isFirst: true,
  isLast: true,
  pages: 1,
  perPage: 10,
  total: 1,
})

const paginationReducer = (
  state = {
    default: initialState(),
    brand: initialState(),
    category: initialState(),
    banner: initialState(),
    primaryYenSalesHistory: initialState(),
    secondaryYenSalesHistory: initialState(),
    primaryEthSalesHistory: initialState(),
    secondaryEthSalesHistory: initialState(),
    nftSearchList: initialState(),
    userPaymentListYen: initialState(),
    userPaymentListEth: initialState(),
    userPaymentListMatic: initialState(),
    officialPaymentListYen: initialState(),
    officialPaymentListEth: initialState(),
    officialPaymentListMatic: initialState(),
  },
  action
) => {
  switch (action.type) {
    case PAGINATION_INIT: {
      const newState = { ...state }
      const slot = action.slot || "default"
      newState[slot] = initialState()
      return newState
    }
    case PAGINATION_SET: {
      const newState = { ...state }
      const slot = action.slot || "default"
      newState[slot] = action.payload
      return newState
    }
    default:
      return state
  }
}

export default paginationReducer
