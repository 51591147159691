export const ADMIN_ACCOUNT_LIST_LOADED = "ADMIN_ACCOUNT_LIST_LOADED"
export const ADMIN_ACCOUNT_LIST_ERROR = "ADMIN_ACCOUNT_LIST_ERROR"

export const ADMIN_ACCOUNT_REQUEST = "ADMIN_ACCOUNT_REQUEST"
export const ADMIN_ACCOUNT_INIT = "ADMIN_ACCOUNT_INIT"
export const ADMIN_ACCOUNT_LOADED = "ADMIN_ACCOUNT_LOADED"
export const ADMIN_ACCOUNT_CREATED = "ADMIN_ACCOUNT_CREATED"
export const ADMIN_ACCOUNT_UPDATED = "ADMIN_ACCOUNT_UPDATED"
export const ADMIN_ACCOUNT_DELETED = "ADMIN_ACCOUNT_DELETED"
export const ADMIN_ACCOUNT_ERROR = "ADMIN_ACCOUNT_ERROR"
