export const SEARCH_LIST_REQUEST = "SEARCH_LIST_REQUEST"
export const SEARCH_LIST_LOADED = "SEARCH_LIST_LOADED"
export const SEARCH_LIST_ERROR = "SEARCH_LIST_ERROR"
export const SEARCH_INIT = "SEARCH_INIT"
export const SEARCH_SET = "SEARCH_SET"

export const NFT_DETAIL_REQUEST = "NFT_DETAIL_REQUEST"
export const NFT_DETAIL_LOADED = "NFT_DETAIL_LOADED"
export const NFT_DETAIL_ERROR = "NFT_DETAIL_ERROR"

export const CATEGORY_LIST_LOADED = "CATEGORY_LIST_LOADED"
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR"

export const BRAND_LIST_LOADED = "BRAND_LIST_LOADED"
export const BRAND_LIST_ERROR = "BRAND_LIST_ERROR"

export const BANNER_LIST_LOADED = "BANNER_LIST_LOADED"
export const BANNER_LIST_ERROR = "BANNER_LIST_ERROR"

export const SEARCH_HISTORY_LIST_LOADED = "SEARCH_HISTORY_LIST_LOADED"
export const SEARCH_HISTORY_LIST_CLEAR = "SEARCH_HISTORY_LIST_CLEAR"
export const SEARCH_HISTORY_LIST_ERROR = "SEARCH_HISTORY_LIST_ERROR"

export const PRIMARY_NFT_LIST_REQUEST = "PRIMARY_NFT_LIST_REQUEST"
export const PRIMARY_NFT_LIST_LOADED = "PRIMARY_NFT_LIST_LOADED"
export const PRIMARY_NFT_LIST_ERROR = "PRIMARY_NFT_LIST_ERROR"

export const SECONDARY_NFT_LIST_REQUEST = "SECONDARY_NFT_LIST_REQUEST"
export const SECONDARY_NFT_LIST_LOADED = "SECONDARY_NFT_LIST_LOADED"
export const SECONDARY_NFT_LIST_ERROR = "SECONDARY_NFT_LIST_ERROR"

export const NFT_UUID_LIST_REQUEST = "NFT_UUID_LIST_REQUEST"
export const NFT_UUID_LIST_LOADED = "NFT_UUID_LIST_LOADED"
export const NFT_UUID_LIST_ERROR = "NFT_UUID_LIST_ERROR"

export const NFT_UUID_TRANSACTION_LIST_REQUEST =
  "NFT_UUID_TRANSACTION_LIST_REQUEST"
export const NFT_UUID_TRANSACTION_LIST_LOADED =
  "NFT_UUID_TRANSACTION_LIST_LOADED"
export const NFT_UUID_TRANSACTION_LIST_ERROR = "NFT_UUID_TRANSACTION_LIST_ERROR"

export const NFT_UUID_UPDATED = "NFT_UUID_UPDATED"
export const NFT_UUID_ERROR = "NFT_UUID_ERROR"

export const NFT_UUID_BULK_UPDATED = "NFT_UUID_BULK_UPDATED"
export const NFT_UUID_BULK_ERROR = "NFT_UUID_BULK_ERROR"

export const NFT_VERIFY_SIGNATURES = "NFT_VERIFY_SIGNATURES"
export const NFT_VERIFY_SIGNATURES_ERROR = "NFT_VERIFY_SIGNATURES_ERROR"
