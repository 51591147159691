import {
  USE_BENEFIT_MANAGEMENT_LIST_LOADED,
  USE_BENEFIT_MANAGEMENT_LOADED,
  USE_BENEFIT_MANAGEMENT_LIST_ERROR,
  USE_BENEFIT_MANAGEMENT_ERROR,
  USE_BENEFIT_MANAGEMENT_BENEFIT_USED,
} from "./types"

const initialState = () => ({
  list: [],
  form: {
    title: "",
    detail: "",
    image: "",
    web: "",
    expiredAt: null,
    distributionMethod: "",
    story: "",
  },
})

const convertToForm = (data) => ({
  ...data.benefit,
  story: JSON.parse(data.benefit.story),
  isUsed: data.isUsed,
  userBenefitId: data.id,
})

const convertToListForm = (data) => ({
  ...data.benefit,
  userBenefitId: data.id,
})

const useBenefitManagementReducers = (state = initialState(), action) => {
  switch (action.type) {
    case USE_BENEFIT_MANAGEMENT_LIST_LOADED:
      return {
        ...state,
        list: action.payload.map((relation) => convertToListForm(relation)),
      }
    case USE_BENEFIT_MANAGEMENT_LOADED:
      return {
        ...state,
        form: convertToForm(action.payload),
      }
    case USE_BENEFIT_MANAGEMENT_BENEFIT_USED:
      return {
        ...state,
      }
    case USE_BENEFIT_MANAGEMENT_LIST_ERROR:
    case USE_BENEFIT_MANAGEMENT_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default useBenefitManagementReducers
