import {
  NFT_FAVORITE_INIT,
  NFT_FAVORITE_LOADED,
  NFT_FAVORITE_CREATED,
  NFT_FAVORITE_DELETED,
} from "./types"

const initialState = () => ({
  form: {
    nftId: 0,
    userId: 0,
  },
})

const favoriteReducers = (state = initialState(), action) => {
  switch (action.type) {
    case NFT_FAVORITE_INIT:
    case NFT_FAVORITE_LOADED:
    case NFT_FAVORITE_CREATED:
    case NFT_FAVORITE_DELETED:
    default:
      return state
  }
}

export default favoriteReducers
