export const PURCHACE_HISTORY_LIST_LOADED = "PURCHACE_HISTORY_LIST_LOADED"
export const PURCHACE_HISTORY_LIST_ERROR = "PURCHACE_HISTORY_LIST_ERROR"

export const INIT_SALES = "INIT_SALES"

export const SALES_HISTORY_LIST_REQUEST = "SALES_HISTORY_LIST_REQUEST"
export const SALES_HISTORY_LIST_LOADED = "SALES_HISTORY_LIST_LOADED"
export const SALES_HISTORY_LIST_ERROR = "SALES_HISTORY_LIST_ERROR"

export const SALES_SUMMARY_REQUEST = "SALES_SUMMARY_REQUEST"
export const SALES_SUMMARY_LOADED = "SALES_SUMMARY_LOADED"
export const SALES_SUMMARY_ERROR = "SALES_SUMMARY_ERROR"

export const ADMIN_SALES_TRANSFERRED_LOADED = "ADMIN_SALES_TRANSFERRED_LOADED"
