import {
  ADMIN_ACCOUNT_LIST_LOADED,
  ADMIN_ACCOUNT_LIST_ERROR,
  ADMIN_ACCOUNT_LOADED,
  ADMIN_ACCOUNT_INIT,
  ADMIN_ACCOUNT_UPDATED,
  ADMIN_ACCOUNT_ERROR,
  ADMIN_ACCOUNT_CREATED,
  ADMIN_ACCOUNT_DELETED,
} from "./types"

const initialState = () => ({
  list: [],
  transition: [],
  form: {
    nickname: "",
    lastName: "",
    firstName: "",
    lastNameKana: "",
    firstNameKana: "",
    birthDate: "",
    email: "",
    emailConfirmation: "",
    password: "",
    passwordConfirmation: "",
  },
})

const convertToForm = (data) => {
  const validKeys = [
    "nickname",
    "lastName",
    "firstName",
    "lastNameKana",
    "firstNameKana",
    "birthDate",
    "email",
  ]
  const requiredData = Object.fromEntries(
    Object.entries(data).filter(([key, _value]) => validKeys.includes(key))
  )
  return {
    ...requiredData,
    nickname: data.nickname || "",
    emailConfirmation: data.email,
    password: "",
    passwordConfirmation: "",
  }
}

const adminAccountReducers = (state = initialState(), action) => {
  switch (action.type) {
    case ADMIN_ACCOUNT_LIST_LOADED:
      return {
        ...state,
        list: action.payload,
      }
    case ADMIN_ACCOUNT_LOADED: {
      return {
        ...state,
        form: convertToForm(action.payload),
      }
    }
    case ADMIN_ACCOUNT_INIT:
      return {
        ...state,
        form: initialState().form,
      }
    case ADMIN_ACCOUNT_CREATED:
    case ADMIN_ACCOUNT_UPDATED:
    case ADMIN_ACCOUNT_DELETED:
      return {
        ...state,
      }
    case ADMIN_ACCOUNT_LIST_ERROR:
    case ADMIN_ACCOUNT_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default adminAccountReducers
