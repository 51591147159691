import { COLLECTION_LIST_LOADED, COLLECTION_LIST_ERROR } from "./types"

const initialState = {
  list: [],
}

const convertToForm = (data) => ({
  ...data,
  createdAt: new Date(data.published_at),
})

const collectionReducers = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_LIST_LOADED:
      return {
        ...state,
        list: action.payload.map((data) => convertToForm(data)),
      }
    case COLLECTION_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default collectionReducers
