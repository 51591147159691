import {
  OFFICIAL_ACCOUNT_LIST_LOADED,
  OFFICIAL_ACCOUNT_LIST_ERROR,
} from "./types"

const initialState = {
  list: [],
}

const convertToForm = (data) => ({
  ...data,
  createdAt: new Date(data.published_at),
})

const adminOfficialAccountReducers = (state = initialState, action) => {
  switch (action.type) {
    case OFFICIAL_ACCOUNT_LIST_LOADED:
      return {
        ...state,
        list: action.payload.map((data) => convertToForm(data)),
      }
    case OFFICIAL_ACCOUNT_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default adminOfficialAccountReducers
