import Web3 from "web3"
import { ethers } from "ethers"
import PropTypes from "prop-types"
import detectEthereumProvider from "@metamask/detect-provider"
import { createContext, useEffect, useState } from "react"

/**
 * @type {React.Context<Web3>} Web3 Context
 */
const Web3Context = createContext(undefined)

const Web3Provider = ({ children }) => {
  /**
   * @type {[Web3 | undefined, Function]} Web3 Instance
   */
  const [web3Instance, setWeb3Instance] = useState(undefined)
  /**
   * @type {[object | undefined, Function]} Web3 Instance
   */
  const [signer, setSigner] = useState(undefined)

  useEffect(() => {
    const setup = async () => {
      const provider = await detectEthereumProvider()
      if (!provider) return

      const web3 = new Web3(provider)
      setWeb3Instance(web3)

      const ethersProvider = new ethers.providers.Web3Provider(provider)
      setSigner(ethersProvider.getSigner())
    }

    setup().finally(/* NOP */)
  }, [])

  // noinspection JSXUnresolvedComponent
  return (
    <Web3Context.Provider value={{ web3: web3Instance, signer }}>
      {children}
    </Web3Context.Provider>
  )
}

Web3Provider.propTypes = {
  children: PropTypes.node,
}

export { Web3Context, Web3Provider }
