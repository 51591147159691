import React from "react"
import PropTypes from "prop-types"
import { TextField } from "@material-ui/core"
import { useField } from "formik"

const TextFieldWrapper = ({ name, ...props }) => {
  const [field, meta] = useField(name)

  const configTextfield = {
    fullWidth: true,
    ...field,
    ...props,
  }
  if (meta && meta.touched && meta.error) {
    configTextfield.error = true
    configTextfield.helperText = meta.error
  }
  return <TextField {...configTextfield} />
}

TextFieldWrapper.propTypes = {
  name: PropTypes.string,
}

export default TextFieldWrapper
