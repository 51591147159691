import { useEffect, useMemo, useState } from "react"

// View可視状態を取得する
export const useOnScreen = (ref, enabled) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    []
  )

  useEffect(() => {
    if (!enabled || !ref.current) return {}

    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  })

  return isIntersecting
}
