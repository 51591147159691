import {
  USER_PAYMENT_CREATED,
  USER_PAYMENT_LIST_ERROR,
  OFFICIAL_PAYMENT_LIST_ERROR,
  USER_PAYMENT_LIST_LOADED,
  OFFICIAL_PAYMENT_LIST_LOADED,
  USER_PAYMENT_LIST_INIT,
  OFFICIAL_PAYMENT_LIST_INIT,
  OFFICIAL_PAYMENT_CREATED,
  USER_PAYMENT_REQUEST,
  USER_PAYMENT_ERROR,
  OFFICIAL_PAYMENT_REQUEST,
  OFFICIAL_PAYMENT_ERROR,
} from "./types"

const salesMethods = {
  list: "定価販売",
  lottery: "抽選",
  auction: "オークション",
  airdrop: "エアドロップ",
}

const initialState = () => ({
  userPaymentList: {
    yen: {
      list: [],
      csv: [],
    },
    eth: {
      list: [],
      csv: [],
    },
    matic: {
      list: [],
      csv: [],
    },
  },
  officialPaymentList: {
    yen: {
      list: [],
      csv: [],
    },
    eth: {
      list: [],
      csv: [],
    },
    matic: {
      list: [],
      csv: [],
    },
  },
})

const convertUserRow = (data) => ({
  ...data,
  salesMethodName: salesMethods[data.salesMethod],
})

const convertUserCsv = (data) => {
  const rows = data
    .map(convertUserRow)
    .map((item) => [
      item.hasTransferredToUser ? "入金済み" : "未入金",
      item.userNft.nft.id,
      new Date(item.createdAt).toLocaleString("ja-JP"),
      item.orderId,
      item.userNft.nft.title,
      item.salesMethodName,
    ])

  const header = [
    "入金状況",
    "出品ID",
    "取引時間",
    "取引ID",
    "出品名",
    "出品形式",
  ]
  return [header, ...rows]
}

const convertOfficialCsv = (data) => {
  const rows = data.map((item) => [
    item.hasTransferredToUser ? "入金済み" : "未入金",
    item.officialId,
    item.officialName,
    item.primaryCount,
    item.primaryPrice,
    item.primarySales,
    item.secondaryCount,
    item.secondaryPrice,
    item.secondarySales,
    item.total,
  ])

  const header = [
    "入金状況",
    "オフィシャルID",
    "オフィシャル名",
    "一次流通件数",
    "一次流通金額",
    "一次販売利益",
    "二次流通件数",
    "二次流通金額",
    "二次販売利益",
    "月額合計金額",
  ]
  return [header, ...rows]
}

const paymentManagementReducers = (state = initialState(), action) => {
  switch (action.type) {
    case USER_PAYMENT_LIST_INIT:
      return {
        ...state,
        userPaymentList: initialState().userPaymentList,
      }
    case OFFICIAL_PAYMENT_LIST_INIT:
      return {
        ...state,
        officialPaymentList: initialState().officialPaymentList,
      }
    case USER_PAYMENT_LIST_LOADED: {
      const newState = {
        ...state,
      }
      newState.userPaymentList[action.currencyType] = {
        list: action.payload.map(convertUserRow),
        csv: convertUserCsv(action.payload),
      }
      return newState
    }
    case OFFICIAL_PAYMENT_LIST_LOADED: {
      const newState = {
        ...state,
      }
      if (action.currencyType) {
        newState.officialPaymentList[action.currencyType] = {
          list: action.payload[action.currencyType],
          csv: convertOfficialCsv(action.payload[action.currencyType]),
        }
      } else {
        newState.officialPaymentList.yen = {
          list: action.payload.yen,
          csv: convertOfficialCsv(action.payload.yen),
        }
        newState.officialPaymentList.eth = {
          list: action.payload.eth,
          csv: convertOfficialCsv(action.payload.eth),
        }
        newState.officialPaymentList.matic = {
          list: action.payload.matic,
          csv: convertOfficialCsv(action.payload.matic),
        }
      }
      return newState
    }
    case USER_PAYMENT_CREATED:
    case USER_PAYMENT_REQUEST:
    case USER_PAYMENT_ERROR:
    case OFFICIAL_PAYMENT_CREATED:
    case OFFICIAL_PAYMENT_REQUEST:
    case OFFICIAL_PAYMENT_ERROR: {
      const list = action.type.startsWith("OFFICIAL_")
        ? state.officialPaymentList
        : state.userPaymentList
      const target = list[action.payload.currencyType].list.find(
        (item) => item.id === action.payload.transactionId
      )
      if (target) {
        target.isLoading = action.type.endsWith("_REQUEST")
        if (action.type.endsWith("_CREATED")) {
          target.hasTransferredToUser = !target.hasTransferredToUser
          target.hasTransferredToOfficial = !target.hasTransferredToOfficial
        }
      }
      return {
        ...state,
      }
    }
    case USER_PAYMENT_LIST_ERROR:
    case OFFICIAL_PAYMENT_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default paymentManagementReducers
