import ApiErrors from "./ApiErrors"
import { GET_ERRORS, CLEAR_ERRORS, GET_API_ERRORS } from "./types"

const initialState = {
  msg: "",
  errors: new ApiErrors(),
  status: null,
  id: null,
}

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS:
      return {
        msg: action.payload.msg,
        errors: new ApiErrors(),
        status: action.payload.status,
        id: action.payload.id,
      }
    case GET_API_ERRORS:
      return {
        msg: "",
        errors: new ApiErrors(action.payload.errors, action.payload.error),
        status: action.payload.status,
        id: null,
      }
    case CLEAR_ERRORS:
      return {
        msg: "",
        errors: new ApiErrors(),
        status: null,
        id: null,
      }
    default:
      return state
  }
}

export default errorReducer
