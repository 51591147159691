import React from "react"
import PropTypes from "prop-types"
import {
  Switch,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core"
import { useField, useFormikContext } from "formik"
import "./Switch.scss"

const SwitchWrapper = ({ name, label, legend, ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)
  const handleChange = (event) => {
    const { checked } = event.target
    setFieldValue(name, checked)
  }

  const configSwitch = {
    ...field,
    ...props,
    onChange: handleChange,
  }
  const configFormControl = {}
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true
  }
  return (
    <FormControl {...configFormControl}>
      <FormLabel component="legend">{legend}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Switch {...configSwitch} />}
          label={label}
        />
      </FormGroup>
    </FormControl>
  )
}

SwitchWrapper.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  legend: PropTypes.string,
}

export default SwitchWrapper
