import React from "react"
import { useLocation } from "react-router-dom"

export const ScrollToTop = () => {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

// カスタムscroll Top
export const StepperScrollToTop = ({ step }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  return null
}
