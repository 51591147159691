import { GET_ERRORS, GET_API_ERRORS } from "./types"

// RETURN ERRORS
export const returnErrors = (msg, status, id = null) => ({
  type: GET_ERRORS,
  payload: { msg, status, id },
})

export const returnApiErrors = (data, status) => ({
  type: GET_API_ERRORS,
  payload: { errors: data.errors, error: data.error, status },
})
