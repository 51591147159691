import { precautions } from "../../../../utils/precautions"
import { getCurrencyFormattWithoutSymbol } from "../../../../utils/helper"
import {
  NFT_MANAGEMENT_INIT,
  NFT_MANAGEMENT_CREATED,
  NFT_MANAGEMENT_DUPLICATED,
  NFT_MANAGEMENT_LIST_LOADED,
  NFT_MANAGEMENT_LIST_ERROR,
  NFT_MANAGEMENT_ERROR,
  NFT_MANAGEMENT_LOADED,
  NFT_MANAGEMENT_UPDATED,
  NFT_MANAGEMENT_DELETED,
  NFT_MANAGEMENT_LOTTERY_LIST_LOADED,
  NFT_MANAGEMENT_LOTTERY_LIST_ERROR,
  NFT_MANAGEMENT_AUCTION_LIST_LOADED,
  NFT_MANAGEMENT_AUCTION_LIST_ERROR,
  APPROVE_LOADED,
  APPROVE_UPDATED,
  AUCTION_LIST_LOADED,
  CHANGE_AUCTION_WINNER_LOADED,
  NFT_AUCTION_CLOSED_LOADED,
  TRANSFER_NFT_REQUEST,
  NFT_PRIVATE_MEDIA_CONTENTS_DESCRIPTION_LOADED,
  NFT_PRIVATE_MEDIA_CONTENTS_DESCRIPTION_UPDATED,
  NFT_PRIVATEMEDIACONTENT_INIT,
  NFT_PRIVATEMEDIACONTENT_ERROR,
  NFT_PRIVATEMEDIACONTENT_LOADED,
  NFT_PRIVATEMEDIACONTENT_CREATED,
  NFT_PRIVATEMEDIACONTENT_UPDATED,
  NFT_PRIVATEMEDIACONTENT_DELETED,
} from "./types"

const initialState = () => ({
  list: [],
  form: {
    coverImage: "",
    thumbnail: "",
    mediaContents: [],
    title: "",
    // detail: "",
    category: "",
    brand: "",
    web: "",
    salesMethod: "",
    salesStartedAt: new Date(),
    salesEndedAt: null,
    publishedAt: new Date(),
    paymentDueDateAt: null,
    expiredAt: null,
    isReleaseNow: false,
    isPublishNow: false,
    price: "",
    hasBank: true,
    buyoutPrice: "",
    availablePaymentMethod: [],
    accept: false,
    quantity: "",
    multipleSell: "",
    multipleSellWithSerial: "",
    serialNumber: [],
    story: "",
    royalty: "",
    commission: "",
    secondaryCommission: "",
    purchaseLimit: "",
    benefitsChoice: [],
    status: "",
    officialId: "",
    uuid: "",
    ecdsaR: "",
    ecdsaS: "",
    ecdsaV: "",
    bankError: "",
    walletError: "",
    isRecommend: false,
    isRecommendCategory: false,
    precaution: precautions,
    limitMaxPriceOnSecondarySelling: "",
    limitedSellingConditionRule: "",
    limitedSellingConditions: "",
    limitedSellingConditionRuleKitRooms: "",
    limitedSellingConditionsKitRooms: "",
    limitedSellingConditionRuleNftOwned: "",
    limitedSellingConditionsNftOwned: "",
    limitedSellingConditionExpiredAtNftOwned: null,
    isLimitedCondition: false,
    isLimitedConditionKitRooms: false,
    isLimitedConditionNftOwned: false,
    isNotAutoCreditSettlement: false,
  },
  formNftPrivateMediaContentsDescription: {
    description: "",
  },
  formNftPrivateMediaContent: {
    title: "",
    contentType: "",
    mediaUrl: "",
    startViewableAt: null,
    endViewableAt: null,
  },
  auctionList: [],
  lotteryList: [],
  approve: false,
})

// 価格入力フォームの変換
const convertToPriceForm = (price, currencyMethod) =>
  price === null
    ? ""
    : getCurrencyFormattWithoutSymbol(price, currencyMethod).replace(/,/g, "")

const convertToForm = (data) => {
  const displayCurrency =
    data.nftAvailablePaymentMethod?.length === 1
      ? data.nftAvailablePaymentMethod[0].paymentMethod.displayCurrencey
      : "yen" // Hybrid商品の場合はYEN表記
  // convert to draft object
  let storyObj = {}
  try {
    if (data.story) {
      storyObj = JSON.parse(data.story)
    }
  } catch (_err) {
    console.error(_err)
  }
  let quantity
  let multipleSellWithSerial
  let multipleSell

  if (Array.isArray(data.serialNumber)) {
    if (
      data.quantity > 1 &&
      data.serialNumber.filter((v) => v !== null).length > 0
    ) {
      quantity = "3"
      multipleSellWithSerial = data.quantity.toString()
    } else if (data.quantity > 1) {
      quantity = "2"
      multipleSell = data.quantity.toString()
    } else {
      quantity = "1"
    }
  }

  return {
    ...data,
    story: storyObj,
    category: data.nftCategoryId,
    brand: data.nftBrandId,
    price: convertToPriceForm(data.price, displayCurrency),
    availablePaymentMethod:
      data?.availablePaymentMethod?.map((v) => String(v)) ?? [],
    buyoutPrice: convertToPriceForm(data.buyoutPrice, displayCurrency),
    royalty: String(data.royalty),
    commission: String(data.commission),
    secondaryCommission: String(data.secondaryCommission),
    quantity,
    multipleSellWithSerial,
    multipleSell,
    benefitsChoice: data.benefits.map((benefit) => String(benefit.id)),
    salesStartedAt: new Date(data.salesStartedAt),
    salesEndedAt:
      data.salesEndedAt === null ? null : new Date(data.salesEndedAt),
    publishedAt: new Date(data.publishedAt),
    expiredAt: data.expiredAt === null ? null : new Date(data.expiredAt),
    paymentDueDateAt:
      data.paymentDueDateAt === null || data.paymentDueDateAt === undefined
        ? null
        : new Date(data.paymentDueDateAt),
    serialNumber: data.serialNumber,
    purchaseLimit: String(data.purchaseLimit),
    hasBank: data.hasBank,
    isRecommend: data.recommend,
    isRecommendCategory: data.recommendCategory,
    precaution: data.precaution,
    accept: false,
    limitMaxPriceOnSecondarySelling: convertToPriceForm(
      data.limitMaxPriceOnSecondarySelling,
      displayCurrency
    ),
    limitedSellingConditionRule: data.limitedSellingConditionRule ?? "",
    limitedSellingConditions: data.limitedSellingConditions,
    limitedSellingConditionRuleKitRooms:
      data.limitedSellingConditionRuleKitRooms ?? "",
    limitedSellingConditionsKitRooms: data.limitedSellingConditionsKitRooms,
    limitedSellingConditionRuleNftOwned:
      data.limitedSellingConditionRuleNftOwned ?? "",
    limitedSellingConditionsNftOwned: data.limitedSellingConditionsNftOwned,
    limitedSellingConditionExpiredAtNftOwned:
      data.limitedSellingConditionExpiredAtNftOwned === null
        ? null
        : new Date(data.limitedSellingConditionExpiredAtNftOwned),
    isLimitedCondition: !!(
      data.limitedSellingConditionRule || data.limitedSellingConditions
    ),
    isLimitedConditionKitRooms: !!(
      data.limitedSellingConditionRuleKitRooms ||
      data.limitedSellingConditionsKitRooms
    ),
    isLimitedConditionNftOwned: !!(
      data.limitedSellingConditionRuleNftOwned ||
      data.limitedSellingConditionsNftOwned ||
      data.limitedSellingConditionExpiredAtNftOwned
    ),
    isNotAutoCreditSettlement: !data.autoCreditSettlement,
  }
}

const convertToFormNftPrivateMediaContent = (data) => {
  // convert to draft object
  let descriptionObj = {}
  try {
    descriptionObj = JSON.parse(data.description)
  } catch (_err) {
    console.error(_err)
  }
  return {
    ...data,
    description: descriptionObj,
    startViewableAt:
      data.startViewableAt === null ? null : new Date(data.startViewableAt),
    endViewableAt:
      data.endViewableAt === null ? null : new Date(data.endViewableAt),
  }
}

const convertToListForm = (data) => ({
  id: data.id,
  createdAt: new Date(data.createdAt),
  offerAmount: data.offerAmount,
  offerAmountDouble: data.offerAmountDouble,
  offerAmountDisplay: data.offerAmountDisplay,
  won: data.won,
  isPaid: data.isPaid,
  plusmemberId: data.user?.plusmemberId ?? null,
  userId: data.user.id,
})

const nftManagementReducers = (state = initialState(), action) => {
  switch (action.type) {
    case NFT_MANAGEMENT_LIST_LOADED:
    case AUCTION_LIST_LOADED:
      return {
        ...state,
        list: action.payload.map((data) => convertToForm(data)),
      }
    case NFT_MANAGEMENT_AUCTION_LIST_LOADED:
      return {
        ...state,
        auctionList: action.payload.map((data) => convertToListForm(data)),
      }
    case NFT_MANAGEMENT_LOTTERY_LIST_LOADED:
      return {
        ...state,
        lotteryList: action.payload.map((data) => convertToListForm(data)),
      }
    case NFT_MANAGEMENT_LOADED:
      return {
        ...state,
        form: convertToForm(action.payload),
      }
    case NFT_MANAGEMENT_INIT:
      return {
        ...state,
        form: initialState().form,
      }
    case NFT_PRIVATEMEDIACONTENT_LOADED:
      return {
        ...state,
        formNftPrivateMediaContent: convertToFormNftPrivateMediaContent(
          action.payload
        ),
      }
    case NFT_PRIVATEMEDIACONTENT_INIT:
      return {
        ...state,
        formNftPrivateMediaContent: initialState().formNftPrivateMediaContent,
      }
    case NFT_PRIVATE_MEDIA_CONTENTS_DESCRIPTION_LOADED:
      return {
        ...state,
        formNftPrivateMediaContentsDescription: {
          description: action.payload,
        },
      }
    case NFT_PRIVATE_MEDIA_CONTENTS_DESCRIPTION_UPDATED:
      return {
        ...state,
        formNftPrivateMediaContentsDescription: {
          description: action.payload,
        },
      }
    case NFT_PRIVATEMEDIACONTENT_CREATED:
    case NFT_PRIVATEMEDIACONTENT_UPDATED:
    case NFT_PRIVATEMEDIACONTENT_DELETED:
    case NFT_MANAGEMENT_CREATED:
    case NFT_MANAGEMENT_DUPLICATED:
    case NFT_MANAGEMENT_UPDATED:
    case NFT_MANAGEMENT_DELETED:
    case CHANGE_AUCTION_WINNER_LOADED:
    case TRANSFER_NFT_REQUEST:
    case NFT_AUCTION_CLOSED_LOADED:
      return {
        ...state,
      }
    case APPROVE_LOADED:
      return {
        ...state,
        approve: action.payload,
      }
    case APPROVE_UPDATED:
      return {
        ...state,
        approve: action.payload,
      }
    case NFT_PRIVATEMEDIACONTENT_ERROR:
    case NFT_MANAGEMENT_LIST_ERROR:
    case NFT_MANAGEMENT_ERROR:
    case NFT_MANAGEMENT_LOTTERY_LIST_ERROR:
    case NFT_MANAGEMENT_AUCTION_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default nftManagementReducers
