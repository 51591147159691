import React from "react"
import PropTypes from "prop-types"
import { useField, useFormikContext, ErrorMessage } from "formik"
import { FormControl, makeStyles } from "@material-ui/core"
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import jaLocale from "date-fns/locale/ja"
import format from "date-fns/format"
import TextError from "../TextError/TextError"

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "yyyy年MMM", { locale: this.locale })
  }

  getDatePickerHeaderText(date) {
    return format(date, "MMMd日", { locale: this.locale })
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root .MuiInputLabel-formControl": {
      zIndex: 2,
    },
  },
  filled: {
    "& input": {
      backgroundColor: theme.palette.bg_light,
    },
  },
  standard: {
    "& input": {
      backgroundColor: "inherit",
    },
  },
}))
const DatePickerWrapper = ({
  name,
  disabled,
  label,
  shouldFuture,
  shouldPast = false,
  hasTime = false,
  ...props
}) => {
  const classes = useStyles()
  const [field, meta] = useField(name)
  const { setFieldValue } = useFormikContext()
  const { value } = meta
  const handleDateChange = (date) => {
    setFieldValue(name, date)
  }
  const configDatePicker = {
    ...field,
    ...props,
  }
  const configFormControl = {}
  if (meta && meta.touched && meta.error) {
    configDatePicker.error = true
  }
  const picker = hasTime ? (
    <DateTimePicker
      className={!disabled ? classes.filled : classes.standard}
      // disableToolbar
      variant="inline"
      format="yyyy/MM/dd HH:mm"
      margin="normal"
      label={label}
      value={value}
      minDate={shouldFuture ? new Date() : new Date(0)}
      disabled={disabled}
      onChange={handleDateChange}
    />
  ) : (
    <DatePicker
      className={!disabled ? classes.filled : classes.standard}
      disableToolbar
      variant="inline"
      format="yyyy/MM/dd"
      margin="normal"
      label={label}
      value={value}
      minDate={shouldFuture ? new Date() : new Date(0)}
      maxDate={shouldPast ? new Date() : undefined}
      disabled={disabled}
      onChange={handleDateChange}
    />
  )
  return (
    <FormControl {...configFormControl} className={classes.root}>
      <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
        {picker}
      </MuiPickersUtilsProvider>
      <ErrorMessage component={TextError} name={name} />
    </FormControl>
  )
}
DatePickerWrapper.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  shouldFuture: PropTypes.bool,
  shouldPast: PropTypes.bool,
  hasTime: PropTypes.bool,
}
export default DatePickerWrapper
