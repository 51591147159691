const loadingReducers = (state = {}, action) => {
  const { type } = action
  const matches =
    /(.*)_(REQUEST|LOADED|ERROR|CREATED|POST|COMPLETE|ERRORS)/.exec(type)

  if (!matches) return state

  const [, requestName, requestState] = matches
  if (type === "GET_API_ERRORS" || type === "GET_ERRORS") {
    const allFalseState = Object.keys(state).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {})

    return {
      ...allFalseState,
    }
  }
  return {
    ...state,
    [requestName]: requestState === "REQUEST",
  }
}

export default loadingReducers
