export const CHAIN_IDS = {
  POLYGON: 137,
  ETHEREUM: 1,
  GOERLI: 5,
  SEPOLIA: 11155111,
}

// ref: https://zenn.dev/watson_sei/articles/0bf87f4bb70207
/**
 * metamaskにネットワークを追加するための設定値(window.ethereum.requestで扱える仕様)
 * chainId:はhex
 * ref: https://zenn.dev/watson_sei/articles/0bf87f4bb70207
 */
export const METAMASK_NETWORK_CONFIG = {
  polygon: {
    chainName: "Polygon Mainnet",
    chainId: `0x${CHAIN_IDS.POLYGON.toString(16)}`,
    nativeCurrency: {
      name: "MATIC",
      decimals: 18,
      symbol: "MATIC",
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  ethereum: {
    chainName: "Ethereum Mainnet",
    chainId: `0x${CHAIN_IDS.ETHEREUM.toString(16)}`,
    nativeCurrency: {
      name: "Ether",
      decimals: 18,
      symbol: "ETH",
    },
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
  goerli: {
    chainName: "Goerli Testnet",
    chainId: `0x${CHAIN_IDS.GOERLI.toString(16)}`,
    nativeCurrency: {
      name: "GoerliEther",
      decimals: 18,
      symbol: "GoerliETH",
    },
    rpcUrls: ["https://goerli.infura.io/v3/"],
    blockExplorerUrls: ["https://goerli.etherscan.io/"],
  },
  sepolia: {
    chainName: "Sepolia Testnet",
    chainId: `0x${CHAIN_IDS.SEPOLIA.toString(16)}`,
    nativeCurrency: {
      name: "SepoliaEther",
      decimals: 18,
      symbol: "SepoliaETH",
    },
    rpcUrls: ["https://sepolia.infura.io/v3/"],
    blockExplorerUrls: ["https://sepolia.etherscan.io/"],
  },
}
