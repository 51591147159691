import {
  TICKET_MANAGEMENT_TOUR_LIST_LOADED,
  TICKET_MANAGEMENT_TOUR_LIST_ERROR,
  TICKET_MANAGEMENT_CONCERT_LIST_LOADED,
  TICKET_MANAGEMENT_CONCERT_LIST_ERROR,
  TICKET_MANAGEMENT_LOADED,
  TICKET_MANAGEMENT_UPDATED,
  TICKET_MANAGEMENT_ERROR,
} from "./types"

const initialState = () => ({
  list: [],
  form: {
    artistName: "",
    tourTitle: "",
    tourShortTitle: "",
    concertTitle: "",
    concertPlace: "",
    concertDate: null,
    concertOpenTime: null,
    concertStartTime: null,
    concertEndTime: null,
    listingStartedAt: null,
    seatType: "",
    story: "",
    coverImage: "",
    precaution: "",
    royalty: "",
    commission: "",
    secondaryCommission: "",
    isCanceled: false,
    mediaContents: [],
  },
})

const convertToForm = (data) => ({
  ...data,
  concertDate: data.concertDate ? new Date(data.concertDate) : null,
  concertOpenTime: data.concertOpenTime ? new Date(data.concertOpenTime) : null,
  concertStartTime: data.concertStartTime
    ? new Date(data.concertStartTime)
    : null,
  concertEndTime: data.concertEndTime ? new Date(data.concertEndTime) : null,
  listingStartedAt: data.listingStartedAt
    ? new Date(data.listingStartedAt)
    : null,
  story: data.story ? JSON.parse(data.story) : {},
})

const ticketManagementReducers = (state = initialState(), action) => {
  switch (action.type) {
    case TICKET_MANAGEMENT_TOUR_LIST_LOADED:
      return {
        ...state,
        list: action.payload,
      }
    case TICKET_MANAGEMENT_CONCERT_LIST_LOADED:
      return {
        ...state,
        list: action.payload,
      }
    case TICKET_MANAGEMENT_LOADED:
      return {
        ...state,
        form: convertToForm(action.payload),
      }
    case TICKET_MANAGEMENT_UPDATED:
      return {
        ...state,
      }
    case TICKET_MANAGEMENT_TOUR_LIST_ERROR:
    case TICKET_MANAGEMENT_CONCERT_LIST_ERROR:
    case TICKET_MANAGEMENT_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default ticketManagementReducers
