export const USER_BANK_LIST_REQUEST = "USER_BANK_LIST_REQUEST"
export const USER_BANK_LIST_LOADED = "USER_BANK_LIST_LOADED"
export const USER_BANK_LIST_ERROR = "USER_BANK_LIST_ERROR"
export const USER_BANK_CREATED = "USER_BANK_CREATED"
export const USER_BANK_DELETED = "USER_BANK_DELETED"
export const USER_BANK_INIT = "USER_BANK_INIT"

export const USER_CREDIT_LIST_REQUEST = "USER_CREDIT_LIST_REQUEST"
export const USER_CREDIT_LIST_LOADED = "USER_CREDIT_LIST_LOADED"
export const USER_CREDIT_LIST_ERROR = "USER_CREDIT_LIST_ERROR"
export const USER_CREDIT_CREATED = "USER_CREDIT_CREATED"
export const USER_CREDIT_DELETED = "USER_CREDIT_DELETED"
export const USER_CREDIT_INIT = "USER_CREDIT_INIT"

export const USER_WALLET_LIST_REQUEST = "USER_WALLET_LIST_REQUEST"
export const USER_WALLET_LIST_LOADED = "USER_WALLET_LIST_LOADED"
export const USER_WALLET_LIST_ERROR = "USER_WALLET_LIST_ERROR"
export const USER_WALLET_CREATED = "USER_WALLET_CREATED"
export const USER_WALLET_DELETED = "USER_WALLET_DELETED"
export const USER_WALLET_INIT = "USER_WALLET_INIT"

export const OFFICIAL_BANK_LIST_REQUEST = "OFFICIAL_BANK_LIST_REQUEST"
export const OFFICIAL_BANK_LIST_LOADED = "OFFICIAL_BANK_LIST_LOADED"
export const OFFICIAL_BANK_LIST_ERROR = "OFFICIAL_BANK_LIST_ERROR"
export const OFFICIAL_BANK_CREATED = "OFFICIAL_BANK_CREATED"
export const OFFICIAL_BANK_DELETED = "OFFICIAL_BANK_DELETED"
export const OFFICIAL_BANK_INIT = "OFFICIAL_BANK_INIT"

export const OFFICIAL_WALLET_LIST_REQUEST = "OFFICIAL_WALLET_LIST_REQUEST"
export const OFFICIAL_WALLET_LIST_LOADED = "OFFICIAL_WALLET_LIST_LOADED"
export const OFFICIAL_WALLET_LIST_ERROR = "OFFICIAL_WALLET_LIST_ERROR"
export const OFFICIAL_WALLET_CREATED = "OFFICIAL_WALLET_CREATED"
export const OFFICIAL_WALLET_DELETED = "OFFICIAL_WALLET_DELETED"
export const OFFICIAL_WALLET_INIT = "OFFICIAL_WALLET_INIT"
