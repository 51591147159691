import { authApi } from "../api"
import { NOTIFICATION_LIST_LOADED, NOTIFICATION_LIST_CLEAR } from "./types"

const getNotificationList =
  (getMessageType, setMessageType, nftCategoryId, page = 1) =>
  (dispatch) => {
    if (page === 1) {
      dispatch({
        type: NOTIFICATION_LIST_CLEAR,
        messageType: setMessageType,
      })
    }
    return authApi
      .get("/breakingnews", {
        params: {
          messageType: getMessageType,
          nftCategoryId,
          page,
        },
      })
      .then((res) => {
        dispatch({
          type: NOTIFICATION_LIST_LOADED,
          payload: res.data,
          messageType: setMessageType,
        })
      })
  }

// お知らせ取得（TOPヘッダー用）
export const getNotificationTopNewsList = (messageType, nftCategoryId) =>
  getNotificationList(messageType, "topnews", nftCategoryId)

// お知らせ取得
export const getNotificationNewsListAdd = (messageType, nftCategoryId, page) =>
  getNotificationList(messageType, messageType, nftCategoryId, page)
