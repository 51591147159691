import React from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Icon,
  makeStyles,
  Box,
} from "@material-ui/core"
import arwIcon from "../../assets/img/menu_icon_right.svg"

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.main,
  },
  listItem: {
    color: theme.palette.main,
    "&:hover": {
      backgroundColor: theme.palette.bg_light,
    },
  },
}))
// navigation-> routes.jsで作ったURLリンクリストをマッピングし、サイドメニュー一覧を返すfc。
// 各セクションごとに < Divider /> を追加
const HeaderSideMenuGenerator = React.memo(({ menuList = {}, onClose }) => {
  const classes = useStyles()
  return (
    menuList &&
    Object.keys(menuList).map((e) => (
      <Box key={e} style={{ border: "1px solid red" }}>
        {Object.keys(menuList[e]).map((a, i) => (
          <List key={a} component="nav">
            {Object.keys(menuList[e]).length - 1 === i ? (
              <>
                <NavLink
                  to={menuList[e][a].path}
                  className={classes.listItem}
                  key={a}
                  activeClassName={classes.active}
                  onClick={() => onClose()}
                >
                  <ListItem button>
                    <ListItemIcon>
                      {typeof menuList[e][a].icon === "string" ? (
                        <Icon>{menuList[e][a].icon}</Icon>
                      ) : (
                        <>{menuList[e][a].icon}</>
                      )}
                    </ListItemIcon>
                    <ListItemText primary={menuList[e][a].label} />
                    <Box className="arw">
                      <img src={arwIcon} alt="" />
                    </Box>
                  </ListItem>
                </NavLink>
                <Divider />
              </>
            ) : (
              <NavLink
                to={menuList[e][a].path}
                key={a}
                activeClassName={classes.active}
                onClick={() => onClose()}
                className={classes.listItem}
              >
                <ListItem button>
                  <ListItemIcon>
                    {typeof menuList[e][a].icon === "string" ? (
                      <Icon>{menuList[e][a].icon}</Icon>
                    ) : (
                      <>{menuList[e][a].icon}</>
                    )}
                  </ListItemIcon>
                  <ListItemText primary={menuList[e][a].label} />
                  <Box className="arw">
                    <img src={arwIcon} alt="" />
                  </Box>
                </ListItem>
              </NavLink>
            )}
          </List>
        ))}
      </Box>
    ))
  )
})
HeaderSideMenuGenerator.propTypes = {
  menuList: PropTypes.objectOf(PropTypes.objectOf(PropTypes.object)),
  onClose: PropTypes.func,
}
export default HeaderSideMenuGenerator
