import PropTypes from "prop-types"
import { Box, makeStyles } from "@material-ui/core"
import React from "react"
import FormControl from "../FormControl"

const useStyles = makeStyles(() => ({
  creditContainer: {
    maxWidth: 400,
    "& .Mui-error": {
      "&:last-child": {
        marginBottom: "0",
      },
    },
  },
}))

const Tds2CredentialForm = ({
  tds2CredentialPhoneType,
  disabled,
  variant,
  countryCodeList,
}) => {
  const classes = useStyles()
  const options = {}
  countryCodeList.forEach((data) => {
    options[data.telNum] = data.name
  })
  return (
    <Box className={classes.creditContainer}>
      <Box my={2}>
        <FormControl
          control="radio"
          name="phoneType"
          legend=""
          options={tds2CredentialPhoneType}
          disabled={disabled}
          row
        />
      </Box>
      <Box my={2}>
        <FormControl
          control="select"
          name="phoneCC"
          label="国コード"
          options={options}
          variant={variant}
          disabled={disabled}
        />
      </Box>
      <Box my={2}>
        <FormControl
          control="textfield"
          name="phoneNumber"
          label="電話番号"
          variant={variant}
          disabled={disabled}
        />
      </Box>
    </Box>
  )
}

Tds2CredentialForm.propTypes = {
  tds2CredentialPhoneType: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  countryCodeList: PropTypes.arrayOf(PropTypes.object),
}

export default Tds2CredentialForm
