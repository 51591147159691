/**
 * カテゴリ一覧（NFT,プレのタブ絞り込み用）
 */
export const categoryTabList = [
  { name: "すべて", checked: false },
  { name: "アート", checked: false },
  { name: "ライブチケット", checked: false },
  { name: "カテゴリ１", checked: false },
  { name: "カテゴリ２", checked: false },
  { name: "カテゴリ３", checked: false },
  { name: "カテゴリ４", checked: false },
  { name: "カテゴリ５", checked: false },
]
/**
 * フィルター
 */
export const sort = [
  { name: "新着順", value: "latest" },
  { name: "いいね数順", value: "liked" },
  { name: "金額が安い順", value: "asc" },
  { name: "金額が高い順", value: "des" },
]
export const filter = {
  detail: {
    name: "詳細検索",
    values: [
      { name: "商品名(部分一致)", value: "product_name", selected: false },
    ],
  },
  date: {
    name: "発売日",
    values: [{ name: "発売日", value: "date", selected: false }],
  },
  order: {
    name: "並び替え",
    values: [
      { name: "人気順", value: "view" },
      { name: "新着順", value: "new" },
      { name: "金額が安い順", value: "price_asc" },
      { name: "金額が高い順", value: "price_desc" },
    ],
  },
  salesMethod: {
    name: "販売方式",
    values: [
      { name: "定価販売", value: "list", selected: false },
      { name: "抽選販売", value: "lottery", selected: false },
      { name: "オークション", value: "auction", selected: false },
    ],
  },
  currencyMethod: {
    name: "販売通貨",
    values: [{ name: "YEN", value: "yen", selected: false }],
  },
  status: {
    name: "一次/二次流通",
    values: [
      { name: "一次流通", value: "primary_selling", selected: false },
      { name: "二次流通", value: "secondary_selling", selected: false },
    ],
  },
  salesStatus: {
    name: "販売",
    values: [
      { name: "販売開始前", value: "before", selected: false },
      { name: "販売中", value: "onsale", selected: false },
      { name: "販売終了", value: "ended", selected: false },
    ],
  },
}
