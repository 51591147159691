import { snake2camel } from "../../utils/helper"

export default class ApiErrors {
  errors = {}

  constructor(errors = [], singleError) {
    // convert errors for Fromik
    this.errors = Object.fromEntries(
      errors
        .map((error) => {
          if (!error.param) return ["_", error.param]
          switch (error.message) {
            case "email already in use":
              return [error.param, "メールアドレスは既に使われています。"]
            default:
              return [error.param, error.message]
          }
        })
        .map(([param, message]) => [snake2camel(param), message])
    )
    if (singleError) {
      switch (singleError.message) {
        case "This bank do not exist.":
          this.errors.bankName =
            "入力した銀行名が存在しません。もう一度ご確認の上、入力してください。"
          break
        case "This branch do not exist.":
          this.errors.branchName =
            "入力した支店名が存在しません。もう一度ご確認の上、入力してください。"
          break
        case "price is more higher than min bid.":
          this.errors.price = "入札価格は入札単位以上の金額を指定してください。"
          break
        case "you are max amount holder.":
          this.errors.price = "最高入札者はあなたになります。"
          break
        case "already applied.":
          this.errors.purchase = "こちらの商品は既に応募済です。"
          break
        case "Shortage of quantity.":
          this.errors.quantity = "在庫がありません。"
          break
        case "Get caught in purchase limit.":
          this.errors.quantity = "購入可能個数を超えてます。"
          break
        case "No official wallet account has been set up.":
        case "No user wallet account has been set up.":
          this.errors.walletError =
            "出品する前に決済情報にてウォレット登録を行ってください。"
          break
        case "No official bank account has been set up.":
        case "No user bank account has been set up.":
          this.errors.bankError =
            "出品する前に決済情報にて銀行口座登録を行ってください。"
          break
        default:
          // eslint-disable-next-line no-underscore-dangle
          this.errors.__error = singleError.message
      }
    }
  }
}
