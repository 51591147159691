export const TICKET_MANAGEMENT_TOUR_LIST_LOADED =
  "TICKET_MANAGEMENT_TOUR_LIST_LOADED"
export const TICKET_MANAGEMENT_TOUR_LIST_ERROR =
  "TICKET_MANAGEMENT_TOUR_LIST_ERROR"

export const TICKET_MANAGEMENT_CONCERT_LIST_LOADED =
  "TICKET_MANAGEMENT_CONCERT_LIST_LOADED"
export const TICKET_MANAGEMENT_CONCERT_LIST_ERROR =
  "TICKET_MANAGEMENT_CONCERT_LIST_ERROR"

export const TICKET_MANAGEMENT_REQUEST = "TICKET_MANAGEMENT_REQUEST"
export const TICKET_MANAGEMENT_LOADED = "TICKET_MANAGEMENT_LOADED"
export const TICKET_MANAGEMENT_UPDATED = "TICKET_MANAGEMENT_UPDATED"
export const TICKET_MANAGEMENT_ERROR = "TICKET_MANAGEMENT_ERROR"
