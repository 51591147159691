import {
  BRAND_LIST_LOADED,
  CATEGORY_LIST_LOADED,
  CATEGORY_SELECT_LIST_LOADED,
  CATEGORY_LIST_ERROR,
  BRAND_LIST_ERROR,
  CATEGORY_DELETED,
  BRAND_DELETED,
  CATEGORY_UPDATED,
  BRAND_UPDATED,
  CATEGORY_CREATED,
  BRAND_CREATED,
  BRAND_INIT,
  CATEGORY_INIT,
  BANNER_LIST_LOADED,
  BANNER_INIT,
  BANNER_CREATED,
  BANNER_UPDATED,
  BANNER_DELETED,
  BANNER_LIST_ERROR,
} from "./types"

const initialState = () => ({
  categoryList: [],
  categorySelectList: [],
  brandList: [],
  bannerList: [],
  brandForm: {},
  categoryForm: {},
  bannerForm: {},
  form: {
    nickname: "",
    lastName: "",
    firstName: "",
    lastNameKana: "",
    firstNameKana: "",
    birthDate: "",
    email: "",
    emailConfirmation: "",
    password: "",
    passwordConfirmation: "",
  },
})

const generalAccountReducers = (state = initialState(), action) => {
  switch (action.type) {
    case BRAND_LIST_LOADED:
      return {
        ...state,
        brandList: action.payload,
      }
    case CATEGORY_LIST_LOADED:
      return {
        ...state,
        categoryList: action.payload,
      }
    case CATEGORY_SELECT_LIST_LOADED:
      return {
        ...state,
        categorySelectList: action.payload,
      }
    case BANNER_LIST_LOADED:
      return {
        ...state,
        bannerList: action.payload,
      }
    case BRAND_INIT:
      return {
        ...state,
        brandForm: initialState().brandForm,
      }
    case CATEGORY_INIT:
      return {
        ...state,
        categoryForm: initialState().categoryForm,
      }
    case BANNER_INIT:
      return {
        ...state,
        bannerForm: initialState().bannerForm,
      }
    case CATEGORY_CREATED:
    case BRAND_CREATED:
    case BANNER_CREATED:
    case CATEGORY_UPDATED:
    case BRAND_UPDATED:
    case BANNER_UPDATED:
    case CATEGORY_DELETED:
    case BRAND_DELETED:
    case BANNER_DELETED:
      return {
        ...state,
      }
    case CATEGORY_LIST_ERROR:
    case BRAND_LIST_ERROR:
    case BANNER_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default generalAccountReducers
