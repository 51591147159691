import { combineReducers } from "redux"
import authReducers from "./auth/reducers"
import errorReducers from "./error/reducers"
import paginationReducers from "./pagination/reducers"
import notificationReducers from "./notification/reducers"
import notificationNewsManagementReducers from "./pages/admin/notification_news_management/reducers"
import generalAccountReducers from "./pages/admin/general_account/reducers"
import officialAccountReducers from "./pages/admin/official_account/reducers"
import officialAccountUserReducers from "./pages/admin/official_account/user/reducers"
import adminAccountReducers from "./pages/admin/admin_account/reducers"
import adminOfficialAccountReducers from "./pages/nft_management_primary_listing/super/official_account/reducers"
import nftManagementPrimaryListingReducers from "./pages/nft_management_primary_listing/nft_management/reducers"
import nftMarketCategoryBrandReducers from "./pages/nft_management_primary_listing/nft_market/reducers"
import ticketManagementReducers from "./pages/admin/ticket_management/reducers"
import benefitManagementReducers from "./pages/admin/benefit_management/reducers"
import nftMarketReducers from "./pages/admin/nft_market/reducers"
import listingBenefitReducers from "./pages/nft_management_primary_listing/benefits/reducers"
import settingsReducers from "./pages/admin/settings/reducers"
import searchNftReducers from "./pages/search_nft/reducers"
import purchaseReducers from "./purchase/reducers"
import collectionReducers from "./pages/nft_management_secondary_listing/collection/reducers"
import secondaryListingnftManagementReducers from "./pages/nft_management_secondary_listing/nft_management/reducers"
import likeReducers from "./like/reducers"
import favoriteReducers from "./favorite/reducers"
import offerReducers from "./offer/reducers"
import salesManagementReducers from "./pages/admin/sales_management/reducers"
import paymentManagementReducers from "./pages/admin/payment_management/reducers"
import bankManagementReducers from "./pages/admin/bank_management/reducers"
import useBenefitManagementReducers from "./pages/admin/use_benefit_management/reducers"
import sellerMyPageReducers from "./sellerMypage/reducers"
import contactReducers from "./pages/contact/reducers"
import masterDataReducers from "./master/reducers"
import bankTransferManagementReducers from "./pages/admin/bank_transfer_management/reducers"
import loadingReducers from "./loading/reducers"

export default combineReducers({
  auth: authReducers,
  error: errorReducers,
  pagination: paginationReducers,
  notification: notificationReducers,
  notificationNewsManagement: notificationNewsManagementReducers,
  generalAccount: generalAccountReducers,
  officialAccount: officialAccountReducers,
  officialAccountUser: officialAccountUserReducers,
  adminAccount: adminAccountReducers,
  adminOfficialAccount: adminOfficialAccountReducers,
  nftManagementPrimaryListing: nftManagementPrimaryListingReducers,
  nftMarketCategoryBrand: nftMarketCategoryBrandReducers,
  ticketManagement: ticketManagementReducers,
  benefitManagement: benefitManagementReducers,
  nftMarket: nftMarketReducers,
  listingBenefit: listingBenefitReducers,
  settings: settingsReducers,
  searchNft: searchNftReducers,
  purchase: purchaseReducers,
  collection: collectionReducers,
  secondaryListingnftManagement: secondaryListingnftManagementReducers,
  like: likeReducers,
  favorite: favoriteReducers,
  offer: offerReducers,
  salesManagement: salesManagementReducers,
  paymentManagement: paymentManagementReducers,
  bankManagement: bankManagementReducers,
  useBenefitManagement: useBenefitManagementReducers,
  sellerMyPage: sellerMyPageReducers,
  contact: contactReducers,
  masterData: masterDataReducers,
  bankTransferManagement: bankTransferManagementReducers,
  loading: loadingReducers,
})
