import React from "react"
import PropTypes from "prop-types"
import { Box, Grid, makeStyles, Icon, IconButton } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#F7F7F7",
  },
  container: {
    background: "#F7F7F7",
    position: "relative",
    overflow: "auto",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.bg_base,
  },
  header: {
    height: "80px",
    padding: "20px",
    // backgroundColor: theme.palette.main,
    fontSize: "26px",
    lineHeight: "1.2",
    color: "#000",
    "& .MuiIcon-root": {
      "&:empty": {
        display: "none",
      },
    },
  },
}))

const NotificationNewsLayout = ({
  onClose,
  icon,
  title,
  children,
  widthSize,
}) => {
  const classes = useStyles()
  return (
    <Grid item component={Box} width="100%" className={classes.root}>
      <Box
        width={widthSize}
        height="100%"
        mx="auto"
        className={classes.container}
      >
        <Box width="100%" className={classes.header}>
          <IconButton
            className={classes.closeButton}
            onClick={() => onClose(false)}
          >
            <Icon>close</Icon>
          </IconButton>
          <Box display="flex" alignItems="center" fontWeight="fontWeightBold">
            <Icon>{icon}</Icon>
            {title}
          </Box>
        </Box>
        <Box p={1}>{children}</Box>
      </Box>
    </Grid>
  )
}

NotificationNewsLayout.propTypes = {
  onClose: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  widthSize: PropTypes.string,
}

export default NotificationNewsLayout
