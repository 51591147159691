import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import { Grid, Icon, Box, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { useSelector } from "react-redux"
import Button from "../Button/Button"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    padding: "14px 40px",
    marginBottom: "60px",
    fontSize: "14px",
    lineHeight: "1.1",
    textAlign: "center",
    overflow: "hidden",
    "@media (min-width: 1400px)": {
      left: "50%",
      right: "50%",
      width: "100vw",
      margin: "0 -50vw 60px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      marginTop: "-10px",
      marginBottom: "30px",
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      lineHeight: "1.3",
    },
    "& button": {
      background: "transparent",
      border: "none",
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      fontStyle: "italic",
      "& .MuiButton-label": {
        marginTop: "-1px",
        fontSize: "18px",
        "& .MuiButton-startIcon": {
          "& .material-icons": {
            width: "7px",
            height: "7px",
            fontSize: "0",
            background: "#8F00FF",
            borderRadius: "50%",
          },
        },
      },
    },
    "& .info-area": {
      display: "flex",
      maxWidth: "1400px",
      margin: "0 auto",
      alignItems: "center",
      "& .info-t": {
        marginRight: "26px",
        fontFamily: "futura-pt, sans-serif",
        fontWeight: "600",
        fontStyle: "italic",
      },
      "& .info-time": {
        marginRight: "18px",
        color: "#9D9D9D",
      },
      "& .info-detail": {
        display: "box",
        alignItems: "center",
        WebkitLineClamp: "1",
        MozLineClamp: "1",
        lineClamp: "1",
        WebkitBoxOrient: "vertical",
        MozBoxOrient: "vertical",
        boxOrient: "vertical",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        maxWidth: "548px",
        "& > .MuiBox-root": {
          "& p": {
            display: "inline",
            lineHeight: "2",
            fontSize: "14px !important",
            fontWeight: "normal !important",
            textAlign: "left !important",
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px !important",
            },
            "& *": {
              fontSize: "14px !important",
              fontWeight: "normal !important",
              [theme.breakpoints.down("sm")]: {
                fontSize: "12px !important",
              },
            },
          },
          "& img": {
            display: "none",
          },
        },
        "& + .MuiButton-root": {
          padding: "0",
          marginLeft: "40px",
          [theme.breakpoints.down("sm")]: {
            marginLeft: "26px",
          },
          "& .MuiButton-label": {
            fontSize: "14px",
            whiteSpace: "nowrap",
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          },
        },
      },
    },
  },
  admin: {
    backgroundColor: theme.palette.main,
    color: theme.palette.bg_base,
  },
  generalOfficial: {
    backgroundColor: "#FAFAFA",
    color: theme.palette.text_dark,
  },
}))

const HeaderRibbon = ({ type, handleToggleNotificationDrawer }) => {
  const classes = useStyles()
  const isTopPage = useLocation().pathname === "/"
  const data = useSelector((state) => state.notification.topnews.list[0])

  const HeaderRibbonType = () => {
    switch (type) {
      case "super":
        return (
          <Box mt={4}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={clsx(classes.admin, classes.root)}
            >
              <Icon>error_outline</Icon>
              運営アカウントでログインしています
            </Box>
          </Box>
        )
      default:
        return (
          <>
            {isTopPage && (
              <Box mt={4}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={clsx(classes.generalOfficial, classes.root)}
                >
                  <Box className="info-area">
                    <Box className="info-t">Info</Box>
                    <Box className="info-time">
                      {data?.publishedAt &&
                        new Date(data?.publishedAt).toLocaleDateString("ja-JP")}
                    </Box>
                    <Box className="info-detail">
                      <Box dangerouslySetInnerHTML={data?.descriptionHTML} />
                    </Box>
                    <Button
                      text="VIEW ALL"
                      onClick={() => handleToggleNotificationDrawer(true)}
                      icon={<Icon>chevron_right</Icon>}
                    />
                  </Box>
                </Grid>
              </Box>
            )}
          </>
        )
    }
  }

  return <HeaderRibbonType />
}

HeaderRibbon.propTypes = {
  type: PropTypes.string,
  handleToggleNotificationDrawer: PropTypes.func,
}

export default HeaderRibbon
