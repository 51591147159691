import Roles from "../config/Roles"
import views from "./views"
import {
  NFT_MARKET_PATH,
  NFT_MARKET_LIST_PATH,
  MARKET_ITEM_PATH,
  PURCHASE_PATH,
  PURCHASE_COMPLETE_PATH,
  COLLECTION_PATH,
  MY_PAGE_PATH,
  MY_PAGE_EDIT_PATH,
  SECONDARY_LISTING_PATH,
  EDIT_SECONDARY_LISTING_PATH,
  NFT_MANAGEMENT_PATH,
  NFT_MANAGEMENT_OFFICIAL_PATH,
  CREATE_NEW_NFT_PATH,
  DUPLICATE_NFT_PATH,
  NFT_MANAGEMENT_ITEM_PATH,
  EDIT_NFT_PATH,
  TICKET_MANAGEMENT_PATH,
  BENEFITS_MANAGEMENT_ACCOUNT_SELECT_PATH,
  USE_BENEFITS_MANAGEMENT_PATH,
  BENEFITS_MANAGEMENT_PATH,
  BENEFITS_MANAGEMENT_OFFICIAL_PATH,
  BENEFITS_PATH,
  CREATE_NEW_BENEFIT_PATH,
  SALES_MANAGEMENT_PATH,
  SALES_OFFICIAL_MANAGEMENT_PATH,
  TRADING_HISTORY_PATH,
  ACCOUNT_MANAGEMENT_PATH,
  BANK_CREDIT_INFO_PATH,
  USER_MANAGEMENT_PATH,
  CREATE_NEW_USER_PATH,
  EDIT_USER_PATH,
  ADMIN_GENERAL_ACCOUNT_MANAGEMENT_PATH,
  ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT_PATH,
  CREATE_NEW_OFFICIAL_PATH,
  ADMIN_NFT_MARKET_MANAGEMENT_PATH,
  ADMIN_ACCOUNT_MANAGEMENT_PATH,
  CREATE_NEW_ADMIN_PATH,
  EDIT_ADMIN_PATH,
  NOTIFICATION_NEWS_MANAGEMANT_PATH,
  CREATE_NEW_NOTIFICATION_NEWS_PATH,
  EDIT_NOTIFICATION_NEWS_PATH,
  LOG_IN_PATH,
  LOGIN_BY_TID_PATH,
  SIGN_UP_PATH,
  RESET_PASSWORD_PATH,
  FORGOT_PASSWORD_PATH,
  TERMS_PATH,
  SNS_CHECK_PATH,
  COMPANY_PATH,
  FLOW_PATH,
  ABOUT_MARK_PATH,
  USAGE_GUIDE_PATH,
  HELP_PATH,
  ABOUT_RETIRNS_PATH,
  FAQ_PATH,
  AUCTION_PAYMENT_PATH,
  PRIVACY_POLICY_PATH,
  SCTA_PATH,
  ETH_PAYMANT_PATH,
  NOT_FOUND_PATH,
  SYSTEM_ERROR_PATH,
  SETTINGS_PATH,
  SYSTEM_MANAGEMENT_PATH,
  MAINTENANCE_PATH,
  CONTACT_PATH,
  BANK_TRANSFERE_HISTORY_PATH,
  LINK_BANK_PATH,
  PAYMENT_MANAGEMENT_PATH,
  PAYMENT_OFFICIAL_MANAGEMENT_PATH,
} from "./constatnsPaths"
import BenifitIcon from "../assets/img/menu_icon_benifit.svg"
import CheckIcon from "../assets/img/menu_icon_checklist.svg"
import EditIcon from "../assets/img/menu_icon_edit.svg"
import HistoryIcon from "../assets/img/menu_icon_history.svg"
import MyIcon from "../assets/img/menu_icon_mypage.svg"
import ItemIcon from "../assets/img/menu_icon_nft.svg"
import SetIcon from "../assets/img/menu_icon_settings.svg"
import walletIcon from "../assets/img/menu_icon_wallet.svg"

export const NFT_MARKET = {
  component: views.NFTMarket,
  label: "NFTマーケット",
  path: NFT_MARKET_PATH,
  icon: "store",
  isPrivate: false,
  exact: true,
}
export const NFT_MARKET_LIST = {
  component: views.NFTMarketList,
  label: "NFTマーケット一覧",
  path: NFT_MARKET_LIST_PATH,
  icon: "store",
  isPrivate: false,
  exact: true,
}
export const MARKET_ITEM = {
  component: views.MarketItem,
  label: "マーケット詳細",
  path: `${MARKET_ITEM_PATH}/:id`, // 動的ページ
  icon: null,
  isPrivate: false,
  exact: true,
}
export const MARKET_OWN_ITEM = {
  component: views.MarketItem,
  label: "アイテム一覧用マーケット詳細",
  path: `${MARKET_ITEM_PATH}/own/:id`, // 動的ページ
  icon: null,
  isPrivate: true,
  exact: true,
}
export const PURCHASE = {
  component: views.Purchase,
  label: "購入",
  path: `${PURCHASE_PATH}/:id`, // 動的ページ
  icon: null,
  isPrivate: true,
  exact: true,
  permission: [Roles.GENERAL],
}
export const PURCHASE_COMPLETE = {
  component: views.PurchaseComplete,
  label: "購入完了",
  path: `${PURCHASE_COMPLETE_PATH}/:id`, // 動的ページ
  isPrivate: true,
  exact: true,
  icon: null,
}
export const COLLECTION = {
  component: views.Collection,
  label: "アイテム一覧・二次出品",
  path: COLLECTION_PATH,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
  permission: [Roles.GENERAL],
}
export const COLLECTION_OWN = {
  component: views.Collection,
  label: "アイテム一覧 保有中",
  path: `${COLLECTION_PATH}/own`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const COLLECTION_SELLING = {
  component: views.Collection,
  label: "アイテム一覧 出品中",
  path: `${COLLECTION_PATH}/selling`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const COLLECTION_DRAFT = {
  component: views.Collection,
  label: "アイテム一覧 出品中（下書き）",
  path: `${COLLECTION_PATH}/draft`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const COLLECTION_CLOSED = {
  component: views.Collection,
  label: "アイテム一覧 出品終了",
  path: `${COLLECTION_PATH}/closed`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const COLLECTION_SOLD = {
  component: views.Collection,
  label: "アイテム一覧 販売済み",
  path: `${COLLECTION_PATH}/sold`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const COLLECTION_OFFER = {
  component: views.Collection,
  label: "アイテム一覧 オファー",
  path: `${COLLECTION_PATH}/offer`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const COLLECTION_LOTTERY = {
  component: views.Collection,
  label: "アイテム一覧 抽選応募中",
  path: `${COLLECTION_PATH}/lottery`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const COLLECTION_AUCTION = {
  component: views.Collection,
  label: "アイテム一覧 オークション",
  path: `${COLLECTION_PATH}/auction`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const COLLECTION_AUCTION_ITEM = {
  component: views.MarketItem,
  label: "アイテム一覧用オークション詳細",
  path: `${MARKET_ITEM_PATH}/auction/:id`, // 動的ページ
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const COLLECTION_FAVORITE = {
  component: views.Collection,
  label: "アイテム一覧 お気に入り",
  path: `${COLLECTION_PATH}/favorite`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const COLLECTION_LIKE = {
  component: views.Collection,
  label: "アイテム一覧 いいね",
  path: `${COLLECTION_PATH}/like`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const MY_PAGE = {
  component: views.Mypage,
  label: "マイページ",
  path: MY_PAGE_PATH,
  icon: <img src={MyIcon} alt="" />,
  isPrivate: true,
  exact: true,
  permission: [Roles.GENERAL, Roles.OFFICIAL],
}
export const MY_PAGE_EDIT = {
  component: views.MypageEdit,
  label: "マイページ編集",
  path: MY_PAGE_EDIT_PATH,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const SELLER_OFFICIAL_MY_PAGE = {
  component: views.SellerMypage,
  label: "出品者オフィシャルマイページ",
  path: `${MY_PAGE_PATH}/official/:id`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: false,
  exact: true,
}
export const SELLER_OFFICIAL_MY_PAGE_SELLING = {
  component: views.SellerMypage,
  label: "出品者オフィシャルマイページ",
  path: `${MY_PAGE_PATH}/official/:id/selling`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: false,
  exact: true,
}
export const SELLER_OFFICIAL_MY_PAGE_SELLED = {
  component: views.SellerMypage,
  label: "出品者オフィシャルマイページ",
  path: `${MY_PAGE_PATH}/official/:id/sold`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: false,
  exact: true,
}
export const SELLER_USER_MY_PAGE = {
  component: views.SellerMypage,
  label: "出品者一般ユーザーマイページ",
  path: `${MY_PAGE_PATH}/user/:id`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: false,
  exact: true,
}
export const SELLER_USER_MY_PAGE_SELLING = {
  component: views.SellerMypage,
  label: "出品者一般ユーザーマイページ",
  path: `${MY_PAGE_PATH}/user/:id/selling`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: false,
  exact: true,
}
export const SELLER_USER_MY_PAGE_SELLED = {
  component: views.SellerMypage,
  label: "出品者一般ユーザーマイページ",
  path: `${MY_PAGE_PATH}/user/:id/sold`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: false,
  exact: true,
}
export const SELLER_USER_MY_PAGE_OWN = {
  component: views.SellerMypage,
  label: "出品者一般ユーザーマイページ",
  path: `${MY_PAGE_PATH}/user/:id/own`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: false,
  exact: true,
}
// export const SELL = {
//   component: views.NFTSell,
//   label: "出品",
//   path: NFT_SELL_PATH,
//   icon: "unarchive",
//   isPrivate: true,
//   exact: true,
// }
// export const NFT_SELL = {
//   component: views.NFTSell,
//   label: "アイテム一覧出品リスト",
//   path: NFT_SELL_PATH,
//   icon: "star",
//   isPrivate: true,
//   exact: true,
// }
export const SECONDARY_LISTING = {
  component: views.SellFromCollection,
  label: "二次出品",
  path: `${SECONDARY_LISTING_PATH}/:id`, // 動的ページ
  icon: null,
  isPrivate: true,
  exact: true,
}

export const EDIT_SECONDARY_LISTING = {
  component: views.SellFromCollection,
  label: "二次出品編集",
  path: `${EDIT_SECONDARY_LISTING_PATH}/:id`, // 動的ページ
  icon: null,
  isPrivate: true,
  exact: true,
}

export const SELECT_OFFICIAL_ACCOUNT_NFT_MANAGEMENT = {
  component: views.SelectOfficialAccount,
  label: "NFT管理・出品",
  path: NFT_MANAGEMENT_PATH,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}

export const NFT_MANAGEMENT = {
  component: views.NFTManagement,
  label: "NFT管理・出品",
  path: `${NFT_MANAGEMENT_PATH}/:id/nft`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
}
export const NFT_AUCTION_MANAGEMENT = {
  component: views.NFTManagement,
  label: "NFT管理・出品",
  path: `${NFT_MANAGEMENT_PATH}/:id/auction`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
}

export const CREATE_NEW_PRIVATE_MEDIA_CONTENTS = {
  component: views.CreateNewPrivateMediaContent,
  label: "限定コンテンツ新規作成",
  path: `${NFT_MANAGEMENT_PATH}/:id/pmc/new`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const NFT_PRIVATE_MEDIA_CONTENTS_MANAGEMENT = {
  component: views.NFTPrivateMediaContents,
  label: "限定コンテンツ管理",
  path: `${NFT_MANAGEMENT_PATH}/:id/pmc`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const NFT_PRIVATE_MEDIA_CONTENTS_MANAGEMENT_ITEM = {
  component: views.NFTPrivateMediaContentsItem,
  label: "限定コンテンツ管理詳細",
  path: `${NFT_MANAGEMENT_PATH}/:id/pmc/:pmc_id`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const EDIT_PRIVATE_MEDIA_CONTENTS = {
  component: views.EditPrivateMediaContent,
  label: "限定コンテンツ編集",
  path: `${NFT_MANAGEMENT_PATH}/:id/pmc/edit/:pmc_id`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
  exact: true,
}

export const NFT_MANAGEMENT_OFFICIAL = {
  component: views.OfficialNFTManagement,
  label: "NFT管理・出品",
  path: `${NFT_MANAGEMENT_OFFICIAL_PATH}/nft`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
}
export const NFT_MANAGEMENT_AUCTION_OFFICIAL = {
  component: views.OfficialNFTManagement,
  label: "NFT管理・出品",
  path: `${NFT_MANAGEMENT_OFFICIAL_PATH}/auction`,
  icon: <img src={ItemIcon} alt="" />,
  isPrivate: true,
}
export const CREATE_NEW_NFT = {
  component: views.CreateNewNFT,
  label: "NFT新規作成",
  path: `${CREATE_NEW_NFT_PATH}/:id`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const DUPLICATE_NFT = {
  component: views.DuplicateNFT,
  label: "NFT複製",
  path: `${DUPLICATE_NFT_PATH}/:id`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const NFT_MANAGEMENT_ITEM = {
  component: views.NFTManagementItem,
  label: "NFT管理詳細",
  path: `${NFT_MANAGEMENT_ITEM_PATH}/:id`, // 動的ページ
  icon: null,
  isPrivate: true,
}
export const EDIT_NFT = {
  component: views.EditNFT,
  label: "NFT編集",
  path: `${EDIT_NFT_PATH}/:offical_id/:id`, // 動的ページ
  icon: null,
  isPrivate: true,
}
export const SELECT_TOUR_TICKET_MANAGEMENT = {
  component: views.TicketManagementSelectTour,
  label: "チケット管理 - ツアー選択",
  path: TICKET_MANAGEMENT_PATH,
  icon: <img src={BenifitIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const SELECT_CONCERT_TICKET_MANAGEMENT = {
  component: views.TicketManagementSelectConcert,
  label: "チケット管理 - 公演選択",
  path: `${TICKET_MANAGEMENT_PATH}/:tourId/concerts`,
  icon: <img src={BenifitIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const TICKET_MANAGEMENT = {
  component: views.TicketManagement,
  label: "チケット管理・公演詳細",
  path: `${TICKET_MANAGEMENT_PATH}/:cid`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const BENEFITS = {
  component: views.Benefits,
  label: "特典詳細",
  path: `${BENEFITS_PATH}/:id`, // 動的ページ
  icon: null,
  isPrivate: true,
  exact: true,
}
export const USE_BENEFITS_MANAGEMENT = {
  component: views.UseBenefitsManagement,
  label: "特典管理",
  path: USE_BENEFITS_MANAGEMENT_PATH,
  icon: <img src={BenifitIcon} alt="" />,
  isPrivate: true,
}
export const BENEFITS_MANAGEMENT_ACCOUNT_SELECT = {
  component: views.BenefitsManagementAccountSelect,
  label: "特典管理",
  path: BENEFITS_MANAGEMENT_ACCOUNT_SELECT_PATH,
  icon: <img src={BenifitIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const BENEFITS_MANAGEMENT_TABLE_OFFICIAL = {
  component: views.BenefitsManagementTable,
  label: "特典管理",
  path: BENEFITS_MANAGEMENT_OFFICIAL_PATH,
  icon: <img src={BenifitIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const BENEFITS_MANAGEMENT_TABLE = {
  component: views.BenefitsManagementTable,
  label: "特典管理",
  path: `${BENEFITS_MANAGEMENT_PATH}/:id`,
  icon: <img src={BenifitIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const BENEFITS_MANAGEMENT = {
  component: views.BenefitsManagement,
  label: "特典管理詳細",
  path: `${BENEFITS_MANAGEMENT_PATH}/:id/detail`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const CREATE_NEW_BENEFIT = {
  component: views.CreateBenefit,
  label: "特典新規作成",
  path: `${CREATE_NEW_BENEFIT_PATH}/:officialId`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const BENEFITS_MANAGEMENT_HOLDING = {
  component: views.BenefitsManagementHolding,
  label: "特典管理保有者",
  path: `${BENEFITS_MANAGEMENT_PATH}/:id/holding`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const SALES_MANAGEMENT = {
  component: views.SalesManagement,
  label: "売上・出金管理",
  path: SALES_MANAGEMENT_PATH,
  icon: <img src={walletIcon} alt="" />,
  isPrivate: true,
}
export const SALES_OFFICIAL_MANAGEMENT = {
  component: views.SalesOfficialManagement,
  label: "売上管理",
  path: SALES_OFFICIAL_MANAGEMENT_PATH,
  icon: <img src={walletIcon} alt="" />,
  isPrivate: true,
}
export const PAYMENT_MANAGEMENT = {
  component: views.AdminPaymentManagement,
  label: "入金管理(対一般アカウント)",
  path: PAYMENT_MANAGEMENT_PATH,
  icon: "show_chart",
  isPrivate: true,
}
export const PAYMENT_OFFICIAL_MANAGEMENT = {
  component: views.AdminPaymentOfficialManagement,
  label: "入金管理(対公式アカウント)",
  path: PAYMENT_OFFICIAL_MANAGEMENT_PATH,
  icon: "show_chart",
  isPrivate: true,
}

export const TRADING_HISTORY = {
  component: views.TradingHistory,
  label: "購入履歴",
  path: TRADING_HISTORY_PATH,
  icon: <img src={HistoryIcon} alt="" />,
  isPrivate: true,
}
export const ACCOUNT_MANAGEMENT = {
  component: views.AccountManagement,
  label: "アカウント管理",
  path: ACCOUNT_MANAGEMENT_PATH,
  icon: <img src={EditIcon} alt="" />,
  isPrivate: true,
}
// 公式ユーザー
export const BANK_WALLET_INFO = {
  component: views.BankCreditInfo,
  label: "決済情報",
  path: `${BANK_CREDIT_INFO_PATH}/official`,
  icon: <img src={CheckIcon} alt="" />,
  isPrivate: true,
}
// 一般ユーザー
export const BANK_CREDIT_WALLET_INFO = {
  component: views.BankCreditInfo,
  label: "決済情報",
  path: `${BANK_CREDIT_INFO_PATH}/user`,
  icon: <img src={CheckIcon} alt="" />,
  isPrivate: true,
}
export const USER_MANAGEMENT = {
  component: views.UserManagement,
  label: "ユーザー管理・作成",
  path: USER_MANAGEMENT_PATH,
  icon: "group_add",
  isPrivate: true,
  exact: true,
}
export const CREATE_NEW_USER = {
  component: views.CreateNewUser,
  label: "ユーザー新規作成",
  path: CREATE_NEW_USER_PATH,
  icon: null,
  isPrivate: true,
}
export const EDIT_USER = {
  component: views.EditUser,
  label: "ユーザー編集",
  path: `${EDIT_USER_PATH}/:id`, // 動的ページ,
  icon: null,
  isPrivate: true,
}
export const ADMIN_GENERAL_ACCOUNT_MANAGEMENT = {
  component: views.AdminGeneralAccountManagement,
  label: "一般アカウント管理",
  path: ADMIN_GENERAL_ACCOUNT_MANAGEMENT_PATH,
  icon: "person",
  isPrivate: true,
  exact: true,
}
export const ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT = {
  component: views.AdminOfficialAccountManagement,
  label: "公式アカウント管理",
  path: ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT_PATH,
  icon: "group",
  isPrivate: true,
  exact: true,
}
export const ADMIN_GENERAL_ACCOUNT = {
  component: views.AdminGeneralAccount,
  label: "一般アカウント詳細",
  path: `${ADMIN_GENERAL_ACCOUNT_MANAGEMENT_PATH}/:id`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const ADMIN_EDIT_GENERAL_ACCOUNT = {
  component: views.AdminEditGeneralAccountInfo,
  label: "一般アカウント編集",
  path: `${ADMIN_GENERAL_ACCOUNT_MANAGEMENT_PATH}/:id/edit_account`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const ADMIN_EDIT_GENERAL_PROFILE = {
  component: views.AdminEditProfileInfo,
  label: "一般プロフィール編集",
  path: `${ADMIN_GENERAL_ACCOUNT_MANAGEMENT_PATH}/:id/edit_profile`,
  icon: null,
  isPrivate: true,
  exact: true,
}

export const ADMIN_OFFICIAL_ACCOUNT = {
  component: views.AdminOfficialAccount,
  label: "公式アカウント詳細",
  path: `${ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT_PATH}/:id`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const ADMIN_CREATE_OFFICIAL_ACCOUNT = {
  component: views.CreateOfficialAccount,
  lavel: "公式アカウント作成",
  path: CREATE_NEW_OFFICIAL_PATH,
  icon: null,
  isPrivate: true,
  expect: true,
}
export const ADMIN_EDIT_OFFICIAL_ACCOUNT = {
  component: views.AdminEditOfficialAccountInfo,
  label: "公式アカウント編集",
  path: `${ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT_PATH}/:id/edit_account`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const ADMIN_EDIT_OFFICIAL_PROFILE = {
  component: views.AdminEditProfileInfo,
  label: "公式プロフィール編集",
  path: `${ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT_PATH}/:id/edit_profile`,
  icon: null,
  isPrivate: true,
  exact: true,
}

export const ADMIN_CREATE_OFFICIAL_USER = {
  component: views.AdminEditOfficialUserInfo,
  label: "公式ユーザー新規作成",
  path: `${ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT_PATH}/:id/new`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const ADMIN_EDIT_OFFICIAL_USER = {
  component: views.AdminEditOfficialUserInfo,
  label: "公式ユーザー編集",
  path: `${ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT_PATH}/:id/edit_user/:userId`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const ADMIN_NFT_MARKET_MANAGEMENT = {
  component: views.NFTMarketManagement,
  label: "NFTマーケット管理",
  path: ADMIN_NFT_MARKET_MANAGEMENT_PATH,
  icon: "store",
  isPrivate: true,
  exact: true,
}
export const ADMIN_ACCOUNT_MANAGEMENT = {
  component: views.AdminAccountManagement,
  label: "運営アカウント管理",
  path: ADMIN_ACCOUNT_MANAGEMENT_PATH,
  icon: <img src={EditIcon} alt="" />,
  isPrivate: true,
  exact: true,
}
export const CREATE_NEW_ADMIN = {
  component: views.CreateNewAdminAccount,
  label: "運営アカウント新規作成",
  path: CREATE_NEW_ADMIN_PATH,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const EDIT_ADMIN = {
  component: views.EditAdminAccount,
  label: "運営アカウント編集",
  path: `${EDIT_ADMIN_PATH}/:id`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const NOTIFICATION_NEWS_MANAGEMANT = {
  component: views.NotificationNewsManagement,
  label: "お知らせ・速報",
  path: NOTIFICATION_NEWS_MANAGEMANT_PATH,
  icon: "notifications",
  isPrivate: true,
  exact: true,
}
export const CREATE_NEW_NOTIFICATION_NEWS = {
  component: views.CreateNewNotificationNews,
  label: "運営お知らせ・速報新規作成",
  path: CREATE_NEW_NOTIFICATION_NEWS_PATH,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const EDIT_NOTIFICATION_NEWS = {
  component: views.EditNotificationNews,
  label: "運営お知らせ・メッセージ編集",
  path: `${EDIT_NOTIFICATION_NEWS_PATH}/:id`,
  icon: null,
  isPrivate: true,
  exact: true,
}
export const BANK_TRANSFERE_HISTORY = {
  component: views.BankTransferHistory,
  label: "振込履歴管理",
  path: BANK_TRANSFERE_HISTORY_PATH,
  icon: <img src={walletIcon} alt="" />,
  isPrivate: true,
}
export const LINK_BANK = {
  component: views.LinkBank,
  label: "振込履歴管理",
  path: LINK_BANK_PATH,
  icon: <img src={walletIcon} alt="" />,
  isPrivate: true,
}
// export const ADMIN_MESSAGE = {
//   component: views.AdminMessage,
//   label: "メッセージ送受信",
//   path: ADMIN_MESSAGE_PATH,
//   icon: "mail",
//   isPrivate: true,
//   exact: true,
// }
export const LOG_IN = {
  component: views.LogIn,
  label: "ログイン",
  path: LOG_IN_PATH,
  icon: null,
  isPrivate: false,
}
export const LOGIN_BY_TID = {
  component: views.LoginByTid,
  label: "TIDログイン",
  path: LOGIN_BY_TID_PATH,
  icon: null,
  isPrivate: false,
}
export const SIGN_UP = {
  component: views.LogIn,
  label: "新規登録",
  path: SIGN_UP_PATH,
  icon: null,
  isPrivate: false,
}
export const FORGOT_PASSWORD = {
  component: views.ForgotPassword,
  label: "パスワード忘れ",
  path: FORGOT_PASSWORD_PATH,
  icon: null,
  isPrivate: false,
}
export const RESET_PASSWORD = {
  component: views.ResetPassword,
  label: "パスワード再発行",
  path: RESET_PASSWORD_PATH,
  icon: null,
  isPrivate: false,
}
export const TERMS = {
  component: views.WPStatic,
  label: "利用規約",
  path: TERMS_PATH,
  icon: null,
  isPrivate: false,
}
export const SNS_CHECK = {
  component: views.SNSCheck,
  label: "SNS認証",
  path: SNS_CHECK_PATH,
  icon: null,
  isPrivate: false,
}
export const COMPANY = {
  component: views.WPStatic,
  label: "会社概要(運営会社)",
  path: COMPANY_PATH,
  icon: null,
  isPrivate: false,
}
export const OPERATION = {
  component: views.WPStatic,
  label: "運営会社",
  path: "https://fanplus.co.jp/",
  icon: null,
  isPrivate: false,
}
export const FLOW = {
  component: views.WPStatic,
  label: "取引の流れ",
  path: FLOW_PATH,
  icon: null,
  isPrivate: false,
}
export const ABOUT_MARK = {
  component: views.WPStatic,
  label: "公式マークについて",
  path: ABOUT_MARK_PATH,
  icon: null,
  isPrivate: false,
}
export const USAGE_GUIDE = {
  component: views.WPStatic,
  label: "ご利用ガイド",
  path: USAGE_GUIDE_PATH,
  icon: null,
  isPrivate: false,
}
export const HELP = {
  component: views.WPStatic,
  label: "ヘルプ",
  path: HELP_PATH,
  icon: null,
  isPrivate: false,
}
export const ABOUT_RETIRNS = {
  component: views.WPStatic,
  label: "返品等の事項",
  path: ABOUT_RETIRNS_PATH,
  icon: null,
  isPrivate: false,
}
export const FAQ = {
  component: views.WPStatic,
  label: "よくある質問",
  path: FAQ_PATH,
  icon: null,
  isPrivate: false,
}
export const AUCTION_PAYMENT = {
  component: views.WPStatic,
  label: "オークション販売における銀行振込決済について",
  path: AUCTION_PAYMENT_PATH,
  icon: null,
  isPrivate: false,
}
export const PRIVACY_POLICY = {
  component: views.WPStatic,
  label: "プライバシーポリシー",
  path: PRIVACY_POLICY_PATH,
  icon: null,
  isPrivate: false,
}
export const SCTA = {
  component: views.WPStatic,
  label: "特定商取引法に基づく表記",
  path: SCTA_PATH,
  icon: null,
  isPrivate: false,
}
export const ETH_PAYMENT = {
  component: views.WPStatic,
  label: "Ethereumによるお支払い方法について",
  path: ETH_PAYMANT_PATH,
  icon: null,
  isPrivate: false,
}
export const NOT_FOUND = {
  component: views.NotFound,
  label: "404",
  path: NOT_FOUND_PATH,
  icon: null,
  isPrivate: false,
}
export const SYSTEM_ERROR = {
  component: views.SystemError,
  label: "システムエラー",
  path: SYSTEM_ERROR_PATH,
  icon: null,
  isPrivate: false,
}
export const SETTINGS = {
  component: views.Settings,
  label: "各種設定・その他",
  path: SETTINGS_PATH,
  icon: <img src={SetIcon} alt="" />,
  isPrivate: true,
}
export const SYSTEM_MANAGEMENT = {
  component: views.SystemManagement,
  label: "システム管理",
  path: SYSTEM_MANAGEMENT_PATH,
  icon: "build",
  isPrivate: true,
}
export const MAINTENANCE = {
  component: views.Maintenance,
  label: "メンテナンス",
  path: MAINTENANCE_PATH,
  icon: null,
  isPrivate: false,
}
export const CONTACT = {
  component: views.Contact,
  label: "お問い合わせ",
  path: CONTACT_PATH,
  icon: null,
  isPrivate: false,
}

// React Router用
export const allRoutes = [
  NFT_MARKET,
  NFT_MARKET_LIST,
  MARKET_ITEM,
  MARKET_OWN_ITEM,
  PURCHASE,
  PURCHASE_COMPLETE,
  COLLECTION,
  COLLECTION_OWN,
  COLLECTION_SELLING,
  COLLECTION_DRAFT,
  COLLECTION_SOLD,
  COLLECTION_OFFER,
  COLLECTION_LOTTERY,
  COLLECTION_AUCTION,
  COLLECTION_FAVORITE,
  COLLECTION_LIKE,
  COLLECTION_AUCTION_ITEM,
  COLLECTION_CLOSED,
  MY_PAGE,
  MY_PAGE_EDIT,
  SELLER_OFFICIAL_MY_PAGE,
  SELLER_USER_MY_PAGE,
  SELLER_OFFICIAL_MY_PAGE_SELLING,
  SELLER_OFFICIAL_MY_PAGE_SELLED,
  SELLER_USER_MY_PAGE_SELLING,
  SELLER_USER_MY_PAGE_SELLED,
  SELLER_USER_MY_PAGE_OWN,
  // NFT_SELL,
  SECONDARY_LISTING,
  EDIT_SECONDARY_LISTING,
  SELECT_OFFICIAL_ACCOUNT_NFT_MANAGEMENT,
  NFT_MANAGEMENT,
  NFT_AUCTION_MANAGEMENT,
  NFT_MANAGEMENT_OFFICIAL,
  NFT_MANAGEMENT_AUCTION_OFFICIAL,
  NFT_PRIVATE_MEDIA_CONTENTS_MANAGEMENT,
  CREATE_NEW_PRIVATE_MEDIA_CONTENTS,
  EDIT_PRIVATE_MEDIA_CONTENTS,
  NFT_PRIVATE_MEDIA_CONTENTS_MANAGEMENT_ITEM,
  CREATE_NEW_NFT,
  DUPLICATE_NFT,
  EDIT_NFT,
  NFT_MANAGEMENT_ITEM,
  SELECT_TOUR_TICKET_MANAGEMENT,
  SELECT_CONCERT_TICKET_MANAGEMENT,
  TICKET_MANAGEMENT,
  BENEFITS,
  USE_BENEFITS_MANAGEMENT,
  BENEFITS_MANAGEMENT_TABLE,
  BENEFITS_MANAGEMENT,
  BENEFITS_MANAGEMENT_HOLDING,
  SALES_MANAGEMENT,
  SALES_OFFICIAL_MANAGEMENT,
  PAYMENT_OFFICIAL_MANAGEMENT,
  PAYMENT_MANAGEMENT,
  TRADING_HISTORY,
  ACCOUNT_MANAGEMENT,
  BANK_WALLET_INFO,
  BANK_CREDIT_WALLET_INFO,
  USER_MANAGEMENT,
  CREATE_NEW_USER,
  EDIT_USER,
  ADMIN_GENERAL_ACCOUNT_MANAGEMENT,
  ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT,
  ADMIN_GENERAL_ACCOUNT,
  ADMIN_OFFICIAL_ACCOUNT,
  ADMIN_EDIT_GENERAL_ACCOUNT,
  ADMIN_EDIT_GENERAL_PROFILE,
  ADMIN_CREATE_OFFICIAL_ACCOUNT,
  ADMIN_EDIT_OFFICIAL_ACCOUNT,
  ADMIN_EDIT_OFFICIAL_PROFILE,
  ADMIN_CREATE_OFFICIAL_USER,
  ADMIN_EDIT_OFFICIAL_USER,
  ADMIN_ACCOUNT_MANAGEMENT,
  CREATE_NEW_ADMIN,
  EDIT_ADMIN,
  NOTIFICATION_NEWS_MANAGEMANT,
  CREATE_NEW_NOTIFICATION_NEWS,
  EDIT_NOTIFICATION_NEWS,
  LOG_IN,
  LOGIN_BY_TID,
  SIGN_UP,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  TERMS,
  SNS_CHECK,
  COMPANY,
  FLOW,
  ABOUT_MARK,
  USAGE_GUIDE,
  AUCTION_PAYMENT,
  FAQ,
  HELP,
  ABOUT_RETIRNS,
  PRIVACY_POLICY,
  SCTA,
  ETH_PAYMENT,
  NOT_FOUND,
  SYSTEM_ERROR,
  SETTINGS,
  SYSTEM_MANAGEMENT,
  MAINTENANCE,
  ADMIN_NFT_MARKET_MANAGEMENT,
  BENEFITS_MANAGEMENT_ACCOUNT_SELECT,
  CREATE_NEW_BENEFIT,
  BENEFITS_MANAGEMENT_TABLE_OFFICIAL,
  CONTACT,
  BANK_TRANSFERE_HISTORY,
  LINK_BANK,
]

export const footerRoutes = [USAGE_GUIDE, FAQ, TERMS]
export const footerRoutesOther = [SCTA, PRIVACY_POLICY, OPERATION]

// 一般アカウント サイドメニュー
export const generalSideMenu = {
  1: {
    COLLECTION,
    USE_BENEFITS_MANAGEMENT,
    SALES_MANAGEMENT,
    TRADING_HISTORY,
  },
  2: { ACCOUNT_MANAGEMENT, MY_PAGE, BANK_CREDIT_WALLET_INFO },
}
// 公式アカウント サイドメニュー
export const officialSideMenu = {
  1: {
    NFT_MANAGEMENT_OFFICIAL,
    BENEFITS_MANAGEMENT_TABLE_OFFICIAL,
    SALES_OFFICIAL_MANAGEMENT,
  },
  2: {
    ACCOUNT_MANAGEMENT,
    MY_PAGE,
    BANK_WALLET_INFO,
    USER_MANAGEMENT,
  },
}
// 運営アカウント サイドメニュー
export const adminSideMenu = {
  1: {
    SELECT_OFFICIAL_ACCOUNT_NFT_MANAGEMENT,
    SELECT_TOUR_TICKET_MANAGEMENT,
    BENEFITS_MANAGEMENT_ACCOUNT_SELECT,
  },
  2: {
    ADMIN_GENERAL_ACCOUNT_MANAGEMENT,
    ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT,
    NOTIFICATION_NEWS_MANAGEMANT,
    BANK_TRANSFERE_HISTORY,
    PAYMENT_OFFICIAL_MANAGEMENT,
    PAYMENT_MANAGEMENT,
    // ADMIN_MESSAGE,
  },
  3: {
    // BLOCKCHAIN,
    ADMIN_ACCOUNT_MANAGEMENT,
    // WALLET_INFO,
    ADMIN_NFT_MARKET_MANAGEMENT,
    SYSTEM_MANAGEMENT,
  },
}

// 一般アカウント ヘッダーサイドメニュー
export const generalHeaderSideMenu = {
  1: {
    COLLECTION,
    USE_BENEFITS_MANAGEMENT,
    SALES_MANAGEMENT,
    TRADING_HISTORY,
  },
  2: { ACCOUNT_MANAGEMENT, MY_PAGE, BANK_CREDIT_WALLET_INFO },
  3: { SETTINGS },
}
// 公式アカウント ヘッダーサイドメニュー
export const officialHeaderSideMenu = {
  1: {
    NFT_MANAGEMENT_OFFICIAL,
    BENEFITS_MANAGEMENT_TABLE_OFFICIAL,
    SALES_OFFICIAL_MANAGEMENT,
    // TRADING_HISTORY,
  },
  2: {
    ACCOUNT_MANAGEMENT,
    MY_PAGE,
    BANK_WALLET_INFO,
    USER_MANAGEMENT,
  },
  3: { SETTINGS },
}
// 運営アカウント ヘッダーサイドメニュー
export const adminHeaderSideMenu = {
  1: {
    SELECT_OFFICIAL_ACCOUNT_NFT_MANAGEMENT,
    SELECT_TOUR_TICKET_MANAGEMENT,
    BENEFITS_MANAGEMENT_ACCOUNT_SELECT,
  },
  2: {
    ADMIN_GENERAL_ACCOUNT_MANAGEMENT,
    ADMIN_OFFICIAL_ACCOUNT_MANAGEMENT,
    NOTIFICATION_NEWS_MANAGEMANT,
    BANK_TRANSFERE_HISTORY,
    PAYMENT_OFFICIAL_MANAGEMENT,
    PAYMENT_MANAGEMENT,
  },
  3: {
    ADMIN_ACCOUNT_MANAGEMENT,
    ADMIN_NFT_MARKET_MANAGEMENT,
    SYSTEM_MANAGEMENT,
  },
}
