import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Icon, Box, Hidden } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 280,
    marginBottom: 380,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: 133,
      marginBottom: 233,
    },
  },
  warning: {
    "& .MuiIcon-root": {
      fontSize: 70,
    },
  },
}))

const Maintenance = ({ message }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classes.root}
    >
      <Box
        fontSize={{ xs: 57, sm: 60 }}
        fontWeight={700}
        textAlign="center"
        lineHeight="70px"
        mb="50px"
        display={{ xs: "block", sm: "flex" }}
      >
        <Box className={classes.warning}>
          <Icon>warning_amber</Icon>
        </Box>
        メンテナンス
        <Hidden smUp>
          <br />
        </Hidden>
        のお知らせ
      </Box>
      <Box
        display="flex"
        textAlign="center"
        fontWeight={700}
        className={classes.text}
        fontSize={{ xs: 24, sm: 28 }}
      >
        {message}
      </Box>
    </Box>
  )
}

Maintenance.propTypes = {
  message: PropTypes.string,
}

export default Maintenance
