export const tds2CredentialPhoneTypeList = [
  {
    typeName: "1",
    explain: "携帯",
    checked: true,
  },
  {
    typeName: "2",
    explain: "自宅",
    checked: false,
  },
  {
    typeName: "3",
    explain: "職場",
    checked: false,
  },
]
