import { authApi, api } from "../../api"
import { PAGINATION_INIT, PAGINATION_SET } from "../../pagination/types"
import {
  SEARCH_LIST_REQUEST,
  SEARCH_LIST_LOADED,
  CATEGORY_LIST_LOADED,
  BRAND_LIST_LOADED,
  SEARCH_HISTORY_LIST_LOADED,
  NFT_DETAIL_REQUEST,
  NFT_DETAIL_LOADED,
  NFT_DETAIL_ERROR,
  PRIMARY_NFT_LIST_REQUEST,
  PRIMARY_NFT_LIST_LOADED,
  SECONDARY_NFT_LIST_REQUEST,
  SECONDARY_NFT_LIST_LOADED,
  SEARCH_SET,
  NFT_UUID_LIST_LOADED,
  NFT_UUID_TRANSACTION_LIST_LOADED,
  NFT_UUID_LIST_REQUEST,
  NFT_UUID_TRANSACTION_LIST_REQUEST,
  BANNER_LIST_LOADED,
  NFT_UUID_UPDATED,
  NFT_VERIFY_SIGNATURES,
} from "./types"

export const getSearchList =
  ({ page = 1 } = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: SEARCH_LIST_REQUEST,
    })
    if (page === 1) {
      dispatch({
        type: PAGINATION_INIT,
        slot: "nftSearchList",
      })
    }
    const query = getState().searchNft.searchQuery
    return authApi
      .get("/nfts", {
        params: {
          page,
          limit: 16,
          ...query,
        },
      })
      .then((res) => {
        dispatch({
          type: SEARCH_LIST_LOADED,
          payload: res.data.data,
        })
        dispatch({
          type: PAGINATION_SET,
          payload: res.data.pagination,
          slot: "nftSearchList",
        })
      })
  }

export const setSearchFilter = (params) => (dispatch) => {
  dispatch({
    type: SEARCH_SET,
    payload: params,
  })
}

// ページングなしカテゴリ括データ取得
export const getCategoryList = () => (dispatch) =>
  api
    .get("/nfts/categories", {
      params: {
        pagination: false,
      },
    })
    .then((res) => {
      dispatch({
        type: CATEGORY_LIST_LOADED,
        payload: res.data.data,
      })
    })

// ページングなしブランド括データ取得
export const getBrandList = () => (dispatch) =>
  api
    .get("/nfts/brands", {
      params: {
        pagination: false,
      },
    })
    .then((res) => {
      dispatch({
        type: BRAND_LIST_LOADED,
        payload: res.data.data,
      })
    })

// ページングなしバナーデータ取得
export const getBannerList = () => (dispatch, getState) => {
  const query = getState().searchNft.searchQuery
  return api
    .get("/banners", {
      params: {
        ...query,
      },
    })
    .then((res) => {
      dispatch({
        type: BANNER_LIST_LOADED,
        payload: res.data.data,
      })
    })
}

export const getSearchHistoryList = () => (dispatch) =>
  authApi.get("/search/history").then((res) => {
    dispatch({
      type: SEARCH_HISTORY_LIST_LOADED,
      payload: res.data.data,
    })
  })

export const getNftDetail = (id, path) => (dispatch) => {
  dispatch({
    type: NFT_DETAIL_REQUEST,
  })
  authApi
    .get(`/nfts/${path}/${id}`)
    .then((res) => {
      dispatch({
        type: NFT_DETAIL_LOADED,
        payload: res.data.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: NFT_DETAIL_ERROR,
        payload: err.response?.status,
      })
    })
}

export const getPrimaryNftList =
  (page = 1) =>
  (dispatch, getState) => {
    dispatch({
      type: PRIMARY_NFT_LIST_REQUEST,
    })
    dispatch({
      type: PAGINATION_INIT,
    })
    const query = getState().searchNft.searchQuery
    return authApi
      .get("/nfts", {
        params: {
          page,
          status: ["primary_selling"],
          limit: 16,
          recommend: 1,
          order: "new",
          ...query,
        },
      })
      .then((res) => {
        dispatch({
          type: PRIMARY_NFT_LIST_LOADED,
          payload: res.data.data,
        })
        dispatch({
          type: PAGINATION_SET,
          payload: res.data.pagination,
        })
      })
  }

export const getSecondaryNftList =
  (page = 1) =>
  (dispatch, getState) => {
    dispatch({
      type: SECONDARY_NFT_LIST_REQUEST,
    })
    dispatch({
      type: PAGINATION_INIT,
    })
    const query = getState().searchNft.searchQuery
    return authApi
      .get("/nfts", {
        params: {
          page,
          status: ["secondary_selling"],
          limit: 10,
          recommend: 1,
          order: "new",
          ...query,
        },
      })
      .then((res) => {
        dispatch({
          type: SECONDARY_NFT_LIST_LOADED,
          payload: res.data.data,
        })
        dispatch({
          type: PAGINATION_SET,
          payload: res.data.pagination,
        })
      })
  }

export const getUUIDList =
  (id, { page = 1 } = {}) =>
  (dispatch) => {
    dispatch({
      type: NFT_UUID_LIST_REQUEST,
    })
    return authApi
      .get(`/nfts/detail/${id}/token`, {
        params: {
          page,
        },
      })
      .then((res) => {
        dispatch({
          type: NFT_UUID_LIST_LOADED,
          payload: res.data.data,
        })
        dispatch({
          type: PAGINATION_SET,
          payload: res.data.pagination,
        })
      })
  }

export const getUUIDTransactionList =
  ({ id, uuid }) =>
  (dispatch) => {
    dispatch({
      type: NFT_UUID_TRANSACTION_LIST_REQUEST,
    })
    return authApi
      .get(`/nfts/detail/${id}/token/${uuid}/transaction`)
      .then((res) => {
        dispatch({
          type: NFT_UUID_TRANSACTION_LIST_LOADED,
          payload: res.data.data,
        })
      })
  }

export const updateUUIDSellerSignature = (uuid, values) => (dispatch) =>
  authApi
    .put(`/nfts/token/${uuid}/seller/signature`, { ...values })
    .then((res) => {
      dispatch({
        type: NFT_UUID_UPDATED,
        payload: res.data.data,
      })
    })

export const updateNftSellerSignature = (nftId, values) => () =>
  authApi.put(`/nfts/${nftId}/seller/signature`, { ...values })

export const verifySignatures = (nftId, values) => (dispatch) =>
  authApi
    .post(`/nfts/${nftId}/verify_signatures`, { ...values })
    .then((res) => {
      dispatch({
        type: NFT_VERIFY_SIGNATURES,
        payload: res.data.data,
      })
    })

/**
 * クレカ登録の有無を同期的にチェック
 * @returns true/false
 */
export const checkUserCreditList = async () =>
  authApi.get(`/settings/credits`).then((res) => res.data.data.length > 0)
