export const GENERAL_ACCOUNT_LIST_LOADED = "GENERAL_ACCOUNT_LIST_LOADED"
export const GENERAL_ACCOUNT_LIST_TRANSITION_LOADED =
  "GENERAL_ACCOUNT_LIST_TRANSITION_LOADED"
export const GENERAL_ACCOUNT_LIST_ERROR = "GENERAL_ACCOUNT_LIST_ERROR"

export const GENERAL_ACCOUNT_REQUEST = "GENERAL_ACCOUNT_REQUEST"
export const GENERAL_ACCOUNT_INIT = "GENERAL_ACCOUNT_INIT"
export const GENERAL_ACCOUNT_LOADED = "GENERAL_ACCOUNT_LOADED"
export const GENERAL_ACCOUNT_UPDATED = "GENERAL_ACCOUNT_UPDATED"
export const GENERAL_ACCOUNT_DELETED = "GENERAL_ACCOUNT_DELETED"
export const GENERAL_ACCOUNT_ERROR = "GENERAL_ACCOUNT_ERROR"

export const GENERAL_BANK_LIST_REQUEST = "GENERAL_BANK_LIST_REQUEST"
export const GENERAL_BANK_LIST_LOADED = "GENERAL_BANK_LIST_LOADED"
export const GENERAL_BANK_LIST_ERROR = "GENERAL_BANK_LIST_ERROR"
export const GENERAL_CREDIT_LIST_REQUEST = "GENERAL_CREDIT_LIST_REQUEST"
export const GENERAL_CREDIT_LIST_LOADED = "GENERAL_CREDIT_LIST_LOADED"
export const GENERAL_CREDIT_LIST_ERROR = "GENERAL_CREDIT_LIST_ERROR"
export const GENERAL_WALLET_LIST_LOADED = "GENERAL_WALLET_LIST_LOADED"
export const GENERAL_WALLET_LIST_ERROR = "GENERAL_WALLET_LIST_ERROR"

export const COLLECTION_LIST_REQUEST = "COLLECTION_LIST_REQUEST"
export const COLLECTION_LIST_LOADED = "COLLECTION_LIST_LOADED"
export const COLLECTION_LIST_ERROR = "COLLECTION_LIST_ERROR"
