import { format } from "date-fns"
import {
  PURCHACE_HISTORY_LIST_LOADED,
  PURCHACE_HISTORY_LIST_ERROR,
  SALES_HISTORY_LIST_LOADED,
  INIT_SALES,
  SALES_SUMMARY_LOADED,
  SALES_SUMMARY_ERROR,
  SALES_HISTORY_LIST_ERROR,
} from "./types"

const salesMethods = {
  list: "定価販売",
  lottery: "抽選",
  auction: "オークション",
}
const paymentMethodDisplayNames = {
  creditcard: "クレジットカード",
  bitpay: "Bitpay",
  now_payments: "NowPayments",
  polygon: "Polygon(MATIC)",
  ethereum: "Ethereum",
  エアドロップ: "エアドロップ",
}

const initialState = () => ({
  history: {
    salesHistoryList: [],
    csv: [],
  },
  primary: {
    yen: {
      salesHistoryList: [],
      csv: [],
      summary: {
        priceYen: 0,
        salesYen: 0,
        count: 0,
      },
    },
    eth: {
      salesHistoryList: [],
      csv: [],
      summary: {
        priceYen: 0,
        salesYen: 0,
        count: 0,
      },
    },
    matic: {
      salesHistoryList: [],
      csv: [],
      summary: {
        priceYen: 0,
        salesYen: 0,
        count: 0,
      },
    },
  },
  secondary: {
    yen: {
      salesHistoryList: [],
      csv: [],
      summary: {
        priceYen: 0,
        salesYen: 0,
        count: 0,
      },
    },
    eth: {
      salesHistoryList: [],
      csv: [],
      summary: {
        priceYen: 0,
        salesYen: 0,
        count: 0,
      },
    },
    matic: {
      salesHistoryList: [],
      csv: [],
      summary: {
        priceYen: 0,
        salesYen: 0,
        count: 0,
      },
    },
  },
  secondary_user: {
    yen: {
      salesHistoryList: [],
      csv: [],
      summary: {
        priceYen: 0,
        salesYen: 0,
        count: 0,
      },
    },
    eth: {
      salesHistoryList: [],
      csv: [],
      summary: {
        priceYen: 0,
        salesYen: 0,
        count: 0,
      },
    },
    matic: {
      salesHistoryList: [],
      csv: [],
      summary: {
        priceYen: 0,
        salesYen: 0,
        count: 0,
      },
    },
  },
})

const convertToSalesHistoryRow = (data, viewType, paymentMethods) => {
  const row = {
    ...data,
    salesMethodName: salesMethods[data.salesMethod],
    paymentMethodName:
      paymentMethodDisplayNames[paymentMethods[data.paymentMethodMasterId]],
    to: data.toUser.plusmemberId,
    isPrimary: data.fromUser === null,
  }

  if (viewType === "primary") {
    row.comission = data.commission
    row.comissionPrice = data.commissionPrice
    row.from = data.userNft.official.name
  } else if (viewType === "secondary") {
    row.comission = data.royalty
    row.comissionPrice = data.royaltyPrice
    row.from = data.fromUser.plusmemberId
  } else if (viewType === "secondary_user") {
    row.commission = data.secondaryCommission
    row.commissionPrice = data.secondaryCommissionPrice
    row.from = data.fromUser.plusmemberId
  } else {
    row.from = data.fromUser
      ? data.fromUser.plusmemberId
      : data.userNft.official.name
  }

  return row
}

const createCsvData = (list, viewType) => {
  const header = [
    "出品ID",
    "取引時間",
    "取引ID",
    "出品名",
    "出品形式",
    "売上金額",
  ]
    .concat(
      viewType === "primary"
        ? ["手数料率(%)", "手数料金額(金額)", "販売利益(金額)"]
        : viewType === "secondary"
        ? ["ロイヤリティ率(%)", "ロイヤリティ金額(金額)"]
        : viewType === "secondary_user"
        ? ["ロイヤリティ率(%)", "手数料金額(金額)", "販売利益(金額)"]
        : []
    )
    .concat(["取引元ユーザーID", "取引先ユーザーID", "決済方法"])
    .concat(viewType === "secondary_user" ? ["入金状況"] : [])

  const rows = list.map((row) =>
    [
      row.userNft.nft.id,
      format(new Date(row.createdAt), "yyyy-MM-dd HH:mm:ss"),
      row.orderId,
      row.userNft.nft.title,
      row.salesMethodName,
      row.price,
    ]
      .concat(
        viewType === "secondary"
          ? [row.comission, row.comissionPrice]
          : viewType !== "user"
          ? [row.comission, row.comissionPrice, row.salesPrice]
          : []
      )
      .concat([row.from, row.to, row.paymentMethodName])
      .concat(
        viewType === "secondary_user"
          ? [row.hasTransferredToUser ? "入金済み" : "未入金"]
          : []
      )
  )
  return [header, ...rows]
}

const salesManagementReducers = (state = initialState(), action) => {
  switch (action.type) {
    case INIT_SALES:
      return initialState()
    case PURCHACE_HISTORY_LIST_LOADED:
    case SALES_HISTORY_LIST_LOADED: {
      const newState = { ...state }
      const historyList = action.payload.map((data) =>
        convertToSalesHistoryRow(data, action.viewType, action.paymentMethods)
      )
      if (action.currencyType) {
        newState[action.viewType][action.currencyType] = {
          ...newState[action.viewType][action.currencyType],
          salesHistoryList: historyList,
          csv: createCsvData(historyList, action.viewType),
        }
      } else {
        newState[action.viewType] = {
          ...newState[action.viewType],
          salesHistoryList: historyList,
          csv: createCsvData(historyList, action.viewType),
        }
      }

      return newState
    }
    case SALES_SUMMARY_LOADED: {
      const newState = { ...state }
      newState[action.viewType][action.currencyType] = {
        ...newState[action.viewType][action.currencyType],
        summary: action.payload[action.currencyType],
      }
      return newState
    }

    case PURCHACE_HISTORY_LIST_ERROR:
    case SALES_HISTORY_LIST_ERROR:
    case SALES_SUMMARY_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default salesManagementReducers
