import {
  generalSideMenu,
  officialSideMenu,
  adminSideMenu,
  generalHeaderSideMenu,
  officialHeaderSideMenu,
  adminHeaderSideMenu,
} from "../navigation/routes"

export const getMenuListByAccountType = (type) => {
  switch (type) {
    case "user":
      return generalSideMenu
    case "official":
      return officialSideMenu
    case "super":
      return adminSideMenu
    default:
      return null
  }
}

export const getHeaderSideMenuListByAccountType = (type) => {
  switch (type) {
    case "user":
      return generalHeaderSideMenu
    case "official":
      return officialHeaderSideMenu
    case "super":
      return adminHeaderSideMenu
    default:
      return null
  }
}
