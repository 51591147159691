export const BENEFIT_MANAGEMENT_LIST_LOADED = "BENEFIT_MANAGEMENT_LIST_LOADED"
export const BENEFIT_MANAGEMENT_LIST_ERROR = "BENEFIT_MANAGEMENT_LIST_ERROR"

export const BENEFIT_MANAGEMENT_REQUEST = "BENEFIT_MANAGEMENT_REQUEST"
export const BENEFIT_MANAGEMENT_INIT = "BENEFIT_MANAGEMENT_INIT"
export const BENEFIT_MANAGEMENT_LOADED = "BENEFIT_MANAGEMENT_LOADED"
export const BENEFIT_MANAGEMENT_CREATED = "BENEFIT_MANAGEMENT_CREATED"
export const BENEFIT_MANAGEMENT_UPDATED = "BENEFIT_MANAGEMENT_UPDATED"
export const BENEFIT_MANAGEMENT_DELETED = "BENEFIT_MANAGEMENT_DELETED"
export const BENEFIT_MANAGEMENT_ERROR = "BENEFIT_MANAGEMENT_ERROR"

export const BENEFIT_OWNER_LIST_LOADED = "BENEFIT_OWNER_LIST_LOADED"
export const BENEFIT_OWNER_USED = "BENEFIT_OWNER_USED"
