import {
  OFFICIAL_ACCOUNT_USER_LIST_LOADED,
  OFFICIAL_ACCOUNT_USER_LOADED,
  OFFICIAL_ACCOUNT_USER_INIT,
  OFFICIAL_ACCOUNT_USER_CREATED,
  OFFICIAL_ACCOUNT_USER_UPDATED,
  OFFICIAL_ACCOUNT_USER_ERROR,
  OFFICIAL_ACCOUNT_USER_LIST_ERROR,
  OFFICIAL_ACCOUNT_USER_DELETED,
} from "./types"

const initialState = () => ({
  list: [],
  transition: [],
  form: {
    nickname: "",
    lastName: "",
    firstName: "",
    lastNameKana: "",
    firstNameKana: "",
    birthDate: "",
    email: "",
    emailConfirmation: "",
    password: "",
    passwordConfirmation: "",
  },
})

const convertToForm = (data) => {
  const validKeys = [
    "nickname",
    "lastName",
    "firstName",
    "lastNameKana",
    "firstNameKana",
    "birthDate",
    "email",
  ]
  const requiredData = Object.fromEntries(
    Object.entries(data).filter(([key, _value]) => validKeys.includes(key))
  )
  return {
    ...requiredData,
    nickname: data.nickname || "",
    emailConfirmation: data.email,
    password: "",
    passwordConfirmation: "",
  }
}

const officialAccountReducers = (state = initialState(), action) => {
  switch (action.type) {
    case OFFICIAL_ACCOUNT_USER_LIST_LOADED:
      return {
        ...state,
        list: action.payload,
      }
    case OFFICIAL_ACCOUNT_USER_LOADED: {
      return {
        ...state,
        form: convertToForm(action.payload),
      }
    }
    case OFFICIAL_ACCOUNT_USER_INIT:
      return {
        ...state,
        form: initialState().form,
      }
    case OFFICIAL_ACCOUNT_USER_CREATED:
    case OFFICIAL_ACCOUNT_USER_UPDATED:
    case OFFICIAL_ACCOUNT_USER_DELETED:
      return {
        ...state,
      }
    case OFFICIAL_ACCOUNT_USER_LIST_ERROR:
    case OFFICIAL_ACCOUNT_USER_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default officialAccountReducers
