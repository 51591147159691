import React, { Suspense } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ThemeProvider } from "@material-ui/core/styles"
import { Container, Slide, IconButton, Icon } from "@material-ui/core"
import { Provider } from "react-redux"
import { SnackbarProvider } from "notistack"
import store from "./store/store"
import AppRoute from "./navigation/AppRoute"
import { allRoutes } from "./navigation/routes"
import Layout from "./components/Layout/Layout"
import NotFound from "./pages/NotFound/NotFound"
import { theme } from "./styles/customTheme"
import Loading from "./components/Loading/Loading"
import { loadUser } from "./store/auth/actions"
import { ScrollToTop } from "./utils/scrollToTop"
import { hasAuthToken } from "./utils/authStorage"
import { Web3Provider } from "./context/web3"
import { getAvailablePaymentMethodList } from "./store/master/actions"

function App() {
  React.useEffect(() => {
    // tokenがlocalstorageに有る場合はログイン情報をフェッチ
    if (hasAuthToken()) {
      store.dispatch(loadUser())
    }

    // マスタ情報をロード
    store.dispatch(getAvailablePaymentMethodList())

    // GMOカード決済SDK
    const gmoTag = document.createElement("script")
    gmoTag.src = process.env.REACT_APP_GMO_TOKEN_SCRIPT_URL
    document.body.appendChild(gmoTag)
  }, [])
  const notistackRef = React.createRef()
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          TransitionComponent={Slide}
          ref={notistackRef}
          action={(key) => (
            <IconButton
              color="inherit"
              onClick={() => notistackRef.current.closeSnackbar(key)}
            >
              <Icon>closeicon</Icon>
            </IconButton>
          )}
        >
          <Container maxWidth="lg" className="Mui-noroot">
            <Suspense fallback={<Loading />}>
              <Router>
                <ScrollToTop />
                <Layout>
                  <Web3Provider>
                    <Switch>
                      {allRoutes.map((route, _) => (
                        <AppRoute
                          key={route.path}
                          path={route.path}
                          component={route.component}
                          isPrivate={route.isPrivate}
                          exact={route.exact}
                        />
                      ))}
                      <Route path="*">
                        <NotFound />
                      </Route>
                    </Switch>
                  </Web3Provider>
                </Layout>
              </Router>
            </Suspense>
          </Container>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
