import React from "react"
import PropTypes from "prop-types"
import { Box, makeStyles } from "@material-ui/core"
import ThreadLayout from "../ThreadLayout"

const useStyles = makeStyles((_) => ({
  root: {
    height: "600px",
    overflow: "auto",
  },
}))

// メッセージ内容
const MessageLayout = ({ messageThreadData }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {messageThreadData.map((data) => (
        <ThreadLayout
          key={data.id}
          senderImage={data.user_image}
          date={new Date(data.time_stamp).toLocaleDateString("ja-JP")}
          htmlText={data.message}
        />
      ))}
    </Box>
  )
}

MessageLayout.propTypes = {
  messageThreadData: PropTypes.arrayOf(PropTypes.object),
}

export default MessageLayout
