import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  Box,
  List,
  ListItem,
  Icon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core"
import { AddOutlined, MinimizeOutlined } from "@material-ui/icons"

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAccordionSummary-root": {
      display: "flex",
      padding: "20px",
      borderBottom: "1px solid transparent",
      alignItems: "center",
      transition: "0.8s",
      "&.Mui-expanded": {
        borderBottom: "1px solid #DEDEDE",
      },
      "& .MuiBox-root": {
        display: "flex",
        alignItems: "center",
        "& .MuiIcon-root": {
          marginRight: "15px",
          lineHeight: "1",
        },
      },
      "& .MuiAccordionSummary-content": {
        margin: "0",
        "&.Mui-expanded": {
          "& + .Mui-expanded": {
            "& .MuiIconButton-label": {
              transform: "translateY(-25%)",
            },
          },
        },
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: "0",
    },
  },
}))

const SearchSection = ({ title, icon, children }) => {
  const [expanded, setExpanded] = React.useState(false)
  const classes = useStyles()
  return (
    <List className={classes.root}>
      <Accordion
        onChange={() => {
          setExpanded((state) => !state)
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <MinimizeOutlined /> : <AddOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display="flex">
            <Icon>{icon}</Icon>
            <Box
              fontSize={{ xs: 15, sm: 18 }}
              fontWeight={700}
              color="textPrimary"
            >
              {title}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ListItem disableGutters>{children}</ListItem>
        </AccordionDetails>
      </Accordion>
    </List>
  )
}

SearchSection.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
  // isDisplay: PropTypes.bool,
}

export default SearchSection
