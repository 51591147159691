import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { useHistory, Link } from "react-router-dom"
import { NFT_MARKET_PATH } from "../../navigation/constatnsPaths"

const useStyles = makeStyles((theme) => ({
  root: {},
  categoryNav: {
    display: "flex",
    alignItems: "center",
    height: "38px",
    backgroundColor: "#000",
    border: "1px solid #000",
    borderRadius: "19px",
    padding: "0",
    overflow: "hidden",
    "& .subheader": {
      display: "flex",
      alignItems: "center",
      height: "35px",
      borderRadius: "19px 0 0 19px",
      fontFamily: "futura-pt, sans-serif",
      fontSize: "14px",
      fontWeight: "700",
      color: "#9D9D9D",
    },
    "& .MuiDivider-root": {
      alignSelf: "center",
      backgroundColor: "#444444",
      height: "50%",
      "&.active": {
        height: "0",
      },
    },
    "& .item": {
      maxWidth: "110px",
      "& .MuiListItemText-primary": {
        paddingLeft: "3px",
        paddingRight: "3px",
        fontFamily: "futura-pt, sans-serif",
        fontSize: "15px",
        fontWeight: "700",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      "&.Mui-selected": {
        backgroundColor: "#692AF0",
        "& + .MuiDivider-root": {
          height: "0",
        },
      },
      "&:hover": {
        backgroundColor: theme.palette.bg_base,
        color: theme.palette.main,
      },
    },
  },
  CategorySelect: {
    width: "133px",
    height: "32px",
    backgroundColor: "#000",
    border: "1px solid #000",
    borderRadius: "16px",
    padding: "0 16px",
    overflow: "hidden",
    color: "white",
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "38px",
      borderRadius: "19px",
      padding: "0 16px",
    },
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
    "&.unselected .MuiListItemText-primary": {
      color: "#9D9D9D",
    },
    "& .MuiListItemText-primary": {
      fontFamily: "futura-pt, sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      textOverflow: "ellipsis",
      overflow: "hidden",
      [theme.breakpoints.up("sm")]: {
        fontSize: "15px",
      },
    },
    "& .MuiSelect-icon": {
      right: "10px",
      color: "white",
    },
    "& .MuiListItemIcon-root": {
      display: "none",
    },
  },
  CategorySelectMenu: {
    "& .MuiMenu-paper": {
      width: "133px",
      backgroundColor: "#000",
      border: "1px solid #000",
      borderRadius: "16px",
      color: "white",
      [theme.breakpoints.up("sm")]: {
        width: "150px",
        borderRadius: "19px",
      },
      [theme.breakpoints.up("md")]: {
        width: "250px",
      },
      "& .MuiMenu-list": {
        padding: "0",
      },
      "& .subheader": {
        minHeight: "38px",
        paddingTop: "0",
        paddingBottom: "0",
        color: "#9D9D9D",
        [theme.breakpoints.up("sm")]: {
          minHeight: "45px",
        },
        "&.Mui-disabled": {
          opacity: "1",
        },
        "& .MuiListItemText-root": {
          margin: "0",
        },
        "& .MuiListItemText-primary": {
          fontFamily: "futura-pt, sans-serif",
          fontSize: "14px",
          fontWeight: "600",
          textOverflow: "ellipsis",
          overflow: "hidden",
          [theme.breakpoints.up("sm")]: {
            fontSize: "15px",
          },
        },
        "& .MuiListItemIcon-root": {
          justifyContent: "flex-end",
          position: "absolute",
          right: "10px",
          color: "white",
          [theme.breakpoints.up("sm")]: {
            right: "10px",
          },
        },
      },
      "& .item": {
        minHeight: "32px",
        paddingTop: "0",
        paddingBottom: "0",
        borderTop: "1px solid #707070",
        [theme.breakpoints.up("sm")]: {
          minHeight: "42px",
        },
        "& .MuiListItemText-root": {
          margin: "0",
        },
        "& .MuiListItemText-primary": {
          fontFamily: "futura-pt, sans-serif",
          fontSize: "14px",
          fontWeight: "600",
          textOverflow: "ellipsis",
          overflow: "hidden",
          [theme.breakpoints.up("sm")]: {
            fontSize: "15px",
          },
        },
        "&.Mui-selected": {
          backgroundColor: "#692AF0",
        },
        "&:hover": {
          backgroundColor: theme.palette.bg_base,
          color: theme.palette.main,
        },
      },
    },
  },
}))

const HeaderCategoryNav = ({ categoryList, selectedCategory = "" }) => {
  const history = useHistory()
  const classes = useStyles()
  const isSp = useMediaQuery((theme) => theme.breakpoints.down("md"))
  const [isOpen, setIsOepn] = React.useState(false)

  const handleChange = (event) => {
    history.push({
      pathname: NFT_MARKET_PATH,
      search: `?category=${event.target.value}`,
    })
  }

  const handleToggleOpen = () => {
    setIsOepn((prev) => !prev)
  }

  // PC表示
  const categoryNav = (
    <List component="nav" className={classes.categoryNav}>
      <ListSubheader component="div" className="subheader">
        Category
      </ListSubheader>
      {categoryList?.map((category) => (
        <Fragment key={category.id}>
          <Divider
            orientation="vertical"
            flexItem
            className={
              category.urlParameter === selectedCategory ? "active" : ""
            }
          />
          <ListItem
            className="item"
            key={category.id}
            button
            selected={category.urlParameter === selectedCategory}
            component={Link}
            to={{
              pathname: NFT_MARKET_PATH,
              search: `?category=${category.urlParameter}`,
            }}
          >
            <ListItemText>{category.name}</ListItemText>
          </ListItem>
        </Fragment>
      ))}
    </List>
  )

  // スマホ表示
  const categoryNavSelect = (
    <Select
      displayEmpty
      className={
        selectedCategory
          ? classes.CategorySelect
          : `${classes.CategorySelect} unselected`
      }
      MenuProps={{
        className: classes.CategorySelectMenu,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        getContentAnchorEl: null,
        TransitionComponent: Collapse,
      }}
      IconComponent={ExpandMoreIcon}
      value={selectedCategory}
      onChange={handleChange}
      open={isOpen}
      onClick={handleToggleOpen}
      autoWidth
    >
      <MenuItem
        className="subheader"
        value=""
        disabled
        onClick={() => setIsOepn(false)}
      >
        <ListItemText>Category</ListItemText>
        <ListItemIcon>
          <ExpandMoreIcon />
        </ListItemIcon>
      </MenuItem>
      {categoryList?.map((category) => (
        <MenuItem
          className="item"
          key={category.id}
          value={category.urlParameter}
        >
          <ListItemText>{category.name}</ListItemText>
        </MenuItem>
      ))}
    </Select>
  )

  return (
    <Box className={classes.root}>
      {isSp || categoryList?.length > 5 ? categoryNavSelect : categoryNav}
    </Box>
  )
}

HeaderCategoryNav.propTypes = {
  categoryList: PropTypes.arrayOf(PropTypes.object),
  selectedCategory: PropTypes.string,
}

export default HeaderCategoryNav
