import React from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  IconButton,
  Divider,
  makeStyles,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import HeaderSideMenuGenerator from "./HeaderSideMenuGenerator"
import { logout } from "../../store/auth/actions"
import NotiIcon from "../../assets/img/menu_icon_noti.svg"
import LogoutIcon from "../../assets/img/menu_icon_logout.svg"
import arwIcon from "../../assets/img/menu_icon_right.svg"
import AboutIcon from "../../assets/img/menu_icon_about.svg"
import { COMPANY_PATH } from "../../navigation/constatnsPaths"

const useStyles = makeStyles(() => ({
  root: {
    width: "428px",
    height: "100%",
    padding: "0 20px 20px",
    background: "#F7F7F7",
    "@media (max-width: 767px)": {
      width: "320px",
    },
    "& > .MuiBox-root": {
      "&:first-child": {
        display: "flex",
        height: "70px",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiIconButton-root:only-child": {
          marginLeft: "auto",
        },
        "& .L-title": {
          fontSize: "26px",
          fontWeight: "600",
          fontFamily: "futura-pt, sans-serif",
          fontStyle: "italic",
        },
      },
      "&:not(:first-child)": {
        marginBottom: "20px",
        background: "#fff",
        border: "1px solid #DEDEDE !important",
        borderRadius: "16px",
        overflow: "hidden",
        boxShadow: "none",
        "& a": {
          display: "block",
        },
        "&:empty": {
          display: "none",
        },
        "& .MuiListItem-button": {
          position: "relative",
          display: "flex",
          height: "70px",
          alignItems: "center",
          "& .MuiTypography-root": {
            fontSize: "16px",
            color: "#333",
            fontWeight: "bold",
          },
          "& .MuiListItemIcon-root": {
            minWidth: "39px",
            color: "#000",
          },
        },
      },
    },
    "& .MuiList-padding": {
      paddingTop: "0",
      paddingBottom: "0",
      borderBottom: "1px solid #DEDEDE",
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
}))
const HeaderSideMenu = React.memo(
  ({
    onClose,
    routeList,
    type,
    handleToggleNotificationDrawer,
    // handleToggleMessagesDrawer,
    // handleToggleNewsDrawer,
  }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const handleLogout = async () => {
      onClose()
      await dispatch(logout())
      window.location.reload()
    }
    const handleClickNotification = () => {
      handleToggleNotificationDrawer(true)
      onClose(false)
    }

    const NotificationNewsMessagesComponent = () => {
      if (type !== "super") {
        return (
          <List component="nav">
            <ListItem button onClick={handleClickNotification}>
              <ListItemIcon>
                <Icon>
                  <img src={NotiIcon} alt="" />
                </Icon>
              </ListItemIcon>
              <ListItemText>
                {/* お知らせ <NotificationBadge value="10" /> */}
                お知らせ
              </ListItemText>
              <Box className="arw">
                <img src={arwIcon} alt="" />
              </Box>
            </ListItem>

            {/* <ListItem button onClick={handleClickNews}>
              <ListItemIcon>
                <Icon>offline_bolt</Icon>
              </ListItemIcon>
              <Box> */}
            {/* 速報 <NotificationBadge value="5" /> */}
            {/* 速報
              </Box>
            </ListItem> */}
            {/* <ListItem button onClick={handleClickMessages}>
              <ListItemIcon>
                <Icon>mail</Icon>
              </ListItemIcon>
              <Box>
                メッセージ <NotificationBadge value="200" />
                メッセージ
              </Box>
            </ListItem> */}
            <Divider />
          </List>
        )
      }
      return null
    }
    const AobutComponent = () => {
      if (type !== "super") {
        return (
          <Box>
            <List component="nav">
              <ListItem
                button
                onClick={() => onClose()}
                component={Link}
                to={COMPANY_PATH}
              >
                <ListItemIcon>
                  <Icon>
                    <img src={AboutIcon} alt="" />
                  </Icon>
                </ListItemIcon>
                <ListItemText>Fanpla Owner とは？</ListItemText>
                <Box className="arw">
                  <img src={arwIcon} alt="" />
                </Box>
              </ListItem>
            </List>
          </Box>
        )
      }
      return null
    }
    return (
      <Box className={classes.root}>
        <Box style={{ textAlign: "end" }}>
          <p className="L-title">Menu</p>
          <IconButton onClick={() => onClose()}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
        <Box>
          <NotificationNewsMessagesComponent />
        </Box>
        <AobutComponent />
        <HeaderSideMenuGenerator menuList={routeList} onClose={onClose} />
        <Box>
          <List component="nav">
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <Icon>
                  <img src={LogoutIcon} alt="" />
                </Icon>
              </ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItem>
          </List>
        </Box>
      </Box>
    )
  }
)
HeaderSideMenu.propTypes = {
  onClose: PropTypes.func,
  routeList: PropTypes.objectOf(PropTypes.object),
  type: PropTypes.string,
  handleToggleNotificationDrawer: PropTypes.func,
  // handleToggleNewsDrawer: PropTypes.func,
  // handleToggleMessagesDrawer: PropTypes.func,
}
export default HeaderSideMenu
