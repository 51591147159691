import PropTypes from "prop-types"
import { Box, makeStyles } from "@material-ui/core"
import FormControl from "../FormControl"

const useStyles = makeStyles(() => ({
  cardInput: {
    "& .MuiTextField-root": {
      width: "32%",
    },
  },
  creditContainer: {
    maxWidth: 400,

    "& .month": {
      width: "20%",
    },
    "& .year": {
      width: "25%",
    },
    "& .sc": {
      width: "50%",
    },
    "& .Mui-error": {
      "&:last-child": {
        marginBottom: "0",
      },
    },
  },
}))

const CreditCardForm = ({ disabled, variant }) => {
  const classes = useStyles()

  return (
    <Box className={classes.creditContainer}>
      <Box my={2}>
        <FormControl
          control="textfield"
          name="creditcard.cardHolder"
          label="クレジットカード名義人"
          variant={variant}
          disabled={disabled}
        />
      </Box>
      <Box my={2}>
        <FormControl
          control="textfield"
          name="creditcard.cardNumber"
          label="カード番号"
          variant={variant}
          disabled={disabled}
        />
      </Box>
      <Box
        my={2}
        display="flex"
        justifyContent="space-between"
        className={classes.cardInput}
      >
        <FormControl
          control="textfield"
          name="creditcard.cardMonth"
          label="MM"
          variant={variant}
          disabled={disabled}
          className="month"
        />
        <FormControl
          control="textfield"
          name="creditcard.cardYear"
          label="YY"
          variant={variant}
          disabled={disabled}
          className="year"
        />
        <FormControl
          control="textfield"
          name="creditcard.cvc"
          label="セキュリティコード"
          variant={variant}
          maxLength="4"
          disabled={disabled}
          className="sc"
        />
      </Box>
    </Box>
  )
}

CreditCardForm.propTypes = {
  disabled: PropTypes.bool,
  variant: PropTypes.string,
}

export default CreditCardForm
