import {
  OFFICIAL_ACCOUNT_LIST_LOADED,
  OFFICIAL_ACCOUNT_LOADED,
  OFFICIAL_ACCOUNT_INIT,
  OFFICIAL_ACCOUNT_CREATED,
  OFFICIAL_ACCOUNT_UPDATED,
  OFFICIAL_ACCOUNT_ERROR,
  OFFICIAL_ACCOUNT_LIST_ERROR,
  OFFICIAL_ACCOUNT_DELETED,
  OFFICIAL_BANK_LIST_LOADED,
  OFFICIAL_WALLET_LIST_LOADED,
  OFFICIAL_BANK_LIST_ERROR,
  OFFICIAL_WALLET_LIST_ERROR,
  COLLECTION_LIST_LOADED,
  COLLECTION_LIST_ERROR,
} from "./types"

const initialState = () => ({
  list: [],
  form: {
    name: "",
    nameKana: "",
    tel: "",
    address: "",
    // _badgeStyle: "1",
    publishedAt: null,
  },
  mypageForm: {
    name: "",
    profilePicUrl: "",
    profileOverview: "",
    profileHideFlg: "",
    story: {},
    url1: "",
    url2: "",
    url3: "",
    url4: "",
    url5: "",
    twitter: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    youtube: "",
    badgeImage: "",
    badgeText: "",
    badgeColor: "",
  },
  creditList: [],
  bankList: [],
  walletList: [],
})

const convertToForm = (data) => ({
  ...data,
  _badgeStyle: data.badgeImage ? "2" : "1",
  createdAt: new Date(data.published_at),
  publishedAt: data.publishedAt ? new Date(data.publishedAt) : null,
})

const convertToMyPageForm = (profile) => {
  const requiredData = Object.fromEntries(
    [
      "name",
      "profilePicUrl",
      "profileOverview",
      "profileHideFlg",
      "story",
      "url1",
      "url2",
      "url3",
      "url4",
      "url5",
      "twitter",
      "facebook",
      "instagram",
      "tiktok",
      "youtube",
      "badgeImage",
      "badgeText",
      "badgeColor",
    ].map((key) => [key, profile[key]])
  )

  let story = ""
  try {
    story = JSON.parse(profile.story)
  } catch (_err) {
    // invalid json
  }

  const badgeStyle = profile.badgeImage ? "2" : "1"

  return {
    ...requiredData,
    story,
    _badgeStyle: badgeStyle,
  }
}

const officialAccountReducers = (state = initialState(), action) => {
  switch (action.type) {
    case OFFICIAL_ACCOUNT_LIST_LOADED:
      return {
        ...state,
        list: action.payload,
      }
    case OFFICIAL_ACCOUNT_LOADED: {
      return {
        ...state,
        form: convertToForm(action.payload),
        mypageForm: convertToMyPageForm(action.payload),
      }
    }
    case COLLECTION_LIST_LOADED:
      return {
        ...state,
        list: action.payload.map((data) => convertToForm(data)),
      }
    case COLLECTION_LIST_ERROR:
      throw new Error()
    case OFFICIAL_BANK_LIST_LOADED: {
      return {
        ...state,
        bankList: action.payload,
      }
    }
    case OFFICIAL_WALLET_LIST_LOADED: {
      return {
        ...state,
        walletList: action.payload,
      }
    }
    case OFFICIAL_ACCOUNT_INIT: {
      const newState = initialState()
      return {
        ...state,
        form: newState.form,
        mypageForm: newState.mypageForm,
      }
    }
    case OFFICIAL_ACCOUNT_CREATED:
    case OFFICIAL_ACCOUNT_UPDATED:
    case OFFICIAL_ACCOUNT_DELETED:
      return {
        ...state,
      }
    case OFFICIAL_ACCOUNT_LIST_ERROR:
    case OFFICIAL_ACCOUNT_ERROR:
    case OFFICIAL_BANK_LIST_ERROR:
    case OFFICIAL_WALLET_LIST_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default officialAccountReducers
