import {
  BENEFIT_MANAGEMENT_LIST_LOADED,
  BENEFIT_OWNER_LIST_LOADED,
  BENEFIT_MANAGEMENT_LIST_ERROR,
  BENEFIT_MANAGEMENT_INIT,
  BENEFIT_MANAGEMENT_LOADED,
  BENEFIT_MANAGEMENT_CREATED,
  BENEFIT_MANAGEMENT_UPDATED,
  BENEFIT_MANAGEMENT_DELETED,
  BENEFIT_MANAGEMENT_ERROR,
  BENEFIT_OWNER_USED,
} from "./types"

const initialState = () => ({
  list: [],
  ownerList: [],
  form: {
    title: "",
    detail: "",
    image: "",
    web: "",
    expire: null,
    distributionMethod: "",
    story: "",
  },
})

// ストーリーをオブジェクトにパースする
const convertToForm = (data) => ({
  title: data.name,
  detail: data.detail,
  image: data.image,
  web: data.url,
  expire: data.expiredAt,
  distributionMethod: data.distributionMethod,
  story: data.story ? JSON.parse(data.story) : {},
})

const benefitManagementReducers = (state = initialState(), action) => {
  switch (action.type) {
    case BENEFIT_MANAGEMENT_LIST_LOADED:
      return {
        ...state,
        list: action.payload,
      }
    case BENEFIT_OWNER_LIST_LOADED:
      return {
        ...state,
        ownerList: action.payload,
      }
    case BENEFIT_MANAGEMENT_LOADED:
      return {
        ...state,
        form: convertToForm(action.payload),
      }
    case BENEFIT_MANAGEMENT_INIT:
      return {
        ...state,
        form: initialState().form,
      }
    case BENEFIT_MANAGEMENT_CREATED:
    case BENEFIT_MANAGEMENT_UPDATED:
    case BENEFIT_OWNER_USED:
    case BENEFIT_MANAGEMENT_DELETED:
      return {
        ...state,
      }
    case BENEFIT_MANAGEMENT_LIST_ERROR:
    case BENEFIT_MANAGEMENT_ERROR:
      throw new Error()
    default:
      return state
  }
}

export default benefitManagementReducers
