import {
  AVAILABLE_PAYMENT_METHODS_LOADED,
  BANK_NAME_LOADED,
  BRANCH_NAME_LOADED,
  CATEGORY_DETAIL_LOADED,
  CATEGORY_DETAIL_ERROR,
  CATEGORY_DETAIL_CLEAR,
  CATEGORY_LIST_LOADED,
  CATEGORY_LIST_ERROR,
  COUNTRY_CODE_LIST,
  COUNTRY_CODE_LIST_ERROR,
} from "./types"
import { authApi, api } from "../api"

export const getBankNameList = (keyword) => (dispatch) =>
  authApi
    .get(`/search/banks`, {
      params: {
        keyword,
      },
    })
    .then((res) => {
      dispatch({
        type: BANK_NAME_LOADED,
        payload: res.data.data,
      })
    })

export const getBranchNameList = (keyword, bankCode) => (dispatch) =>
  authApi
    .get(`/search/${bankCode}/branches`, {
      params: {
        keyword,
      },
    })
    .then((res) => {
      dispatch({
        type: BRANCH_NAME_LOADED,
        payload: res.data.data,
      })
    })

// 利用可能な支払い方法を取得
export const getAvailablePaymentMethodList = () => (dispatch) =>
  authApi.get("/available_payment_method").then((res) => {
    dispatch({
      type: AVAILABLE_PAYMENT_METHODS_LOADED,
      payload: res.data.data,
    })
  })

// サイト全体カテゴリの取得（URLパラメータ検索）
export const getCategory = (urlParameter) => (dispatch) => {
  api
    .get(`/nfts/categories/${urlParameter}`)
    .then((res) => {
      dispatch({
        type: CATEGORY_DETAIL_LOADED,
        payload: res.data.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: CATEGORY_DETAIL_ERROR,
        payload: err.response?.status,
      })
    })
}

export const clearCategory = () => (dispatch) => {
  dispatch({
    type: CATEGORY_DETAIL_CLEAR,
  })
}

// カテゴリリスト取得（ページングなし）
export const getCategoryList = () => (dispatch) =>
  api
    .get("/nfts/categories", {
      params: {
        pagination: false,
      },
    })
    .then((res) => {
      dispatch({
        type: CATEGORY_LIST_LOADED,
        payload: res.data.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: CATEGORY_LIST_ERROR,
        payload: err.response?.status,
      })
    })

export const getCountryCodeList = () => (dispatch) =>
  api
    .get("/country_codes")
    .then((res) => {
      dispatch({
        type: COUNTRY_CODE_LIST,
        payload: res.data.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: COUNTRY_CODE_LIST_ERROR,
        payload: err.response?.status,
      })
    })
