// 設定・システム管理共通

import { authApi } from "../../../api"
import {
  SETTINGS_MAIL_SET,
  SETTINGS_USER_REQUEST,
  SETTINGS_USER_LOADED,
  SETTINGS_SYSTEM_LOADED,
  SETTINGS_MAINTENANCE_SET,
  SETTINGS_MAINTENANCE_LOADED,
} from "./types"

export const getSettings = () => (dispatch) =>
  authApi.get("/admin/sys-setting/maintenance").then((res) => {
    dispatch({
      type: SETTINGS_SYSTEM_LOADED,
      payload: res.data.data,
    })
  })

export const getMyGeneralAccount = () => (dispatch) => {
  dispatch({
    type: SETTINGS_USER_REQUEST,
  })
  return authApi.get("/settings/account").then((res) => {
    dispatch({
      type: SETTINGS_USER_LOADED,
      payload: res.data.data,
    })
  })
}

export const setEmailEnable = (enabled) => (dispatch) =>
  authApi
    .post("/settings/mail", {
      mail: enabled ? 1 : 0,
    })
    .then((res) => {
      dispatch({
        type: SETTINGS_MAIL_SET,
        payload: res.data,
      })
    })

export const setMaintenance = (values) => (dispatch) =>
  authApi.post("/admin/sys-setting/maintenance", values).then((_res) => {
    dispatch({
      type: SETTINGS_MAINTENANCE_SET,
      payload: values,
    })
  })

export const checkMaintenaceMode = () => (dispatch) =>
  authApi.get("/is-maintenance").then((res) => {
    dispatch({
      type: SETTINGS_MAINTENANCE_LOADED,
      payload: res,
    })
  })
