import React from "react"
import PropTypes from "prop-types"
import { FormHelperText } from "@material-ui/core"

const TextError = ({ children }) => (
  <FormHelperText error>{children}</FormHelperText>
)

TextError.propTypes = {
  children: PropTypes.node,
}

export default TextError
