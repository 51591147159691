import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { Route, Redirect, useLocation } from "react-router-dom"
import { checkMaintenaceMode } from "../store/pages/admin/settings/actions"
import store from "../store/store"
import { LOG_IN_PATH } from "./constatnsPaths"
import Loading from "../components/Loading/Loading"
import Maintenance from "../pages/Maintenance/Maintenance"

const AppRoute = ({
  component: Component,
  path,
  exact,
  isPrivate,
  ...rest
}) => {
  const { auth, settings } = useSelector((state) => state)
  const location = useLocation()
  const isMaintenanceMode =
    settings.maintenanceMode.status === 253 &&
    (auth.role !== "super" || !auth.isAuthenticated) &&
    location.pathname !== LOG_IN_PATH

  React.useEffect(() => {
    store.dispatch(checkMaintenaceMode())
  }, [])

  if (isMaintenanceMode) {
    return <Maintenance message={settings.maintenanceMode.maintenanceMessage} />
  }

  return (
    <>
      {auth.isLoading ? (
        <Loading />
      ) : (
        <Route
          path={path}
          exact={exact}
          render={(props) =>
            isPrivate && !auth.isAuthenticated && auth.role === null ? (
              <Redirect
                to={{ pathname: LOG_IN_PATH, state: { referer: location } }}
              />
            ) : (
              <Component {...props} />
            )
          }
          {...rest}
        />
      )}
    </>
  )
}

AppRoute.propTypes = {
  component: PropTypes.elementType,
  path: PropTypes.string,
  isPrivate: PropTypes.bool,
  exact: PropTypes.bool,
}

export default AppRoute
